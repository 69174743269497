




























import Vue from "vue";
import { Getter } from "vuex-class";
import { Recorder } from "@/components/recorder";
import { Component, Prop } from "vue-property-decorator";

@Component({ components: { Recorder } })
export default class RecordSession extends Vue {
  @Prop({ default: () => false }) mini!: boolean;
  @Getter("sessions/uploadingSession") loading!: boolean;
  dialog = false;
}
