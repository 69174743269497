const de = {
  dashboardPage: {
    yourGoalsCard: {
      yourGoals: "Deine Ziele",
      haveCompleted: "Du hast {number} Session{plural} abgeschlossen.",
      error: "Ein Fehler ist aufgetreten..",
      loadingGoals: "Ziele laden...",
    },
    groupsCard: {
      groups: "Gruppen",
      viewAll: "Alle ansehen",
      information: "Information",
      created: "Erstellt:",
      users: "Nutzer",
      videos: "Videos",
      posts: "Beiträge",
      notGroupMember: "Du bist noch keiner Gruppe beigetreten.",
      groupsLoading: "Gruppen werden geladen ...",
    },
    videosCard: {
      all: "Alles",
      videos: "Videos",
      group: "Gruppe",
      loadingVids: "Videos werden geladen...",
      noGroups: "Du bist noch keiner Gruppe beigetreten.",
    },
    newsCard: {
      news: "Nachrichten",
      readAll: "Alles lesen",
      posted: "Gesendet",
      readMore: "Weiterlesen",
      noNews: "Es wurden noch keine Nachrichten veröffentlicht.",
      newsLoading: "Nachrichten werden geladen ...",
    },
    sessionsCard: {
      sessions: "Sessions",
      pitchHdg: "Tonhöhe",
      pitchSubHdgDeeper:
        "Du hast diese Woche im Vergleich zur letzten Woche mit tieferer Stimme gesprochen.",
      ptichSubHdgHigher:
        "Du hast diese Woche im Vergleich zur letzten Woche mit höherer Stimme gesprochen.",
      speechSpdHdg: "Sprechgeschwindigkeit",
      speechSpdSubHdgFaster: "Du hast heute schneller gesprochen als gestern.",
      speechSpdSubHdgSlower: "Du hast heute langsamer gesprochen als gestern.",
      hertz400: "400Hz",
      hertz600: "600Hz",
      thisWeek: "Diese Woche",
      wpm: "Wörter / Minute",
      today: "Heute",
      yesterday: "Gestern",
    },
    PresentationsCard: {
      presentations: "Präsentationen",
      mb: "MB",
      empty: "Leer",
    },
  },
  //isnt used
  topPanel: {
    settings: "Einstellungen",
    mail: "Mail",
    refresh: "Aktualisierung",
    logout: "Ausloggen",
  },
  //isnt used
  leftPanel: {
    dashboard: "Dashboard",
    sessions: "Sessions",
    groups: "Gruppen",
    news: "Nachrichten",
    goals: "Ziele",
    meetings: "Meetings",
    adminPanel: "Administrationsmenü",
  },
  sessionsPage: {
    noSessions: "Es konnten keine Sitzungen gefunden werden.",
    sessionsFor: "Sessions für ",
    yourSessionsSection: {
      yourSessions: "Sessions",
      sessionFilters: "Session Filter",
      filterByDate: "nach Datum filtern",
      beforeDate: "vor dem Datum",
      afterDate: "nach dem Datum",
      betweenTwoDates: "zwischen zwei Daten",
      filterByDuration: "nach Dauer filtern",
      timeSpan: "Zeitspanne (Minuten)",
      longerThan: "Länger als (Minuten)",
      shorterThan: "Kürzer als (Minuten)",
      minTime: "Mindestzeit (Minuten)",
      maxTime: "Maximale Zeit (Minuten)",
      filterByName: "Nach Namen filtern",
      startsWith: "Beginnt mit",
      endsWith: "Endet mit",
      includes: "Beinhaltet",
      exactMatch: "Genaue Übereinstimmung",
      searchTerm: "Suchbegriff",
      caseInsensitive: "Groß- und Kleinschreibung wird nicht berücksichtigt",
      filterByScore: "Filtern nach Punktzahl",
      greaterThanScore: "größer als",
      lessThanScore: "weniger als",
      scoreRange: "Punktebereich",
      applyButton: "anwenden",
      clearButton: "löschen",
      cancelButton: "abbrechen",
      fetching: "Session abrufen...",
    },
    sessionsListSection: {
      sessionsList: "Session Liste",
      sessions: "Sessions",
      audioAnalHdg: "Audioanalyse",
      audioAnalNotComplete:
        "Die Audioanalyse wurde noch nicht abgeschlossen. Du kannst entweder noch ein bisschen warten oder den Status überprüfen, indem du die Seite aktualizierst.",
      textAnalHdg: "Textanalyse",
      textAnalNotComplete:
        "Textanalyse nicht abgeschlossen. Du kannst die Seite aktualisieren klicken, um zu überprüfen, ob das Session fertig ist, oder einfach noch ein bischen warten.",
      movementAnalHdg: "Bewegungsanalyse",
      movementAnalNoData: "Es sind keine Bewegungsdaten verfügbar.",
      show: "Zeigen",
      points: "Punkte",
      volumeWarning:
        "Eine zu leises oder laute Aufnahme kann die Qualität der Analyse Ergebnisse beeinflussen. Um präzise Analyse Ergebnisse zu erzielen versuchen sie eine Lautstärke zwischen 20% und 80% zu erreichen.",
      movementAnalCard: {
        movementAnal: "Bewegungsanalyse",
        headMovementOverTime: "Kopfbewegung im Laufe der Zeit",
        headMoTInfo:
          "Die Kopfbewegung im Laufe der Zeit zeigt, wie stark dich deine Bewegung in den letzten Sitzungen verändert hat.",
        headMovement: "Kopfbewegung",
        avgHeadMovement: "Durchschnittliche Kopfbewegung",
        infoHeadM:
          "Die Kopfbewegung zeigt, wie viel du dich mit deinem Kopf / Körper bewegen. Versuchst du, dich mit Grund und Zweck von einem Raum zum anderen zu bewegen.",
        handMovementOverTime: "Handbewegung im Laufe der Zeit",
        handMoTInfo:
          "Handbewegung im Laufe der Zeit zeigt, wie stark dich deine Bewegung in den letzten Sitzungen verändert hat",
        handMovement: "Handbewegung",
        avgHandMovement: "Durchschnittliche Handbewegung",
        infoHandM:
          "Handbewegung zeigt an, wie viel du deine Hände / Gesten bewegen. Viele Bewegungen können dazu führen, dass du nervös erscheinst, und weniger Bewegung kann du als gelangweilt darstellen. Versuchen Sie, Ihre Gesten als Werkzeug zum Aufbau von Spannung zu verwenden.",
        distance: "Entfernung",
        changeOverTime: "Veränderung im Laufe der Zeit",
        timePercent: "Zeit in %",
      },
      speechSpeedCard: {
        cardTitle: "Sprechgeschwindigkeit",
        wpmInfo:
          "Dies zeigt, wie schnell du sprichst. / Wie viele Wörter verwendest du durchschnittlich in einer Minute.",
        wpmTimeUnit: "Wörter/Minute",
        wpmNotice:
          "Für die meisten Vorträge sollte ein Zielwert zwischen 80 und 120 Wörter pro Minute angestrebt werden",
        infoTitle: "Sprechgeschwindigkeit und Hörgeschwindigkeit",
        infoText:
          "Die durchschnittliche Sprechgeschwindigkeit von 80 bis 120 Wörter pro Minute wird allgemein als angenehm und verständlich wahrgenommen. Ein Hörer kann 150 Wörter pro Minute noch gut verstehen. Lesen können wir aber durchschnittlich 260 Wörter pro Minute! Das bedeutet: Wenn wir viel Text auf einer Vortragsfolie haben, wird der Hörer intuitiv den Text lesen und nicht zuhören, bis der Redner den Text vorgelesen hat, weil es schneller geht. Das sollte man beim Konzipieren seiner Folien immer im Hinterkopf behalten. Aber gute Vortragsfolien erstellen ist ein eigenes Thema, auf das wir in den How-To noch mal eingehen.",
      },
      scoresCard: {
        infoTitle: "Session Ergebnisse",
        infoText: "Diese Ergebnisse wurden für dieses Session kalkuliert",
        cardTitle: "Session Ergebnisse",
        overall: "Gesamt",
        other: "Andere Ergebnisse",
        wpm: "Sprechgeschwindigkeit",
        pauses: "Pausen",
        repetition: "Session Ergebnisse",
        filler: "Filler-Wörter",
        speaking: "Sprachverständlichkeit",
        none: "Keine Ergebnisse für dieses Session",
      },
    },
    uploadSessionSection: {
      uploadSession: "Session hochladen",
      createSessionFromAudio: "Session aus Audiodatei erstellen",
      sessionName: "Session Name *",
      audioFile: "Audiodatei *",
      chooseLang: "Sprache wählen",
    },
    deleteSession: {
      deleteSessionButton: "Diese Session löschen",
      youSureDelete: "Möchtest du diese Session wirklich löschen?",
      deletionInfo:
        "Das Löschen einer Session ist irreversibel. Bist du sicher, dass du mit dem Löschen dieser Session fortfahren möchtest?",
      confirmSessDel: "Bestätigen",
      cancelSessDel: "Abbrechen",
    },
    sessionsProgressSection: {
      analysisStatus: {
        AnalyzingAudio: "Audio wird analysiert...",
        AnalyzingText: "Transkript wird erstellt...",
        AudioDone: "Audioanlyze fertig",
        Running: "Laufen...",
        NotStarted: "Noch nicht angefangen",
        Succeeded: "Erflog",
        null: "Laufen...",
        TextDone: "Transkribieren fertig",
      },
      sessionsProgress: "Session Fortschritte",
      analyzingSession: "Analysieren...",
      gettingSession: "Session abrufen...",
      recentData: "aktuelle Daten",
      display: "Anzeige",
      sessions: "Sessions",
      audioAnal: "Audioanalyse",
      textAnal: "Textanalyse",
      movementAnal: "Bewegungsanalyse",
      pitchOverTimeCard: {
        pitchOverTime: "Stimmhöhe im Laufe der Zeit",
        changeOverTime: "Veränderung im Laufe der Zeit",
        voicePitch: "Stimmhöhe",
        infoPoT:
          "Mit Pitch over Time kannst du den Fortschritt deiner Stimme sehen. Dies ist nützlich, wenn du trainieren möchtest, um eine tiefere oder höhere Stimme zu verwenden.",
      },
      volumeOverTimeCard: {
        volumeOverTime: "Lautstärke im Laufe der Zeit",
        voiceVolume: "Stimmen Lautstärke",
        changeOverTime: "im Laufe der Zeit ändern",
        infoVoT:
          "Lautstärke über im Laufe der Zeit zeigt den Fortschritt der Lautstärke deiner Stimme an.",
      },
      pausesOverTimeCard: {
        pausesOverTime: "Pausen-pro-Minute im Laufe der Zeit",
        changeOverTime: "im Laufe der Zeit ändern",
        totalPauseTime: "Gesamtpausenzeit",
        infoPoT:
          "Pausen im Laufe der Zeit zeigt an, wie viele Pausen du in den letzten Sessions gemacht hast.",
      },
      textAnalCard: {
        sentimentOverTime: "Stimmung im Laufe der Zeit",
        changeOverTime: "im Laufe der Zeit ändern",
        sentiment: "Stimmung",
        infoSoT:
          "Stimmung über im Laufe der zeigt deine Fortschritte im Hinblick auf eine positive Wortwahl.",
      },
      speechSpeedOverTimeCard: {
        speechSpeedOverTime: "Sprachgeschwindigkeit im Laufe der Zeit",
        changeOverTime: "im Laufe der Zeit ändern",
        wordsPerMinute: "Wörter / Minute",
        infoSSoT:
          "Dies zeigt die Geschwindigkeit deiner letzten Sessions im Vergleich zu früheren.",
      },
      movementAnalCard: {
        movementAnal: "Bewegungsanalyse",
        headMovementOverTime: "Kopfbewegung im Laufe der Zeit",
        headMoTInfo:
          "Die Kopfbewegung im Laufe der Zeit zeigt an, wie stark sich deine Bewegung in den letzten Sitzungen verändert hat.",
        headMovement: "Kopfbewegung",
        infoHeadM:
          "Die Kopfbewegung zeigt an, wie viel du dich mit deinem Kopf / Körper bewegst. Versuchst du, dich mit Grund und Zweck von einem Raum zum anderen zu bewegen.",
        handMovementOverTime: "Handbewegung im Laufe der Zeit",
        handMoTInfo:
          "Handbewegung im Laufe der Zeit zeigt an, wie stark dich deine Bewegung in den letzten Sitzungen verändert hat.",
        handMovement: "Handbewegung",
        infoHandM:
          "Handbewegung zeigt an, wie viel du deine Hände / Geste bewegst. Eine Menge Bewegung kann dazu führen, dass du nervös erscheinst, und weniger Bewegung kann du als gelangweilt darstellen. Versuchst du, deine Gesten als Werkzeug zu verwenden, um Spannung aufzubauen.",
        distance: "Entfernung",
        changeOverTime: "Veränderung im Laufe der Zeit",
        timePercent: "Zeit in %",
      },
    },
    sessionFiltersPopUp: {
      sessionFilters: "Session-Filter",
      filterByDate: "Nach Datum filtern",
      filterByDuration: "Nach Dauer filtern",
      minTimeMin: "Mindestzeit (Minuten)",
      maxTimeMin: "Maximale Zeit (Minuten)",
      filterByName: "Nach Namen filtern",
      searchTerm: "Suchbegriff",
      caseInsensitive: "Groß- und Kleinschreibung nicht berücksichtigen",
      filterByScore: "Nach Punktzahl filtern",
      apply: "Anwenden",
      clear: "Löschen",
      cancel: "Abbrechen",
      ok: "OK",
      beforeDate: "Vor dem Datum",
      after: "Nach dem Datum",
      between: "Zwischen zwei Daten",
      startDate: "Anfangsdatum",
      endDate: "Enddatum",
    },
  },
  groupsSections: {
    yourGroupsPage: {
      yourGroups: "Deine Gruppen",
      noGroups: "Du bist in keiner Gruppe.",
      addNewGroup: "Füge eine neue Gruppe hinzu",
      addGroupButton: "Gruppe hinzufügen",
      groupName: "Gruppenname",
      groupUsers: "Gruppenbenutzer",
      emailRules:
        "Trenne Emails durch Komma (,) oder durch Drücken der Leertaste, Tabulatortaste ('Tab') oder Eingabetaste ('Enter')",
      submitButton: "Einreichen",
    },
    //isnt used
    groupPage: {
      latestVideosCard: {
        latestVideos: "Neueste Videos",
        editVideo: "Video bearbeiten",
        deleteVideo: "Video löschen",
        addVidButton: "Video hinzufügen",
        addNewVideo: "Neues Video hinzufügen",
        vidTitle: "Videotitel (optional)",
        info: "Falls der Titel leer ist, wird er automatisch festgelegt",
        vidDescription: "Videobeschreibung (optional)",
        pinVideo: "Dieses Video anheften?",
        pinfo: "Das angeheftete Video wird zuerst angezeigt",
        linkButton: "Link",
        vidLink: "Video-Link",
        submit: "Bestätigen",
        fileButton: "Datei",
      },
      latestPostsCard: {
        latestPosts: "Neueste Beiträge",
        editPost: "Beitrag bearbeiten",
        deletePost: "Beitrag löschen",
        addPostButton: "Beitrag hinzufügen",
        postTitle: "Beitragstitel (erforderlich)",
        postBody: "Post Body (erforderlich)",
        pinPost: "Diesen Beitrag anheften?",
        uploadBannerImage: "Bannerbild hochladen?",
        bannerImage: "Banner Bild",
        otherFiles: "Andere Dateien",
        postButton: "Beitrag",
      },
      usersCard: {
        users: "Benutzer",
        remove: "Benutzer entfernen",
        makeManager: "Zum Manager machen",
        removeManager: "Manager Rolle entfernen",
      },
      addUserCard: {
        addANewUser: "Neuen Benutzer hinzufügen",
        addUserButton: "Benutzer hinzufügen",
        userEmail: "Benutzer Email",
        makeManager: "Zum Manager machen",
        submitButton: "einreichen",
      },
    },
  },
  latestNewsSections: {
    latestNewsPage: {
      latestNews: "Neueste Beiträge",
      noNews: "Noch keine Beiträge gepostet.",
      newsLoading: "Beiträge werden geladen ...",
      read: "Lesen",
      back: "Zurück",
      edit: "Bearbeiten",
      delete: "Löschen",
      err404: "404",
      artWithId: "Artikel mit ID",
      notFound: "konnte nicht gefunden werden.",
      goBack: "zurückgehen",
      addArticleButton: "Artikel hinzufügen",
      addArticlePopUp: {
        addNewArticle: "Fügt einen Artikel hinzu",
        articleTitle: "Artikelüberschrift",
        cannotBeEmpty: "Feld darf nicht leer sein",
        articleBody: "Artikelinhalt",
        addBannerImg: "Banner Image hinzufügen",
        bannerImg: "Banner Bild",
        otherFiles: "Andere Dateien",
        addArticle: "Artikel hinzufügen",
      },
    },
    articleActions: {
      edit: "Bearbeiten",
      editArticle: "Artikel bearbeiten",
      newArticleTitle: "Neuer Artikel-Titel",
      newArticleBody: "Neuer Artikel-Text",
      done: "Fertig",
      deleteArticle: "Artikel löschen",
      areYouSure: "Möchtest du den aktuellen Artikel wirklich löschen?",
      confirm: "Bestätigen",
      cancel: "Abbrechen",
    },
    addArticleButton: {
      addArticle: "Artikel hinzufügen",
      addNewsArticle: "Füge einen Nachrichtenartikel hinzu",
      articleTitle: "Artikeltitel",
      articleBody: "Artikelinhalt",
      addBannerImage: "Bannerbild hinzufügen",
      bannerImage: "Banner Bild",
      otherFiles: "Andere Dateien",
    },
  },
  goalsSections: {
    yourGoals: "Deine Ziele",
    assignedGoalsCard: {
      assignedGoals: "Zugewiesene Ziele",
      completeNumSessions: "Schließe #### Sitzungen ab",
      dueDate: "Fällig in #### Jahren",
      completedSessions: "Du hast #### Sessions abgeschlossen",
      noGoalAssignments: "Dir wurden keine Ziele zugewiesen.",
    },
    createdGoalsCard: {
      createdGoals: "Erstellte Ziele",
      completeNumSessions: "Schließe #### Sessions",
      goalCreatedAtMonth: "vor einem Monat",
      goalCreatedAtMinutes: "vor x Minuten",
      sessionsThatCount:
        "Nur Sitzungen mit einer Punktzahl größer als ## zählen",
      sentimentAim: "Strebe einen Stimmungswert zwischen {from}% und {to}% an.",
      pausesAim: "Versuche x- bis y-Pausen zu machen",
      pitchAim:
        "Strebe eine durchschnittliche Tonhöhe zwischen ## Hz und ## Hz an",
      volumeAim:
        "Strebe du eine durchschnittliche Lautstärke zwischen ## dB und ## dB an",
      noCreatedGoals: "Du hast keine Ziele erstellt.",
    },
    addGoalButton: "Ziel hinzufügen",
    addGoalPopUp: {
      addNewGoal: "Neues Ziel hinzufügen",
      addCondition: "Bedingung hinzufügen",
      conditionNum: "Bedingung #x",
      conditionType: "Art der Bedingung (erforderlich)",
      dueDate: "Fälligkeitsdatum",
      sessionScore: "Session Ergebnis",
      targetScore: "Zielpunktzahl",
      sentiment: "Stimmung",
      targetSentiment: "Ziel Stimmung",
      avgPitch: "Durchschnittliche Tonhöhe",
      targetPitch: "Ziel Tonhöhe",
      avgVolume: "Durchschnittliches Volumen",
      targetVolume: "Zielvolumen",
      pausesPerMin: "Pausen pro Minute",
      targetPauses: "Zielpausen",
      assignToUsers: "Benutzer zuweisen",
      selectAll: "Alle auswählen",
      deselectAll: "Alle abwählen",
      assignSelf: "Sich zuweisen?",
      submit: "Ziel senden",
    },
  },
  meetingsSection: {
    yourMeetings: "Deine Meetings",
    allMeetingsCard: {
      allMeetings: "Alle Meetings",
      subject: "Betreff",
      noSubject: "Kein Betreff festgelegt",
      noMeetings: "Keine anstehenden Meetings.",
      joinMeeting: "Einem Meeting beitreten",
      editMeeting: "Meeting bearbeiten",
      cancelMeeting: "Meeting abbrechen",
      dateFuture: "in xx Tagen",
      datePast: "vor xx Tagen",
      done: "Fertig",
    },
    addMeetingButton: "Meeting hinzufügen",
    addNewMeetingPopUp: {
      addNewMeeting: "Füge eine neue Besprechung hinzu",
      meetingSub: "Besprechungsthema",
      chooseStart: "Wähle das Startdatum / -zeit",
      chooseEnd: "Wähle das Enddatum / die Endzeit",
      assignUsers: "Benutzer zuweisen",
      selectAll: "Alle auswählen",
      deselectAll: "Auswahl löschen",
      additionalUsers: "Zusätzliche Nutzer",
      emailRule:
        "Trenne Emails durch ein Komma (,) oder durch Drücken der Leertaste, Tabulatortaste ('Tab') oder Eingabetaste ('Enter')",
      setPass: "Passwort festlegen?",
      pass: "Passwort",
      meetingTypes: "Besprechungstypen",
      blizzMeeting: "Blizz Meeting",
      customMeeting: "benutzerdefinierte Meeting",
      customMeetingLink: "Benutzerdefinierter Meeting-Link",
      addMeeting: "Meeting hinzufügen",
      dateError: "Das Enddatum des Meetings muss nach dem Startdatum liegen.",
    },
  },
  adminPanelSection: {
    allUsers: "Alle Nutzer",
    changeRole: "Rolle ändern",
    changeRolePopUp: {
      changeRoleUser: "Ändere die Rolle für den Benutzer",
      submit: "Senden",
      cancel: "Abbrechen",
      admin: "Admin",
      user: "Benutzer",
      owner: "Besitzer",
    },
  },
  passwordResetSection: {
    resetPass: "Password zurücksetzen",
    error: "Ein Fehler ist aufgetreten.",
    newPassRequired: "Neues Passwort (erforderlich)",
    confirmPass: "Neues Passwort bestätigen (erforderlich)",
    doneRedirect: "Fertig! Wir leiten dich in 5 Sekunden zum Login weiter.",
    couldNotResetPass: "Passwort konnte nicht zurückgesetzt werden",
    resetMyPass: "Passwort zurücksetzen",
    backToForgotPass: 'Zurück zu "Passwort vergessen"',
    passRequired: "Passwort ist erforderlich",
    passMustBe: "Das Passwort muss mindestens 6 Zeichen lang sein.",
    passCannotBe: "Das Passwort darf nicht länger als 16 Zeichen sein.",
    confirmPassRequired: "Passwortbestätigung erforderlich.",
    passNoMatch: "Passwörter stimmen nicht überein.",
  },
  registerSection: {
    error: "Ein Fehler ist aufgetreten.",
    fNameRequired: "Vorname (erforderlich)",
    lNameRequired: "Nachname (erforderlich)",
    firstName: "Vorname",
    lastName: "Nachname",
    companyName: "Firmenname",
    emailRequired: "Email (erforderlich)",
    passwordRequired: "Passwort (erforderlich)",
    confirmPassRequired: "Passwort bestätigen (erforderlich)",
    agree: "Ich stimme dem zu",
    termsNCond: "Allgemeine Geschäftsbedingungen",
    back: "zurück",
    register: "Registrieren",
    fName: "Vorname ist erforderlich",
    fNameMin: "Der Vorname muss mindestens 2 Zeichen lang sein",
    lName: "Nachname ist erforderlich",
    lNameMin: "Nachname muss mindestens 2 Zeichen lang sein",
    email: "Email ist erforderlich",
    invalidEmail: "Ungültige Email",
    password: "Passwort ist erforderlich",
    passMin: "Passwort muss mindestens 6 Zeichen lang sein",
    passMax: "Passwort darf nicht länger als 16 Zeichen sein",
    passRules:
      "Das Passwort muss mindestens einen Kleinbuchstaben, mindestens einen Großbuchstaben und mindestens eine Ziffer enthalten",
    confirmPass: "Passwortbestätigung erforderlich",
    passMismatch: "Passwörter stimmen nicht überein",
    tOS: "Bitte lesen Sie die Nutzungsbedingungen und stimmen Sie diesen zu",
    termsAndServices:
      "https://prezpstore.blob.core.windows.net/site-files/Nutzungsbedingungen_Prezp_Deutsch_03.03.2021.pdf?sp=rl&st=2021-03-15T08:01:58Z&se=2098-01-16T08:01:00Z&sv=2020-02-10&sr=b&sig=jlq6cAdFw%2FuPNsqdmHorPnwhcVd8qzso7yMHwhVo9gM%3D",
  },
  onboardingSection: {
    titleFirstPart: "YOUR COMMUNICATION TRAINING", // this is left in English by design
    titleSecondPart: "MENSCHEN GEWINNEN",
    bannerContent:
      "Sie möchten besser kommunizieren, packender reden und erfolgreicher verkaufen? Jetzt loslegen!",
    learnMoreBtn: "Mehr erfahren",
    footerLinkPrivacy: "Datenschutz",
    footerLinkLegal: "Legal",
    footerLinkContact: "Kontakt",
  },
  loginSection: {
    dontHaveAccount: "Du hast noch keinen Account?",
    register: "Registrieren",
    signIn: "Einloggen",
    title: "Bei PREZP einloggen",
    error: "Ein Fehler ist aufgetreten.",
    unconfirmedEmail: "Du hast dein E-Mail nicht bestätigt.",
    wrongPasswordError: "Das Passwort stimmt nicht!",
    unauthorizedError:
      "Nicht authorisiert. Der Account ist möglicherweise deaktiviert.",
    email: "Email",
    password: "Passwort",
    forgotPass: "Passwort vergessen",
    emailPlaceHolder: "demo@prezp.com",
    invalidEmail: "Ungültige Email",
    emailRequired: "Email ist erforderlich",
    passRequired: "Passwort ist erforderlich",
    passMin: "Das Passwort muss mindestens 6 Zeichen lang sein",
    passMax: "Das Passwort darf nicht länger als 16 Zeichen sein",
  },
  newUser: {
    thanks:
      "Vielen Dank, dass du dich bei PREZP registriert hast. Dein Konto wird in Kürze aktiviert.",
    welcome: "Willkommen bei PREZP",
  },
  inactiveProfile: {
    notActivated:
      "Dieses Profil wurde nicht aktiviert. Für weitere Informationen wendest du dich bitte an die Administratoren dieser Website.",
  },
  forgotPassSection: {
    signUp: "Willst du dich anmelden?",
    register: "Registrieren",
    wantToSignIn: "Möchtest du dich anmelden?",
    login: "Einloggen",
    resetPass: "Passwort zurücksetzen",
    error: "Ein Fehler ist aufgetreten.",
    email: "Email",
    sentEmail: "Eine Email wurde gesendet an",
    followLinkResetPass:
      "Folge dem Link in der Email, um dein Passwort zurückzusetzen.",
    emailSent: "Email gesendet!",
    sendReset: "Email zurücksetzen senden",
    invalidEmail: "Ungültige Email",
    emailRequired: "Eine Email-Adresse ist erforderlich",
    passRequired: "Passwort ist erforderlich",
    passMinRule: "Das Passwort muss mindestens 6 Zeichen lang sein",
    passMaxRule: "Passwort darf nicht länger als 16 Zeichen sein",
    emailPlaceHolder: "demo@prezp.com",
    haveAnAccount: "Du hast schon ein Account?",
    signIn: "Einloggen",
    forgotPass: "Passwort vergessen?",
  },
  confirmEmailSection: {
    emailConfirmed: "Email erfolgreich bestätigt!",
    contWeb: "Weiter zur Website",
    wait: "Gib uns eine Sekunde ...",
    error: "Ein Fehler ist aufgetreten.",
    couldNotConfirm: "Email konnte nicht bestätigt werden ...",
    backToLogin: "Zurück zum Login",
  },
  audioSection: {
    startAnal: "Analyse starten",
  },
  scoreDetailsSection: {
    sessionScore: "Session Score",
  },
  sentimentSection: {
    breakdown: "Aufschlüsselung nach Satz",
    positive: "Positiv",
    negative: "Negativ",
    neutral: "Neutral",
    noAnalyzedSentence: "Es wurden keine Sätze analysiert.",
    sentimentRatio: "Stimmung",
  },
  pausesCard: {
    pauses: "Pausen",
    spentSubVerb: "Du hast ausgegeben",
    spentPrep: "Zeit in Pause.",
    number: "Pausen gemacht",
    average: "Durchschnittliche Pausenzeit",
    total: "Gesamtpausenzeit",
    timeUnit: "Sekunden",
    warning:
      "Pausen werden ab 2 Sekunden definiert. Wenn die Stimme zu leise ist kann dies als Pause interpretiert werden.",
    ppm: "Pausen/ Minute",
    ppmCaps: "PAUSEN / MINUTE",
  },
  movementRatioCard: {
    movementRatio: "Bewegungsverhältnis",
    headMovement: "Kopfbewegung",
    handMovement: "Handbewegung",
    noMoveData: "Keine Bewegungsdaten gefunden.",
  },
  managedUsersCard: {
    usersManaged: "Benutzer, die du verwaltest",
    noManagedUsers: "Du verwaltest keine Benutzer",
  },
  keywordsCard: {
    keywords: "Schlüsselwörter",
    basedOn: "Basierend auf KI",
  },
  audioCard: {
    yourAudio: "Dein Audio",
    audioFileInfo:
      "Dies ist die Audiodatei, die du für diese Sitzung aufgenommen hast. Während du sie abspielst werden die Diagramme aktualisiert",
  },
  volumeCard: {
    voiceVolume: "Stimmen Lautstärke",
    avgVoiceVol: "Durchschnittliches Volumen",
  },
  pitchCard: {
    voicePitch: "Stimmhöhe",
    avgVoicePitch: "Durchschnittliche Tonhöhe",
  },
  sentimentRatioCard: {
    sentimentRatio: "Stimmung",
  },

  appBar: {
    notConfirmedEmail:
      "Email nicht bestätigt. Bitte klicken sie auf den Link in der Mail.",
    sending: "Senden...",
    sendEmail: "Email senden",
    emailSent: "Email gesendet!",
  },
  toolbarMenu: {
    settings: "Einstellungen",
    mail: "Mail",
    refresh: "Aktualisieren",
    logout: "Abmelden",
  },
  profileDropdown: {
    loading: "Laden...",
    profile: "Profil",
    checkoutSettings: "Überprüfe die Einstellungen",
    logout: "Abmelden",
  },
  groupName: {
    newName: "Neuer Gruppenname",
    rename: "Umbenennen",
    nameRule: "Name muss mindestens 3 Buchstaben lang sein",
  },
  groupItem: {
    deleteGroup: "Gruppe löschen",
    areYouSure: "Möchtest du die Gruppe wirklich löschen?",
    deleteInfo: "Dadurch werden auch alle damit verbundenen Videos gelöscht.",
    confirm: "Bestätigen",
    cancel: "Abbrechen",

    leave: "Gruppe verlassen",
    groupLeft: "Du has Gruppe {name} verlassen",
    confirmLeave: "Bust du sicher, dass du {name} verlassen möchtest?",
  },
  addGroupCard: {
    addNewGroup: "Neue Gruppe hinzufügen",
    groupName: "Gruppenname",
    users: "Mitglieder hinzufügen",
    submit: "Bestätigen",
    addGroup: "Gruppe hinzufügen",
  },

  // Navigation panel
  nav: {
    dash: "Dashboard",
    sessions: "Sessions",
    list: "Session Liste",
    progress: "Session Fortschritt",
    groups: "Gruppen",
    news: "Neuigkeiten",
    goals: "Ziele",
    meetings: "Meetings",
    company: "Unternehmen",
    howto: "Tutorials",
    presentation: "Präsentation",
    admin: "Admin Bereich",
    sessionList: "Session Liste",
    sessionProgress: "Session Fortschritt",
    seminars: "Seminare",
    seminarsDash: "Dashboard",
    seminarsProg: "Fortschritt",
    seminarsAdmin: "Admin Bereich",
  },

  // New stuff
  global: {
    loading: "Laden",
    submit: "Absenden",
    empty: "Leer",
    close: "Schliessen",
    requiredf: "Pflichtfeld",
    cancel: "Abbrechen",
    files: "Angehängte Dokumente",
    confirm: "Bestätigen",
    none: "Keine",
    done: "Bestätigen",
    save: "Änderungen speichern",
    refresh: "Aktualisieren",
    reset: "zurücksetzen",
    clear: "Auswahl zurücksetzen",
    nomatch: "Keine Elemente, die Ihrer Suche entsprechen",
    upload: "Hochladen",
    create: "Erstellen",
    back: "Zurück",
    invalidURL: "ungültige URL",
    err: "Es ist ein Fehler aufgetreten",
    retry: "Noch einmal versuchen",
    backToLogin: "Zurück zum Login",
    imprint: "Impressum",
    dataprotection: "Datenschutz",
    dataprotection_link:
      "https://prezpstore.blob.core.windows.net/site-files/Datenschutzerkl%C3%A4rung_Prezp_Deutsch_03.03.2021.pdf?sp=r&st=2021-03-08T10:12:08Z&se=2099-03-08T18:12:08Z&spr=https&sv=2020-02-10&sr=b&sig=zJPMmbutO6RofPwVf0PKPvhU8aKpnfwT5fjgv83ncvA%3D",
    feedback: "Für Feedback und Fragen: info@prezp.com",
    nodata: "Keine Daten verfügbar",
    remove: "Entfernen",
  },

  forms: {
    invalidform: "Diese Form ist ungültig",
    invalidfield: "Dieses Feld ist ungültig",
    reqfield: "Dieses Feld ist erforderlich",
    audiofile: "Bitte geben Sie eine Audiodatei an",
    namelen3: "Der Name muss mindestens 3 Buchstaben lang sein",
    file: "Bitte geben Sie eine Datei an",
    invaliddata: "Ungültige Eingabedaten",
    errors: "Kann mit vorhandenen Fehlern nicht fortgesetzt werden",
    missing: "Einige Dateien fehlten",
  },

  upsess: {
    button: "Audio hochladen",
    title: "Neue Session erstellen durch Audiodatei",
    subtitle: "Neue Session erstellen",
    text:
      'Erstelle eine Session auf Basis einer Audiodatei. Die Analyse Ergebnisse können auf der Seite "Session" angezeigt werden. Die Analyse beginnt direkt nach dem Hochladen.',
    go: "Jetzt erstellen",
    hint:
      "Wir unterstützen die gängigsten Audiodateiformate wie mp3, wav, ogg, m4a* und mpeg*",
    smallprint:
      "* In seltenen Fällen kann die Transkription für .m4a- oder .mpeg-Dateien nicht erstellt werden",

    select: "Präsentation wählen",
    rec: "Session aufzeichnen",
    rectip: "Neue Session direkt aufzeichnen",
    rectext: "Nehmen Sie eine neue Session mit oder ohne Präsentation auf",
    rejected_file:
      "Unsupported file type. Only audio formats allowed (mp3, wav, ogg, m4a, mpeg)",
  },

  groups: {
    groups: "Gruppen",
    all: "Alle Gruppen",
    none: "Du bist noch keiner Gruppe zugeordnet",
    hint:
      "Trenne Emails mit Komma (,) oder durch Drücken der Leertaste, der Tabulatortaste ('Tab') oder der Eingabetaste ('Enter')",
    created: "Erstellt am",
    delete: "Gruppe löschen",
    you: "(du)",

    creator: "Gruppengründer",
    joined: "Mitglied seit",
    removeu: "Benutzer entfernen",
    confrem: "Möchtest du {u} wirklich aus der Gruppe entfernen?",
    remself: "dich selbst",

    videos: "Neueste Videos",
    addv: "Video hinzufügen",
    addnv: "Neues Video hinzufügen",
    vidtitlehint: "Wenn der Titel leer ist, wird er automatisch festgelegt",
    vidtitlelabel: "Videotitel",
    viddesc: "Videobeschreibung",
    vidpinlabel: "Dieses Video anheften?",
    vidpinhint: "Das gepinnte Video wird zuerst angezeigt",
    link: "Link",
    vidlinklabel: "Video-Link",
    file: "Datei",
    novideos: "Keine Videos gefunden",
    delv: "Video löschen",
    editv: "Video bearbeiten",
    original_title: "Original Titel",
    author: "Autor",
    uploaded_by: "Hochgeladen von",

    posts: "Neueste Beiträge",
    addp: "Beitrag hinzufügen",
    addnp: "Neuen Beitrag erstellen",
    posttitle: "Titel des Posts*",
    postbody: "Inhalt des Posts*",
    postpin: "Diesen Post anheften?",
    bannerlabel: "Laden Sie das Bannerbild hoch?",
    bannerfile: "Bannerbild",
    files: "Andere Dateien",
    noposts: "Keine Einträge gefunden",
    delpost: "Beitrag löschen",
    editpost: "Beitrag bearbeiten",

    users: "Gruppenmitglieder",
    addu: "Mitglied hinzufügen",
    addnu: "Neues Mitglied zur Gruppe hinzufügen",
    uemail: "Benutzer Email",
    makeman: "Zum Manager machen",
    remman: "Als Manager entfernen",
    nousers: "Keine Benutzer Emails, die {term} enthalten",

    inv: {
      title: "Ihre Einladungen",
      message: "{inviter} hat Sie zur Gruppe {group} eingeladen",
      accept: "Akzeptieren",
      decline: "Ablehnen",
      accepted: "Sie wurden zur Gruppe hinzugefügt: {group}",
      declined: "Die Einladung von {inviter} wurde abgelehnt",
      sent: "Gesendete Einladungen",
      nonesent: "Keine gesendeten Einladungen",
    },
  },

  inv: {
    title: "Ihre Einladungen",
    message: "{inviter} hat Sie zur Gruppe {group} eingeladen",
    accept: "Akzeptieren",
    decline: "Ablehnen",
    accepted: "Sie wurden zur Gruppe hinzugefügt: {group}",
    declined: "Die Einladung von {inviter} wurde abgelehnt",
    sent: "Gesendete Einladungen",
    nonesent: "Keine gesendeten Einladungen",
    asmanager: "Eingeladen als Manager",
    asemployee: "Eingeladen als Mitarbeiter",
  },

  videos: {
    videos: "Videos",
    none: "Keine Videos in dieser Gruppe",
    edit: "Video bearbeiten",
    delete: "Video löschen",
    desc: "Videobeschreibung",
    title: "Video Titel",
    ispinned: "Gepinnt?",
  },

  goals: {
    goals: "Deine Ziele",
    none: "Du hast keine Ziele",

    assigned: "Zugewiesene Ziele",
    tassigned: "Alle Ziele, die dir zugewiesen wurden",
    noassigned: "Keine zugewiesene Ziele",
    assigner: "Von",

    created: "Erstellte Ziele",
    tcreated: "Alle Ziele, die du erstellt hast",
    nocreated: "Keine erstellte Ziele",

    add: "Ziel hinzufügen",
    complete: "Schließe {n} Sessions ab",
    aim: "Erreiche",
    addc: "Bedingung hinzufügen",
    assign: "Benutzer zuweisen",
    assignedu: "Zugewiesene Benutzer",
    assigny: "Selbst zuweisen?",
    submit: "Ziel festlegen",
    remove: "Ziel entfernen",
    select: "Wähle alle",
    deselect: "Wähle keine",
    cond: "Bedingung",
    conds: "Ziel Bedingungen",
    ctype: "Art der Bedingung",

    score: "Score Ziel",
    due: "Fälligkeitsdatum",
    sentiment: "Stimmung Ziel",
    pitch: "Stimmton Ziel",
    volume: "Lautstärke Ziel",
    pauses: "Pausen Ziel",
    wpm: "Sprechgeschwindigkeit Ziel",
    overdue: "Überfällig",

    condtype: {
      date: "Enddatum",
      sent: "Stimmung",
      pitch: "Durchschnittliche Stimmhöhe",
      vol: "Durchschnittliche Lautstärke",
      ppm: "Pausen pro Minute",
      wpm: "Sprechgeschwindigkeit",
    },

    condmsg: {
      overdue: "Überfällig",
      duein: "Fällig",
      session_score: "Nur Sessions mit einem Ergebnis größer als {n} zählen",
      sentiment: "Streben Sie einen Stimmungswert zwischen {n1}% und {n2}% an",
      pitch:
        "Streben Sie eine durchschnittliche Tonhöhe zwischen {n1}Hz und {n2}Hz an",
      volume:
        "Streben Sie ein durchschnittliches Volumen zwischen {n1}% und {n2}% an",
      pauses:
        "Versuchen Sie, irgendwo zwischen {n1} und {n2} Pausen pro Minute zu machen",
      speech_speed: "Streben Sie nach {n1} bis {n2} Wörtern pro Minute",
    },

    confirm: "Bist du sicher?",
    confirmmsg:
      "Möchten Sie dieses Ziel wirklich löschen? Die Aktion ist irreversibel.",
  },

  news: {
    news: "News",
    read: "Artikel lesen",
    all: "Alle lesen",
    posted: "Gepostet am",
  },

  meet: {
    created: "Erstellt von:",
    expired: "Dieses Meeting ist abgelaufen",
    add: "Meeting hinzufügen",
  },

  pres: {
    pres: "Präsentationen",
    none: "Du hast keine Präsentationen",
    edit: "Präsentationen bearbeiten",
    dl: "Herunterladen",
    ed: "Bearbeiten",
    rm: "Entfernen",
    no_slides:
      "Wenn hier keine Bilder zu sehen sind, dann konnten wir die Folien nicht convertieren",
    tip:
      "Lade deine Präsentationen (.pdf) hoch, um sie in deinen VR-Headset zu benutzen",
  },

  rec: {
    tiptitle: "Tipps zum PREZP Web-Rekorder",
    tiptext:
      "Du kannst dein Mikrofon testen, um zu überprüfen, ob dein Signal gut ankommt.",
    hide: "Diesen Tipp nicht mehr zeigen",
    voll: "Zu leise",
    volgood: "Gut",
    volh: "Zu laut",
    latest: "Neuste Aufnahmen",
    save: "Aufnahme speichern unter",
    sess: "Neues Session",
    base: "Zur Basissession hinzufügen",
    succ: "Session erfolgreich erstellt",
    fromVideo: "Session aus Video wurde erstellt",
    fail: "Sitzung konnte nicht erstellt werden",
    remove: "Aufnahme entfernen",
    removetext:
      "Sind Sie sicher, dass Sie die folgende Aufnahme entfernen möchten?",
    mic: "Mikrofonlautstärke einstellen",
    mute: "Mikrofon ein-/ausschalten",
    devices: "Geräte",
    prefix: "Aufgenommen am",
  },

  meta: {
    dev: "Abweichung",
    earlier: "Durchschnitt der früheren Sessions",
    recent: "Durchschnitt der letzten Sessions",
    names: {
      pitch: "Stimmton",
      volume: "Lautstärke",
      pauses: "Pausen",
      sentiment: "Stimmung",
      session_score: "Sessionergebnis",
      speech_speed: "Sprechgeschwindigkeit",
    },
    units: {
      pitch: "Hz",
      volume: "%",
      pauses: "Pausen/Minute",
    },
  },

  slides: {
    slides: "Folien",
    tip: "Präsentationsfolien",
    pitch: "Durchschnittliche Stimmhöhe",
    volume: "Durchschnittliche Lautstärke",
    wpm: "Wörter/Minute",
    start: "Startzeit",
    end: "Endzeit",

    notfound: "Keine Folie für diese Zeit",
    none: "Es gibt keine Folien für diese Session",
  },

  lookup: {
    title: "Wörter nachschlagen",
    enter: "Gib den zu suchenden Text ein",
    exact: "Genaue Übereinstimmung",
    show: "Alle anzeigen",
    hide: "Weniger anzeigen",
    start: "Mit dem Tippen beginnen...",
    filter: "Filtrieren mit",
    missing: "Nicht genügend Daten um Wörter anzuzeigen",
  },

  cards: {
    avg: "Durchschnitt",
    pvolume: "Grundlautstärke",
    ppitch: "Grundton",
    wpm: "Wörter/Minute",
    keywords: "Schlüsselwörter (KI-basiert)",
  },

  bench: {
    choose: "Benchmark wählen",
    change: "Benchmark ändern",
    title: "Benchmark Session",
    text:
      "Wähle ein Benchmark Session, die an die Graphen angezeigt wird. Damit kannst du dich mit anderen Sessions vegleichen.",
  },

  sentences: {
    filter: "Sätze filtrieren",
    none: "Keine Sätze gefunden",
    pos: "Positiv",
    neu: "Neutral",
    neg: "Negativ",
    nochart: "Keine Dateien für die Stimmung gefunden",
    filters: {
      none: "Kein",
      vpos: "Sehr positiv",
      vneg: "Sehr negativ",
    },
  },

  company: {
    title: "Unternehmen",
    partof: "Du bist schon einem Unternehmen beigetreten",
    notpartof: "Du bist keinem Unternehmen beigetreten",
    create: "Erstelle ein Unternehmen",
    createbtn: "Unternehmen erstellen",
    invitations:
      "Oder akzeptiere eine Einladung um einem bestehenden Unternehmen beizutreten",
    noinv: "Du hast keine Einladungen",

    companies: "Unternehmen",
    getting: "Unternehmen abrufen...",
    notfound: "Keine Unternehmen gefunden",

    none: "Du hast kein Unternehmen",
    inactive: "Dieses Unternehmen ist nicht aktiviert",
    pdesc: "Wähle einen Plan und kaufe ein Abonnement",

    plans: "Pläne",
    changep: "Plan ändern",
    selectp: "Neuen Plan auswählen",
    same: "Wählen Sie einen anderen Plan",

    emp: "Mitarbeiter",
    esingle: "Mitarbeiter",
    invite: "Benutzer einladen",
    adde: "Mitarbeiter hinzufügen",
    eemail: "Benutzer Email",
    makeman: "Zum Manager machen?",
    rem: "Entfernen",
    sure: "Möchten Sie diesen Mitarbeiter wirklich entfernen?",
    crole: "Rolle wechseln",
    crolefor: "Rolle wechseln für",
    noemp: "Noch keine Mitarbeiter im Unternehmens",

    edit: "Name des Unternehmens bearbeiten",
    name: "Name des Unternehmens",
  },

  charts: {
    avghand: "Durchschnittliche Handbewegung",
    avghead: "Durchschnittliche Kopfbewegung",
    ppm: "Pausen/Minute",
    sent: "Gefühl",
    pitchot: "Stimmton im Laufe der Zeit",
    scoreot: "Sessionergebnis im Laufe der Zeit",
    avgvp: "Durchschnittliche Stimmhöhe",
    avgvol: "Durchschnittliche Lautstärke",
    avgwpm: "Durchschnittliche Wörter/Minute",
    sscore: "Sessionergebnis",
    points: "{n} Punkte zeigen",
    all: "alle",
  },

  sess: {
    noneselected: "Keine Session gewählt",
    shared: "Mit {n} Personen geteilt",
    sharedWithOne: "Mit {n} Person geteilt",
    sharedWith: "Geteilt mit ",
    sharedWithNone: "Mit niemandem geteilt",
    noOneToShareWith:
      "Du kannst Sessions nur mit Usern aus einer deiner Gruppen teilen.",
    private: "Privat",
    duration: "Dauer",
    none: "Keine Sessions gefunden",
    noneseelcted: "Keine Session ausgewählt",
    edit: "Session bearbeiten",
    name: "Name der Sessions",
    vis: "Sichtbarkeit",
    updated: "Session aktualisiert",
    filter: "Filtern von Sitzungen",
    keywords: "Schlüsselwörter",

    sharing: "Siehe geteilte Sessions an",
    usersSharing: "Benutzer, die Sessions mit dir teilen",
    usersManaged: "Benutzer, die Session mit dir Sessions teilen",
    otherUsers: "Andere Benutzer aus Gruppen",
    changeuser: "Benutzer wechseln",
    nouser: "Kein Benutzer ausgewählt",
    sharedback: "Zurück zu geteilten Sessions",

    list: "Liste",
    progress: "Fortschritte",
    progresslimit: "Wählen Sie die Anzahl der anzuzeigenden Sessions",

    filters: {
      date: {
        before: "Vor dem Datum",
        after: "Nach dem Datum",
        between: "Zwischen zwei Daten",
      },
      duration: {
        longer: "Länger als (Minuten)",
        shorter: "Kürzer als (Minuten)",
        between: "Zeitspanne (Minuten)",
      },
      name: {
        startsWith: "Beginnt mit",
        endsWith: "Endet mit",
        includes: "Enthält",
        exact: "Genaue Übereinstimmung",
      },
      score: {
        greater: "Größer als",
        less: "Weniger als",
        range: "Punktebereich",
      },
      pres: {
        title: "Nach Präsentation filtern",
        label: "Präsentation auswählen",
      },
    },

    hanging: {
      title: "Andere Benutzer",
      text:
        "Sie teilen dieses Session immer noch mit einigen Benutzern, mit denen Sie keiner Gruppe mehr angehören.",
      removeOneDialogTitle:
        "Sind Sie sicher, dass Sie dieses Session nicht mehr mit {u} teilen möchten?",
      removeOneDialogText:
        "Diese Aktion ist irreversibel. Sie müssen mit {u} in mindestens einer Gruppe sein, um später wieder ein Session mit Ihnen teilen zu können.",
      removeAllButton: "Entfernen Sie alle diese Benutzer",
      removeAllDialogTitle:
        "Sind Sie sicher, dass Sie alle diese Benutzer entfernen möchten?",
      removeAllDialogText:
        "Diese Aktion ist irreversibel. Sie müssen mit jedem dieser Benutzer in mindestens einer Gruppe sein, um später wieder eine Sitzung mit ihnen teilen zu können.",
      snack: {
        removeAll: "Erfolg. Benutzer entfernt",
        removeSome:
          "Nicht alle Benutzer wurden entfernt. Benutzer {emails} konnten nicht entfernt werden",
        removeFail:
          "Die Sitzung konnte nicht für einen oder mehrere Benutzer freigegeben werden",
      },
    },

    clear: {
      title: "Alle benutzer entfernen",
      text: `Sie müssen Ihre Aktion noch bestätigen, indem Sie auf "Bestätigen" klicken`,
    },
  },

  words: {
    word: "Wort",
    filler: "Füllwort",
    stop: "Stoppwort",
    normal: "Standardwort",
  },

  admin: {
    page: "Admin Bereich",
    users: "Seitennutzer",
    products: "Stripe Produkte",
    coupons: "Stripe Gutscheine",
    tuts: "Tutorials",
    companies: "Unternehmen",
    slides: "Folien",
    createNewProduct: "Neues Produkt anlegen",
    createProduct: "Produkt anlegen",
    activeProduct: "Aktives Produkt",
    productName: "Produktname",
    productDescription: "Produktbeschreibung",
    productPrice: "Produktpreis (in Euro)",
    maximumAllowedEmployees: "Maximale Mitarbeiteranzahl",
    verifyEmail: "Verifizieren sie die E-Mail Adresse dieses Benutzers.",
    permissions: "Verwalten Sie die Berechtigungen dieses Benutzers",
  },

  permissions: {
    session_training: "Session Training",
    seminar: "Seminare",
    editTitle: "Berechtigungen verwalten",
    editText: "Hinzufügen, Entfernen oder Erweitern von Berechtigungen für",
    removeTitle: "Bist du sicher?",
    removeText:
      "Sie können den gleichen Berechtigungstyp zu einem späteren Zeitpunkt hinzufügen.",
    modifyTitle: "Datum ändern",
    add: "Berechtigungen hinzufügen",
    confirm: "Änderungen bestätigen",
  },

  roles: {
    employee: "Mitarbeiter",
    manager: "Manager",
    owner: "Besitzer",
    admin: "Admin",
    user: "Benutzer",
    change: "Rolle wechseln",
    changetitle: "Ändern Sie die Rolle für den Benutzer",
  },

  activeUser: {
    search: "Benutzer filtrieren",
    none: "Keine Benutzer gefunden",
    indef: "Unbestimmt",
    mins: " Minuten",
    hours: " Stunde(n)",
    days: " Tag(e)",
    months: " Monat(e)",
    both: "Aktiv/Deaktiviert",
    active: "Aktiv",
    inactive: "Deaktiviert",
    activate: "Aktivieren",
    deactivate: "Deaktivieren",
    change: "Aktivstatus für folgenen Benutzer wechseln",
    until: "bis",
    confirm: "Bist du sicher, dass du diesen Benutzer deaktivieren möchtest?",
    activateMsg:
      "Du kannst diesen Benutzer deaktivieren. Bitte wähle Option, wie lange der Benutzer aktiv bleiben soll.",
  },

  def_pres: {
    title: "Standardfolien",
    current: "Derzeit hochgeladene Folien",
    currentmsg:
      "Dies sind die Folien, die jeder neue Benutzer erhält, wenn er sich für PREZP registriert",
    none: "Es sind keine Standardfolien hochgeladen",
    upload: "Neue Folien hochladen",
    uploadBtn: "Hochladen",
    remove: "Standardfolien entfernen",
    removeBtn: "Entfernen",
    confirm: "Bist du sicher, dass du diese Folien entfernen willst?",
    no_slides: "Diese Folien können nicht zu Bilder gewandelt werden",
  },

  snack: {
    users: {
      rupdated: "Benutzerrolle aktualisiert",
      rem: "Benutzer entfernt",
      emails: "Ein oder mehrere Emails sind ungültig",
      alreadyin: "Ein oder mehrere Benutzer sind schon in der Gruppe",
    },
    comp: {
      created: "Unternehmen erfolgreich erstellt",
      invited: "Benutzer erfolgreich eingeladen",
      rupdated: "Benutzerrolle aktualisiert",
      upsuccess: "Upgrade erfolgreich",
      upfail: "Das Upgrade war nicht erfolgreich",
      rejected: "Folgende Benutzer wurden nicht eingeladen: ",
      success: "Die Einladung war erfolgreich",
      createfail: "Unternehmen konnte nicht erstellt werden",
      empmax: "Zahl der Mitarbeiter mehr als max. erlaubte",
      conflict: "Ein oder mehrere Nutzer sind bereits in einem Unternehmen",
    },
    groups: {
      unadded: "Diese Benutzer konnten nicht hinzugefügt werden: ",
      del: "Gruppe erfolgreich gelöscht",
      added: "Gruppe erfolgreich hinzugefügt",

      ingroup: "Benutzer bereits in Gruppe",
      allin: "Alle Benutzer bereits in der Gruppe",
      somein: "Einige Benutzer gehören bereits zu dieser Gruppe:",
    },
    news: {
      added: "Artikel erfolgreich gelöscht",
    },
    pres: {
      del: "Präsentation erfolgreich gelöscht",
    },
    sess: {
      delsuccess: "Session erfolgreich gelöscht",
      delfail: "Session konnte nicht gelöscht werden",
      metafail:
        "Die Metaanalyse-Einstellungen konnten nicht aktualisiert werden",
      benchfail: "Benchmark-Session konnten nicht abgerufen werden",
      basefail: "Sessions konnten nicht hochgeladen werden",
      fetchfail: "Sessions für {e} konnten nicht abgerufen werden",
    },
    vid: {
      editsuccess: "Video erfolgreich bearbeitet",
      editfail: "Fehler beim Bearbeiten des Videos",
      delsuccess: "Video erfolgreich gelöscht",
      delfail: "Fehler beim Löschen des Videos",
    },
    auth: {
      fail: "Es ist ein Fehler aufgetreten, zurück zum Login",
      confirmfail: "Diese Email konnte nicht überprüft werden",
      sent: "Email gesendet",
      sendfail: "Email konnte nicht gesendet werden",
      reset: "Passwort erfolgreich zurückgesetzt",
      resetfail: "Passwort konnte nicht zurückgesetzt werden",
    },
    stripe: {
      elempty: "Element kann nicht leer sein",
      elinvalid: "Ungültige Zahlungsinformationen",
      elincomplete: "Unvollständige Daten",
      discount:
        "* Dieser Gutschein gilt für die ersten {d} Monate, anschließend gilt der Standardpreis dieses Produkts",
      purchase: "Erfolg",
      pfail: "Abonnement konnte nicht gekauft werden",
      entercode: "Bitte geben Sie einen Gutscheincode ein",
      codeinvalid: "Ungültiger Gutscheincode",
    },
    meta: {
      attrs: "Bitte wählen Sie mindestens ein Attribut aus",
    },
  },
  settings: {
    title: "Benutzereinstellungen",
    dark: "Dunklen Modus aktivieren",
    alreadyup:
      "Sie haben bereits eine oder mehrere Basis Sessions hochgeladen.",
    analyzed: "Sessions wurden analysiert.",
    notanalyzed: "Sessions wurden noch nicht analysiert.",
    upAt: "Hochgeladen am",
    avgVol: "Durchschnittliches Volumen",
    avgPitch: "Durchschnittliche Tonhöhe",
    actions: "Aktionen",
    items_per_page_text: "Einträge pro Seite",
  },
  baseline: {
    up: "Basis Session hochladen",
    base: "Basissessions",
    base1: "Basissession (1)",
    base2: "Basissession (2)",
    base3: "Basissession (3)",
    text:
      "Die Basis Sessions werden genutzt um sie mit künftigen Aufnahmen zu vergleichen. Sie sollen ihnen eine Orientierung geben wie sich ihre Stimme unter normalen Bedingungen verhält.",
    lang: "Sprache wählen",
  },

  move: {
    title: "Bewegungsanalyse",
    none:
      "Du hast kein VR-Training absolviert, deshalb wurden von PREZP keine Bewegungsdaten von dir analysiert",
    head: {
      title: "Kopfbewegung",
      avg: "Durchschnittliche Kopfbewegung",
      tip: "Zeigt die Kopfbewegung in der VR während der Präsentation an",
    },
    hand: {
      title: "Handbewegung",
      avg: "Durchschnittliche Handbewegung",
      tip: "Zeigt die Kopfbewegung in der VR während der Präsentation an",
    },
    ratio: {
      title: "Bewegungsverhältnis",
      tip: "Dies zeigt das Verhältnis zwischen Kopf- und Handbewegung",
    },
  },

  number: {
    toosmall: "Die Zahl ist zu klein (min.: {n})",
    toobig: "Die Zahl ist zu groß (max.: {n})",
  },

  seminars: {
    home: {
      title: "Seminare Startseite",
      noSeminars: "Du hast noch kein Seminar",
      adminLink: "Verwaltung",
      progressLink: "Fortschritt",
      yourSubmissions: "Deine Aufzeichnungen",
      feedback: "Feedback für mich",
      submissions: "Aufzeichnungen",
      noSubmissions: "Bisher gibt es keine Aufzeichnungen",
      seeAllButton: "Alle anzeigen",
    },
    admin: {
      title: "Seminar Verwaltung",
      addBtn: "Seminar hinzufügen",
      addTitle: "Neues Seminar hinzufügen",
      addText:
        "Hiermit kann ein Seminar erstellt werden. Kurs-Elemente wie z.B. ein Lückentext, Video-Feedback-Übungen oder andere Kurs-Bestandteile können einfach und flexibel erstellt und hinzufügt werden. Veröffentliche danach das Seminar und lade deine Teilnehmer ganz bequem dazu ein.",

      feedback: {
        title: "Geben Sie dem Benutzer Feedback",
        requested: "Angefordertes Feedback",
        desc:
          "Die folgenden Videoeinsendungen haben Feedback vom Seminarinhaber angefordert",
        yourFeedback: "Ihre Feedback",
        alreadyGiven: "Du hast bereits Feedback zu diesem Video gegeben.",
      },
      participants: {
        title: "Seminar Teilnehmer",
        detail: "Aufzeichnungen von",
        numSubmissions: "Anzahl Aufzeichnungen",
        noParticipants: "Keine Teilnehmer",
        pos: "Position",
        progress: "Fortschritt",
        name: "Name",
        fetchFail: "Es konnten die Benutzer nicht abgerufen werden",
        userNotFound: "Benutzer nicht gefunden",
        emptySub: "Nichts zu sehen. Leere Einreichung.",
        filter: {
          title: "Benutzer in diesem Seminar filtern",
          userCount: "{n} Nutzer insgesamt",
          progressLabel: "Aktueller Fortschritt",
          progressHint:
            "Alle Benutzer, deren Fortschrittsprozentsatz höher oder gleich diesem Wert ist, werden eingeschlossen.",
          blockLabel: "Aktuellen Block",
          blockHint:
            "Alle Benutzer, die diesen Block überschritten haben, werden eingeschlossen",
          elLabel: "Aktuelles Element",
          elHint:
            "Alle Benutzer, die dieses Element überschritten haben, werden eingeschlossen",
        },
      },
      invitations: {
        fetchFail: "Gesendete Einladungen konnten nicht abgerufen werden",
        revokeFail: "Einladung konnte nicht widerrufen werden",
        invalidEmail: "Ungültige E-Mail erkannt",
      },
    },

    edit: {
      actions: "Abgaben",
      invite: "Teilnehmenr einladen",
      inviteEmails: "E-Mails, die eingeladen werden",
      editBtn: "Seminar bearbeiten",
      blocks: {
        title: "Seminarblöcke",
        addBtn: "Block hinzufügen",
        editBtn: "Block bearbeiten",
        blockTitle: "Blocktitel",
        blockDesc: "Blockbeschreibung",
      },
      elements: {
        title: "Elemente in diesem Block",
        addBtn: "Element hinzufügen",
        editBtn: "Element bearbeiten",
        elTitle: "Elemententitel",
        elDesc: "Elementenbeschreibung",
        uploadVid: "Neues Video hochladen",
        embedVid: "Video einbetten",
        file: "Videodatei",
        link: "Link zum Video",

        video_presentation:
          "Benutzer nehmen ein Video auf, nichts zu spezifizieren",
        give_feedback:
          "Benutzer geben Feedback zu einem hochgeladenen Video, das hier nicht angegeben werden muss",
        watch_feedback:
          "Benutzer sehen sich ein für sie erstelltes Feedback-Video an, das hier nicht angegeben werden muss",
        gap_text: {
          title:
            'Geben Sie Lücken an, indem Sie "__" (doppelter Unterstrich) setzen',
          answers: "Lücken füllen (Probenlösung)",
        },
        addRow: "Zeile hinzufügen",
        addCategory: "Kategorie hinzufügen",
        newCategory: "Neue Kategorie",
        categoryTitle: "Kategorietitel",
        deleteElement: {
          title: "Element löschen",
          detail:
            "Sind sie sich sicher, dass sie dieses Element löschen wollen?",
        },
        rateable:
          "Die hochgeladenen Videos werden geteilt und können bewertet werden",
        embed_youtube:
          "Wenn du Youtube Videos verlinkst stelle sicher, dass diese integriert werden können!",
        required_numer_of_submissions:
          "Benötigte Anzahl an Abgaben für dieses Element",
        maximum_number_of_submissions:
          "Maximale Anzahl an Abgaben für dieses Element",
        number_range_1_100: "Erlaubte Nummern zwischen 1-100",
        number_range_0_10: "Erlaubte Nummern zwischen 0-10",
        max_condition:
          "Max muss größer oder gleich der benötigten Anzahl an Abgaben sein",
        required_number_of_shared_submissions:
          "Benötigte Anzahl an GETEILTEN Abgaben für dieses Element",
        shared_number_condition:
          "Die Benötigte Anzahl an geteilten Abgaben muss kleiner oder gleich der Anzahl an benötigten Abgaben sein",
        max_number_of_shared_submissions:
          "Maximale Anzahl an GETEILTEN Abgaben für dieses Element",
        timed_video_recording: "Aufnahme mit Zeitbegrenzung",
      },
    },

    name: "Seminarname",
    description: "Seminarbeschreibung",
    loading: "Seminar laden...",
    notFound: "Seminar nicht gefunden",
    noBlocks: "Dieses Seminar hat keine Blöcke",
    progress: "Seminarfortschritt",
    tasks: "Aufgaben",
    skipTask: "Aufgabe überspringen",
    prev: "Zurück",
    next: "Weiter",
    percentComplete: "{percent}% abgeschlossen",
    submittedOn: "Eingereicht am",
    yourSubmission: "Ihre Einreichung",
    yourAnswer: "Deine Antwort",
    sampleSolution: "Probenlösung",

    submissions: {
      videoRecorder: {
        askForFeedback: "Nach Feedback fragen",
        askForFeedbackInfo:
          "Wenn du nach Feedback fragst kann der Seminarveranstalter dein Video sehen und dir Feedback darauf geben",
        expectedTime: "Erwartete Aufnahmezeit:",
        maxAllowedTime: "Maximale Aufnahmezeit:",
        title: "Videorecorder",
        actions: "Aktionen",
        start: "Beginnen",
        stop: "Halt",
        pause: "Pausieren",
        resume: "Fortsetzen",
        upload: "Hochladen",
        reset: "Zurücksetzen",
        flipVideo: "Video umdrehen",
        nameYourFile: "Benennst du deine Datei",
        save: "Speichern",
        uploadYourPres: "Video hochladen",
        privateVid: "Privates Video",
        sharedVid: "Geteiltes Video",
        wouldLikeFeedback:
          "Ja, ich möchte Feedback vom Seminar Besitzer anfordern",
        shared:
          "Deine Aufnahme wird mit allen anderen Seminarteilnehmern geteilt. Bist du sicher, dass du das tun möchtest?",
        notShared:
          "Das folgende Video wird als Einreichung zum aktuellen Element dieses Seminars gespeichert",
      },
    },

    read_text: { title: "Text lesen" },
    watch_video: { title: "Video ansehen" },
    video_presentation: {
      title: "Präsentation aufnehmen",
      submissions: "max. Aufzeichnung(en)",
      reqSubmissions: "benötigte Aufzeichnung(en)",
      sharedSubmissions: "max. geteilte Aufzeichnung(en)",
      reqSharedSubmissions: "benötigte geteilte Aufzeichnung(en)",
      share: "Aufzeichnung teilen",
      unshare: "Aufzeichnung nicht mehr teilen",
      delete: "Aufzeichnung löschen",
      delConfirm: "Sind Sie sicher, dass sie die Aufzeichnung löschen wollen?",
      error:
        "Du brauchst noch {submissions} Aufzeichnung(en) und {sharedSubmissions} geteilte Aufzeichnung(en)!",
    },
    give_feedback: {
      title: "Feedback geben",
      text: "Nehmen Sie ein Video als Feedback zu diesem Video auf",
      noRating: "Dieses Video wird nicht bewertet",
      nextTitle: "Nehmen Sie ein Video als Feedback zu diesem Video auf",
      yourSubmissions: "Deine Aufzeichnung",
      noVideo: "Kein Video gefunden",
      noVideoDetail:
        "Wir konnten leider kein Video finden, das noch nicht von einem anderen Nutzer geprüft wurde.",
    },
    watch_feedback: {
      no_feedback: "Noch keine Feedbackvideos verfügbar!",
      title: "Feedback ansehen",
      text:
        "Sehen Sie sich dieses Video an, das ein anderer Teilnehmer als Feedback zu Ihrer Präsentation gegeben hat",
    },
    gap_text: {
      title: "Lücken ausfüllen",
      error: "Bitte füllen Sie vor dem Absenden alle Lücken aus",
    },
    rephrase: {
      title: "Umformulieren",
      sentences: "Sätze",
      error: "Bitte fülle alle Felder aus",
    },
    categorize: {
      title: "Sätze Verlinken",
      phrases: "Sätze",
      noMore: "Keine Sätze mehr",
      categories: "Kategorien",
      dnd: "Hier ziehen und ablegen",
      error: "Bitte ordnen Sie alle Sätze in Kategorien ein",
    },
    vr_training: {
      title: "VR Training",
      requiredScore: "Erforderliche Punktzahl",
      yourSubmissions: "Deine Einreichungen",
      score: "Punktzahl",
      refresh: "Einreichungen neuladen",
    },

    nameRequired: "Name erforderlich",
    descRequired: "Beschreibung erforderlich",

    ratings: {
      amusing: "Lustig",
      creative: "Kreativ",
      professional: "Professionell",
    },

    videos: {
      title: "Alle Aufzeichnungen",
      noSubmissions: "Bisher gibt es keine Aufzeichnungen",

      sortBy: {
        title: "Sortieren nach",
        date: "Datum",
      },
      filter: {
        users: {
          title: "Benutzer",
          detail: "Filtern nach Benutzer",
          placeholder: "Filtern nach Name...",
        },
        elements: {
          title: "Elemente",
          detail: "Filtern nach Element",
        },
      },
    },
    vrScores: {
      title: "VR Ergebnisse",
      noVr: "Es gibt keine VR Elemente in diesem Seminar",
    },
  },

  homePage: {
    welcomeMessage: "Wilkommen bei PREZP",
    noPermissions:
      "Wenn Sie sich auf dieser Seite befinden, bedeutet das, dass Sie in einer Firma sind, aber keine Berechtigungen haben. Wenn Seminar-/Schulungsberechtigungen erteilt werden, können Sie das Fenster neu laden und mit dem Durchsuchen beginnen",
  },

  invitations: {
    title: "Einladungen",
    sent: "Gesendete Einladungen",
    revoke: "Revoke invitation",
    noInvitations: "Du hast keine Einladungen",
  },

  zoomControl: "Zoom auf Folie",

  notifications: {
    feedback_received: "Feedback erhalten",
    rate_video: "Neues Video zum Bewerten hochgeladen",
    rating_received: "Neue Bewertung erhalten",
    months: "Monate",
    weeks: "Wochen",
    days: "Tage",
    hours: "Stunden",
    minutes: "Minuten",
    fewSeconds: "vor wenigen Sekunden",
  },
};

export default de;
