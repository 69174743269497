




































import Vue from "vue";
import { Component, Prop } from "vue-property-decorator";

@Component
export default class Gauge extends Vue {
  @Prop({ default: () => 0 }) volume!: number;
  @Prop({ default: () => true }) canToggle!: boolean;
  @Prop({ default: () => false }) isTesting!: boolean;

  get showText() {
    return this.isTesting || (!this.canToggle && this.volume > 0);
  }
}
