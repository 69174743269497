






























































import Vue from "vue";
import { Getter } from "vuex-class";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";

import Storage from "@/core/utils/LocalStorage";
import { Permission } from "@/core/models";
import { UploadAudio, RecordSession } from "./components/add-session";
import Menu from "./components/Menu.vue";

@Component({ components: { UploadAudio, RecordSession, Menu } })
export default class Navigation extends Vue {
  @Prop({ default: () => true }) showLayout!: boolean;
  @Prop({ default: () => false }) isMobile!: boolean;
  @PropSync("isOpen") open!: boolean;
  @Getter("isDarkMode") isDarkMode!: boolean;
  @Getter("profile/getUserEmail") myEmail!: string;
  @Getter("profile/getUserHasPrivileges") hasPrivileges!: boolean;
  @Getter("profile/permissions") permissions?: Permission[];
  @Getter("seminars/currentLogo") selectedSeminarLogo?: string;

  get hasTrainingPermissions() {
    return this.permissions?.some(x => x.type === "session_training");
  }

  mini = false;
  expanded = false;
  get imageWidth() {
    if (this.isMobile) return "20%";
    else return "80%";
  }
  get navHeadingHeight() {
    const width = "width:100%;height:";
    if (this.isMobile) return width + "80px";
    else return width + "100px";
  }

  get isOnSessions() {
    return this.$route.fullPath.startsWith("/sessions");
  }

  toggleMini() {
    this.mini = !this.mini;
    if (this.mini) this.expanded = false;
    else if (this.isOnSessions) this.expanded = true;
    Storage.setMenuMini(this.mini);
  }

  created() {
    this.mini = Storage.getMenuMini();
  }

  @Watch("$route")
  routeChanged() {
    if (this.isOnSessions && !this.mini) this.expanded = true;
  }
}
