import { MutationTree } from "vuex";

import { SessionsState } from ".";
import { Session, MetaAnalysis, MetaSettings } from "@/core/models/sessions";

const mutations: MutationTree<SessionsState> = {
  set(state, payload) {
    state.sessions = payload;
  },
  setMeta(state, meta) {
    if (!meta) return;
    const { settings, data } = meta;
    state.metaSettings = settings;
    state.metaAnalysis = data;
  },

  // Mutations to get sessions
  getSessionsSuccess(state: SessionsState, response: any): void {
    const { sessions, metaAnalysisDTO } = response;
    state.sessions = Session.parseSessions(sessions);

    try {
      const { data, settings } = metaAnalysisDTO;
      if (data) {
        state.metaAnalysis = (data as object[]).map(d => new MetaAnalysis(d));
        state.metaSettings = new MetaSettings(settings);
      }
    } catch (error) {
      console.log(error);
    }
  },

  deleteSessionSuccess(state: SessionsState, id: number): void {
    const index = state.sessions.findIndex(s => s.ID === id);
    if (index != -1) {
      const copy = state.sessions.slice(0);
      copy.splice(index, 1);
      state.sessions = copy;
    }
  },
};

export default mutations;
