

























import Vue from "vue";
import { Component, PropSync, Watch } from "vue-property-decorator";
import LocalStorage from "@/core/utils/LocalStorage";
import VolumeTester from "./volume-tester";

@Component({ components: { VolumeTester } })
export default class RecordingTipDialog extends Vue {
  @PropSync("dialog", { type: Boolean, default: () => false })
  dialogProp!: boolean;

  volume = 0;
  dontShowTip = false;
  closeTip() {
    if (this.dontShowTip) LocalStorage.setShouldShowRecordingTip(false);
    this.dialogProp = false;
  }

  showVolume = false;
  @Watch("dialogProp")
  dialogChanged() {
    this.showVolume = this.dialogProp;
  }
}
