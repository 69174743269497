

































import Vue from "vue";
import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";

// @ts-ignore
import Plyr from "plyr/dist/plyr.polyfilled.js";
import "plyr/dist/plyr.css";

@Component
export default class VideoPlayer extends Vue {
  @Prop({ default: () => 0 }) id!: number;
  @Prop({ default: () => "" }) url!: string;
  @Prop({ default: () => 0.5 }) volume!: number;
  @PropSync("shouldStop", { type: Boolean }) shouldStopProp!: boolean;
  @PropSync("shouldPlay", { type: Boolean }) shouldPlayProp!: boolean;

  plyr: any = null;
  loading = false;
  @Ref("video") videoRef!: HTMLVideoElement;

  get playerId() {
    return "player-" + this.id;
  }
  get isYT() {
    return this.url && this.url.includes("youtube.com/embed");
  }

  loadVideo() {
    // this.loading = true;
    this.plyr = new Plyr("#" + this.playerId, {
      ratio: "16:9",
      autopause: true,
      pip: false,
      controls: [
        "play-large",
        "play",
        "progress",
        "current-time",
        "mute",
        "volume",
        "settings",
        "fullscreen",
      ],
    });
    this.plyr.on("ready", () => {
      this.loading = false;
      this.plyr.volume = this.volume;
      if (this.shouldPlayProp) {
        this.shouldPlayProp = false;
        this.plyr.play();
      }
    });
    this.plyr.on("error", () => {
      this.loading = false;
      console.log(`Could not load video ${this.url}`);
    });
    this.plyr.on("play", () => {
      this.plyr.toggleControls(true);
    });
  }
  mounted() {
    // this.loadVideo();
  }
  @Watch("url")
  urlChanged() {
    // this.loadVideo();
  }

  @Watch("shouldStopProp", { immediate: true })
  shouldStopChanged() {
    if (this.shouldStopProp) {
      this.shouldStopProp = false;
      if (this.videoRef) this.videoRef.pause();
      // if (this.plyr && this.plyr.ready) {
      //   this.shouldStopProp = false;
      //   this.plyr.stop();
      // }
    }
  }

  @Watch("shouldPlayProp", { immediate: true })
  shouldPlayChanged() {
    if (this.shouldPlayProp) {
      this.shouldPlayProp = false;
      if (this.videoRef) this.videoRef.play();
      // if (this.plyr && this.plyr.ready) {
      //   this.shouldPlayProp = false;
      //   this.plyr.play();
      // }
    }
  }

  beforeDestroy() {
    if (this.videoRef) {
      this.videoRef.pause();
      this.videoRef.currentTime = 0;
    }
    //@ts-ignore
    if (document.pictureInPictureElement)
      //@ts-ignore
      document.exitPictureInPicture();
  }
}
