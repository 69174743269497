





















































import Vue from "vue";
import { Presentation, Recording } from "@/core/models/";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";
import {
  AudioRecorder,
  RecordList,
  PresentationSelect,
  RecordingTipDialog,
} from "./components";
import VideoRecorderWithPresentation from "@/components/common/VideoRecorderWithPresentations.vue";
import VolumeTester from "./components/volume-tester";
import LocalStorage from "@/core/utils/LocalStorage";

@Component({
  components: {
    RecordList,
    VolumeTester,
    AudioRecorder,
    PresentationSelect,
    RecordingTipDialog,
    VideoRecorderWithPresentation,
  },
})
export default class Recorder extends Vue {
  @Prop({ default: () => false }) canSelect!: boolean;
  @Prop({ default: () => null }) presentaion!: Presentation;
  @PropSync("dialog", { type: Boolean, default: () => false })
  dialogProp!: boolean;

  viewMode = "slide";
  isRecording = false;
  recordingType: "audio" | "video" = "audio";
  records: Recording[] = [];
  selectedPresentation: Presentation | null = null;
  recordingCreated(record: Recording) {
    this.records = [record, ...this.records];
  }

  @Watch("selectedPresentation", { immediate: true })
  presChange() {
    if (this.presentaion) this.selectedPresentation = this.presentaion;
  }

  tipDialog = false;
  showVolume = false;
  @Watch("dialogProp")
  dialogChanged() {
    this.showVolume = this.dialogProp;
    if (!this.dialogProp)
      document.documentElement.classList.remove("no-scroll");
    else {
      const show = LocalStorage.getShouldShowRecordingTip();
      if (show) this.$nextTick(() => (this.tipDialog = true));
      document.documentElement.classList.add("no-scroll");
    }
  }
}
