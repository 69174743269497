import FileObject from "./FileObject";
import { Timestamp } from "./sessions";

export class AnalysisStatus {
  static NotStarted = "NotStarted";
  static Failed = "Failed";
  static Running = "Running";
  static Succeeded = "Succeeded";
  static AnalyzingAudio = "AnalyzingAudio";
  static AudioDone = "AudioDone";
  static AnalyzingText = "AnalyzingText";
  static TextDone = "TextDone";
}

export interface Recording {
  id: number;
  blob: any;
  audioURI?: string;
  videoURI?: string;
  title: string;
  recordedAt: Date;
  locale: "en" | "de";
  presentationId?: number;
  timestamps?: Timestamp[];
  type: "audio" | "video";
}

export interface ApiRecording {
  id: number;
  file: any;
  title: string;
  recordedAt: Date;
  locale: "en" | "de";
  audioURI?: string;
  videoURI?: string;
  presentationId?: number;
  timestamps: Timestamp[];
  type: "audio" | "video";
}

export interface HowToVideo {
  id: number;
  title: string;
  description: string;
  video: FileObject;
  createdAt: string;
}

export interface SelectItem<T = string> {
  text: string;
  value: T;
  disabled?: boolean;
}

export interface ChartClick {
  type: "index" | "offset";
  value: number;
}

export interface GroupInvitation {
  id: number;
  inviter: string;
  group: string;
  createdAt: string;
}

export interface SentInvitation {
  id: number;
  user: string;
  createdAt: string;
}

export interface ProgressPoint {
  date: Date;
  value: number;
}
export interface SessionsProgress {
  voicePitch: ProgressPoint[];
  voiceVolume: ProgressPoint[];
  pauses: ProgressPoint[];
  sentiment: ProgressPoint[];
  wordsPerMinute: ProgressPoint[];
  headMovement: ProgressPoint[];
  handMovement: ProgressPoint[];
}

export interface SessionAvearges {
  ppm: number;
  wpm: number;
  sentiment: number;
  averagePitch: number;
  averageVolume: number;
  averageHeadMovement: number;
  averageHandMovement: number;
}

export type FilterUser = {
  name: string;
  email: string;
  isManager: boolean;
};
export type FilterGroup = {
  name: string;
  isManager: boolean;
  users: FilterUser[];
};

export interface Interval {
  start: number;
  end: number;
}

export interface AdminUser {
  role: string;
  email: string;
  username: string;
  isActive: boolean;
  customerId: string;
  emailVerified: boolean;
  activeUntil: string;
  permissions: Permission[];
}

export interface Permission {
  type: string;
  endDate: string;
}

export interface Invitation {
  id: number;
  createdAt: Date;
  seminarName?: string;
  email?: string;
}

export type ElementType =
  | "read_text"
  | "watch_video"
  | "video_presentation"
  | "give_feedback"
  | "watch_feedback"
  | "gap_text"
  | "rephrase"
  | "categorize"
  | "vr_training";

type BaseSubmission = {
  id: number;
  skipped: boolean;
  userId: string;
  elementId: number;
  seminarId: number;
  createdAt: string;
  isShared: boolean;
};

type TypedSubmission =
  | {
      type: "gap_text";
      answers: string[];
    }
  | {
      type: "vr_training";
      score: string;
    }
  | {
      type: "rephrase";
      answers: string[];
    }
  | {
      type: "categorize";
      answers: string[][];
    }
  | {
      videoURI?: string;
      type: "read_text" | "watch_video";
    }
  | {
      videoURI?: string;
      feedbackFor?: string;
      isShared: boolean;
      IsRateable: boolean;
      feedbackForSubmission?: string;
      askForFeedback?: boolean;
      videoRatings?: Rating[];
      type: "give_feedback" | "video_presentation" | "watch_feedback";
    };

export type Submission = BaseSubmission & TypedSubmission;

export interface Rating {
  id: number;
  submissionId: number;
  rater: string;
  type: string;
  createdAt: string;
}

type BaseElement = {
  id: number;
  seminarId: number;
  blockId: number;
  title: string;
  body: string;
  submissions?: Submission[];
  completed?: boolean;
  skipped?: boolean;
};
type TypedElement =
  | { type: "read_text" }
  | { type: "watch_video"; videoURI: string }
  | {
      type: "video_presentation";
      rateableSubmissions?: boolean;
      requiredSubmissions?: number;
      maxNumShared?: number;
      maxSubmissions?: number;
      requiredNumShared?: number;
      isTimed?: boolean;
      expectedLen?: number;
      maxLen?: number;
      submission?: { uri: string; createdAt: Date };
    }
  | {
      type: "give_feedback";
      forElement: number;
      rateableSubmissions?: boolean;
      submission?: { uri: string; createdAt: Date; feedbackFor: string };
    }
  | { type: "watch_feedback"; submission?: { uri: string; createdAt: Date } }
  | {
      type: "gap_text";
      text: string;
      answers: string[];
      submission?: string[];
    }
  | {
      type: "rephrase";
      sentences: string[];
      answers: string[];
      submission?: string[];
    }
  | {
      type: "categorize";
      words: string[];
      categories: string[];
      answers: string[][];
      submission?: { words: string[] }[];
    }
  | { type: "vr_training"; requiredScore: number; submission?: number };

export type SeminarElement = BaseElement & TypedElement;

export interface SeminarBlock {
  id: number;
  seminarId: number;
  title: string;
  description: string;
  elements: SeminarElement[];
}

export interface SimpleUser {
  email: string;
  username: string;
  id: string;
}

export interface SeminarUser {
  id: string;
  email: string;
  username: string;
  isAdmin: boolean;
  joinedAt: string;
  currentBlock: number;
  currentElement: number;
  progress: number;
}

export interface Seminar {
  retrieved: boolean;
  logo?: string;
  id: number;
  owner?: SimpleUser;
  ownerId: string;
  isOwner?: boolean;
  name: string;
  createdAt: Date;
  description: string;
  blocks: SeminarBlock[];
  users?: SeminarUser[];
  elements?: SeminarElement[];
  submissions?: Submission[];
  allSubmissions?: Submission[];
}
