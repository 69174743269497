import { ActionTree } from "vuex";

import router from "@/core/router";
import { RootState } from "../root";
import api from "@/core/utils/api";
import UserData from "@/core/models/UserData";
import Storage from "@/core/utils/LocalStorage";
import LocalStorage from "@/core/utils/LocalStorage";

const actions: ActionTree<RootState, RootState> = {
  async loadEverything({ state, commit, dispatch }) {
    const token = Storage.getAccessToken();

    if (!token) {
      const { href } = window.location;
      if (!href.includes("/auth")) router.replace("/auth/login");
      state.dataLoadSuccess = true;
      commit("loaded");
      return;
    }

    state.dataLoading = true;
    try {
      const res = await api.get("/api/Users/Data");
      const data = new UserData(res);
      dispatch("setEverything", data);
      state.active = data.active;
      state.dataLoadSuccess = true;
    } catch (error) {
      Storage.clearStorage();
      router.replace("/auth/login");
    }

    state.dataLoading = false;
  },

  clearEverything({ dispatch, state }) {
    state.dataLoading = true;
    dispatch("seminars/clear");
    dispatch("managed/clear");
    dispatch("profile/clear");
    dispatch("dashboard/clear");
    dispatch("goals/clearGoals");
    dispatch("groups/clearGroups");
    dispatch("news/clearArticles");
    dispatch("meetings/clearMeetings");
    dispatch("sessions/clearSessions");
    dispatch("presentations/clearPresentations");
    LocalStorage.clearStorage();

    state.dataLoadSuccess = true;
    state.dataLoading = false;
  },

  displaySnackbar({ commit }, message = "") {
    if (!message) return;
    commit("displaySnackbar", message);
  },

  setEverything({ commit, dispatch }, data: UserData) {
    commit("profile/set", data.user);
    dispatch("seminars/set", data.seminars || []);

    if (!data.active) return;

    commit("profile/setBaseline", data.baseline);
    if (!data.metaAnalysis || !data.metaSettings)
      commit("sessions/setMeta", {
        data: data.metaAnalysis,
        settings: data.metaSettings,
      });
    commit("notifications/setLastChecked", data.lastCheckedNotifications);
  },
};

export default actions;
