import { ActionTree } from "vuex";

import api from "@/core/utils/api";
import {
  AddElementAction,
  AddSubmissionAction,
  CreateSeminarRequest,
  EditSeminarRequest,
  GetSubsRequest,
  RateVideoAction,
  RemoveElementRequest,
} from "@/core/models/requests";
import {
  Seminar,
  SeminarBlock,
  SeminarElement,
  Submission,
  Rating,
} from "@/core/models";
import { requestFromElement, typeToEndpoint } from "@/core/utils/seminars";

import { SeminarsState } from ".";
import { RootState } from "../root";

const updateArray = (state: SeminarsState) => {
  const idx = state.seminars.findIndex(x => x.id === state.selected?.id) || -1;
  if (idx === -1) return;
  state.seminars[idx] = Object.assign({}, state.selected);
};

export const actions: ActionTree<SeminarsState, RootState> = {
  clear({ state }) {
    state.adding = false;
    state.loading = false;
    state.retrieved = false;
    state.updating = false;
    state.selected = undefined;
    state.seminars = [];
  },

  set({ state }, seminars: Seminar[]) {
    state.seminars = seminars;
    if (seminars.length > 0) state.selected = seminars[0];
    state.adding = false;
    state.loading = false;
    state.retrieved = false;
    state.updating = false;
  },

  setindices({ state }, indices: [number, number]) {
    state.blockIndex = indices[0];
    state.elementIndex = indices[1];
  },

  next({ state, getters }) {
    if (!state.selected) return;
    const selectableUpTo = getters["selectableUpTo"] as [number, number];
    const atEnd =
      selectableUpTo[0] < state.blockIndex ||
      (selectableUpTo[0] === state.blockIndex &&
        selectableUpTo[1] === state.elementIndex);
    if (atEnd) return;

    const lastElIdxOfBlock =
      state.selected.blocks[state.blockIndex].elements.length - 1;
    const lastBlockIdx = state.selected.blocks.length - 1;
    if (state.elementIndex === lastElIdxOfBlock) {
      if (state.blockIndex === lastBlockIdx) return;
      state.blockIndex++;
      state.elementIndex = 0;
    } else state.elementIndex++;
  },

  prev({ state }) {
    if (!state.selected) return;
    if (state.elementIndex === 0) {
      if (state.blockIndex === 0) return;
      state.blockIndex--;
      state.elementIndex =
        state.selected.blocks[state.blockIndex].elements.length - 1;
    } else state.elementIndex--;
  },

  // get one/all
  async getAll({ state }) {
    state.loading = true;
    try {
      const seminars = (await api.get("/api/Seminar")) as Seminar[];
      state.seminars = seminars.map(x => {
        x.retrieved = false;
        return x;
      });
      state.retrieved = true;
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  async get({ state, getters, dispatch }, id?: number) {
    const existing = state.seminars.find(x => x.id === id);
    if (existing && existing.retrieved) {
      state.selected = existing;
      return;
    }

    state.loading = true;
    try {
      if (!id && !state.seminars.length) await dispatch("getAll");
      const seminarId = id || state.seminars[0].id;
      const seminar = (await api.get(`/api/Seminar/${seminarId}`)) as Seminar;
      seminar.retrieved = true;
      const newArr = state.seminars.slice(0);
      const idx = newArr.findIndex(x => x.id === id);
      if (idx === -1) newArr.push(seminar);
      else newArr[idx] = seminar;

      // check submissions
      const blocks = seminar.blocks.map(b => {
        const elements = b.elements.map(e => {
          // subs for this el
          const all = seminar.submissions?.filter(x => x.elementId === e.id);
          const subs = all?.filter(x => !x.skipped);
          const skippedSubs = all?.filter(x => x.skipped);

          // element has been skipped
          if (all?.length === 1 && all[0].skipped)
            return { ...e, skipped: true };

          // check video_presentation differently
          let completed = false;
          if (e.type === "video_presentation") {
            const numReq = e.requiredSubmissions || 1;
            const numReqShared = e.requiredNumShared || 0;
            const sharedSubs = subs?.filter(x => x.isShared).length || 0;
            completed =
              (subs?.length || 0) >= numReq && sharedSubs >= numReqShared;

            // if not completed - might be skipped
            if (!completed) {
              const isSkipped = (skippedSubs?.length || 0) > 0;
              if (isSkipped) return { ...e, skipped: true };
            }
          } else completed = (subs?.length || 0) > 0;
          return { ...e, completed };
        });
        return { ...b, elements };
      });

      newArr[idx] = { ...seminar, blocks };
      state.selected = { ...seminar, blocks };
      state.seminars = newArr;
      const upTo = getters["selectableUpTo"] as [number, number];
      state.blockIndex = upTo[0];
      state.elementIndex = upTo[1];
    } catch (error) {
      console.log(error);
      state.selected = undefined;
    }
    state.loading = false;
  },

  // add/edit seminar
  async create({ state, rootGetters }, d: CreateSeminarRequest) {
    state.loading = true;
    try {
      const data = new FormData();
      data.append("name", d.name);
      data.append("description", d.description);
      if (d.logo) data.append("logo", d.logo);
      const { id, createdAt } = (await api.post("/api/Seminar", data, {
        headers: { ContentType: "multipart/form-data" },
      })) as any;

      const seminar: Seminar = {
        id: Number(id),
        name: d.name,
        description: d.description,
        createdAt: new Date(createdAt),
        retrieved: true,
        blocks: [],
        ownerId: rootGetters["profile/id"],
        owner: {
          id: rootGetters["profile/id"],
          username: rootGetters["profile/getUsername"],
          email: rootGetters["profile/getUserEmail"],
        },
        users: [
          {
            id: rootGetters["profile/id"],
            username: rootGetters["profile/getUsername"],
            email: rootGetters["profile/getUserEmail"],
            isAdmin: true,
            currentBlock: 1,
            currentElement: 1,
            joinedAt: new Date().toISOString(),
            progress: 0,
          },
        ],
        submissions: [],
      };
      state.seminars = [...state.seminars, seminar];
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  async edit({ state }, d: EditSeminarRequest) {
    state.loading = true;
    try {
      const data = new FormData();
      data.append("seminarId", JSON.stringify(d.seminarId));
      data.append("name", d.name);
      data.append("description", d.description);
      if (d.logo) data.append("logo", d.logo);
      (await api.patch("/api/Seminar/Edit", data, {
        headers: {
          ContentType: "multipart/form-data",
        },
      })) as any;
      if (!state.selected) return;
      state.selected.id = d.seminarId;
      state.selected.name = d.name;
      state.selected.description = d.description;
      state.selected.logo = d.logo?.toString();
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  async remove({ state }, id: number) {
    state.loading = true;
    try {
      await api.delete(`/api/Seminar/${id}`);
      state.selected = undefined;
      const idx = state.seminars.findIndex(x => x.id === id);
      if (idx !== -1) state.seminars.slice(idx, 1);
    } catch (error) {
      console.log(error);
    }
    state.loading = false;
  },

  // Add/edit block
  async addBlock({ state }, block: SeminarBlock) {
    state.adding = true;
    try {
      const data = {
        title: block.title,
        description: block.description,
        seminarId: block.seminarId,
      };
      const id = (await api.post("/api/Seminar/CreateBlock", data)) as number;
      block.id = id;
      if (!state.selected) return;
      if (!state.selected.blocks) state.selected.blocks = [];
      state.selected.blocks.push(block);
    } catch (error) {
      console.log(error);
    }
    state.adding = false;
  },

  async editBlock({ state }, block: SeminarBlock) {
    state.updating = true;
    try {
      const data = { ...block, elements: null };
      await api.patch("/api/Seminar/Block", data);
      if (!state.selected) throw new Error("No current seminar");
      const newSelected = Object.assign({}, state.selected);
      const idx = newSelected.blocks.findIndex(x => x.id === block.id);
      if (idx !== -1) {
        newSelected.blocks[idx].title = block.title;
        newSelected.blocks[idx].description = block.description;
        state.selected = newSelected;
      }
    } catch (error) {
      console.log(error);
    }
    state.updating = false;
  },

  async removeBlock({ state }, id: number) {
    state.updating = true;
    try {
      await api.delete(`/api/Seminar/DeleteBlock/${id}`);
      if (!state.selected) throw new Error("Seminar not found");

      // set stuff
      const newSelected = { ...state.selected };
      const newBlocks = state.selected.blocks.slice(0);
      const elIds = newBlocks.flatMap(x => x.elements.map(x => x.id));
      const newSubs = state.selected.submissions?.slice(0) || [];
      newSelected.blocks = newBlocks.filter(x => x.id !== id);
      newSelected.submissions = newSubs.filter(
        x => !elIds.includes(x.elementId),
      );

      // update stuff
      state.selected = Object.assign({}, newSelected);
      updateArray(state);
    } catch (error) {
      console.log(error);
    }
    state.updating = false;
  },

  // add/edit element
  async addElement({ state }, { element, file }: AddElementAction) {
    state.adding = true;
    try {
      // send request
      const request = requestFromElement(element);
      const endpoint = typeToEndpoint(element.type);
      if (!request) return;

      // send request
      let res: any;
      if (request.type === "watch_video") {
        if (file) request.data.append("videoFile", file);
        res = (await api.post(endpoint, request.data, {
          headers: { ContentType: "multipart/form-data" },
        })) as any;
      } else res = (await api.post(endpoint, request.data)) as any;

      // setup new element to add
      element.id = res.id;

      // add video uri if type is watch_video
      if (element.type === "watch_video") element.videoURI = res.uri;

      // find seminar/block
      const newArr = state.seminars.slice(0);
      const sIdx = newArr.findIndex(x => x.id === element.seminarId);
      if (sIdx === -1) return;
      const bIdx = newArr[sIdx].blocks.findIndex(x => x.id === element.blockId);
      if (bIdx === -1) return;

      // Add element to current and to array
      newArr[sIdx].blocks[bIdx].elements.push(element);
      const els = newArr[sIdx].elements || [];
      newArr[sIdx].elements = [...els, element];
      state.seminars = [...newArr];
      state.selected = Object.assign({}, newArr[sIdx]);
    } catch (error) {
      console.log(error);
    }
    state.adding = false;
  },

  async editElement({ state }, { element, file }: AddElementAction) {
    state.updating = true;
    try {
      const endpoint = typeToEndpoint(element.type);
      const request = requestFromElement(element);
      if (!request) throw new Error("Element type not found");

      if (request.type === "watch_video") {
        request.data.append("videoURI", (element as any).videoURI);
        request.data.append("elementId", element.id.toString());
        if (file) request.data.append("videoFile", file);
        await api.post(endpoint, request.data, {
          headers: { ContentType: "multipart/form-data" },
        });
      } else
        await api.post(endpoint, { ...request.data, elementId: element.id });

      if (!state.selected) return;
      const newSelected = { ...state.selected };

      // update blocks
      const blockIdx = newSelected.blocks.findIndex(
        x => x.id === element.blockId,
      );
      if (blockIdx !== -1) {
        const idx = newSelected.blocks[blockIdx].elements.findIndex(
          x => x.id === element.id,
        );
        newSelected.blocks[blockIdx].elements[idx] = element;
      }

      // update elements
      if (newSelected.elements) {
        const elIdx =
          newSelected.elements?.findIndex(x => x.id === element.id) || -1;
        if (elIdx !== -1) newSelected.elements[elIdx] = element;
      }

      // update selected
      state.selected = Object.assign({}, newSelected);

      // update array
      updateArray(state);
    } catch (error) {
      console.log(error);
    }
    state.updating = false;
  },

  async removeElement({ state }, { blockId, id }: RemoveElementRequest) {
    state.updating = true;
    try {
      await api.delete(`/api/Elements/${id}`);
      if (!state.selected) throw new Error("Seminar not found");

      const newSelected = { ...state.selected };

      // remove subs
      const newSubs = newSelected.submissions?.slice(0) || [];
      newSelected.submissions = newSubs.filter(x => x.elementId !== id);

      // remove element from block
      const idx = newSelected.blocks?.findIndex(x => x.id === blockId) || -1;
      if (idx !== -1 && newSelected.blocks?.length) {
        const els = newSelected.blocks[idx].elements;
        newSelected.blocks[idx].elements = els.filter(x => x.id !== id);
      }

      // update array + selected
      state.selected = Object.assign({}, newSelected);
      updateArray(state);
    } catch (error) {
      console.log(error);
    }
    state.updating = false;
  },

  async markComplete({ state, getters }, skip: boolean) {
    const id = (getters.element as SeminarElement).id;
    if (!id) throw new Error("Element id not found");

    // element already skipped/completed
    const el = state.selected?.elements?.find(x => x.id === id);
    if (el?.skipped || el?.completed) return;

    // create empty submission for this element
    if (skip) {
      state.skipping = true;
      try {
        await api.get(`/api/Submissions/SkipElement/${id}`);
      } catch (error) {
        throw new Error("Could not skip");
      }
      state.skipping = false;
    }

    // update state
    if (!state.selected) return;
    const updated = { ...state.selected };

    if (skip)
      updated.blocks[state.blockIndex].elements[
        state.elementIndex
      ].skipped = true;
    else {
      updated.blocks[state.blockIndex].elements[
        state.elementIndex
      ].completed = true;
      updated.blocks[state.blockIndex].elements[
        state.elementIndex
      ].skipped = false;
    }

    state.selected = Object.assign({}, updated);
    updateArray(state);
  },

  addSubmission({ state }, { submission, blockId }: AddSubmissionAction) {
    if (!state.selected) return;
    const seminar = { ...state.selected };
    const blockIndex = seminar.blocks.findIndex(x => x.id === blockId);
    if (blockIndex === -1) return;

    const elementIndex = seminar.blocks[blockIndex].elements.findIndex(
      x => x.id === submission.elementId,
    );
    if (elementIndex === -1) return;

    const submissions = seminar.submissions || [];
    submissions.push(submission);
    seminar.submissions = submissions;
    seminar.blocks[blockIndex].elements[
      elementIndex
    ].submissions = submissions.slice(0);
    console.log(submissions);
    state.selected = Object.assign({}, seminar);
  },

  async getFeedbackVideos({ state, dispatch }, id: number) {
    state.gettingFeedback = true;
    try {
      const end = `/api/Submissions/FeedbackForUser/${id}`;
      const res = (await api.get(end)) as Submission[];
      state.feedbackVideos.set(id, res);
    } catch (error) {
      console.log(error);
      dispatch("displaySnackbar", error, { root: true });
    }
    state.gettingFeedback = false;
  },

  async toggleShared({ state, dispatch }, id: number) {
    try {
      await api.patch(`/api/Submissions/ToggleShared/${id}`);
      const newSelected = Object.assign({}, state.selected);
      if (
        !state.seminars.length ||
        !newSelected ||
        !newSelected.submissions?.length ||
        !newSelected.elements?.length ||
        !newSelected.blocks?.length
      )
        return;

      // find current sub
      const sub = newSelected.submissions?.find(x => x.id === id);
      if (!sub) return;

      // update selected
      newSelected.submissions = [
        ...newSelected.submissions.filter(x => x.id !== id),
        { ...sub, isShared: !sub.isShared },
      ];

      // video got shared/unshared - check if el becomes complete/incomplete
      const elIdx = newSelected.elements?.findIndex(
        x => x.id === sub.elementId,
      );
      if (elIdx !== -1) {
        const el = newSelected.elements[elIdx];
        if (el.type === "video_presentation") {
          const numShared = newSelected.submissions.filter(
            x => x.elementId === el.id && !x.skipped && x.isShared,
          ).length;
          const reqShared = el.requiredNumShared || 0;

          // update el on its own
          newSelected.elements[elIdx].completed = numShared >= reqShared;

          // update el in block
          const blockIdx = newSelected.blocks.findIndex(
            x => x.id === el.blockId,
          );
          if (blockIdx !== -1) {
            const elInBlockIdx = newSelected.blocks[
              blockIdx
            ].elements.findIndex(x => x.id === el.id);
            if (elInBlockIdx !== -1)
              newSelected.blocks[blockIdx].elements[elInBlockIdx].completed =
                numShared >= reqShared;
          }
        }
      }

      // update state
      state.selected = Object.assign({}, newSelected);
      updateArray(state);
    } catch (err) {
      dispatch("displaySnackbar", err, { root: true });
    }
  },

  async rateVideo(
    { state, rootGetters, dispatch },
    { submissionId, type }: RateVideoAction,
  ) {
    try {
      const rating = (await api.post("/api/Submissions/Rate", {
        submissionId,
        type,
      })) as Rating | null;

      if (!state.selected) return;
      const newSelected = { ...state.selected };

      if (!newSelected.submissions?.length || !state.seminars.length) return;

      const subIdx =
        newSelected.submissions?.findIndex(x => x.id === submissionId) || -1;
      if (subIdx === -1) return;

      const ratings = ((newSelected.submissions[subIdx] as any).videoRatings ||
        []) as Rating[];
      const userId = rootGetters["profile/id"] as string;

      if (!rating) {
        const rIdx = ratings.findIndex(
          x => x.type === type && x.rater === userId,
        );
        if (rIdx !== -1) ratings.splice(rIdx, 1);
        (newSelected.submissions[subIdx] as any).videoRatings = ratings;
      } else
        (newSelected.submissions[subIdx] as any).videoRatings = [
          ...ratings,
          rating,
        ];

      // update current
      state.selected = Object.assign({}, newSelected);

      // update array
      updateArray(state);
    } catch (error) {
      dispatch("displaySnackbar", error, { root: true });
    }
  },

  async removeSubmission({ state }, id: number) {
    try {
      await api.delete(`/api/Submissions/${id}`);
      const newSelected = Object.assign({}, state.selected);

      // remove from submissions array
      // const elId =
      //   newSelected.submissions?.find(x => x.id === id)?.elementId || -1;
      // const elIdx = newSelected.elements?.findIndex(x => x.id === elId) || -1;
      const newSubs = newSelected.submissions?.filter(x => x.id !== id) || [];
      newSelected.submissions = newSubs;

      // remove from array of submissions on corresponding element
      // if (elIdx !== -1 && !!newSelected.elements?.length) {
      //   const elSubs = [...(newSelected.elements[elIdx].submissions || [])];
      //   newSelected.elements[elIdx].submissions = elSubs.filter(
      //     x => x.id !== id,
      //   );

      //   // remove from array of submssions on corresponding element of corresponding block
      //   const blockId = newSelected.elements[elIdx].blockId;
      //   const blockIdx = newSelected.blocks.findIndex(x => x.id === blockId);
      //   if (blockIdx !== -1)
      //     newSelected.blocks[blockIdx].elements[
      //       elIdx
      //     ].submissions = elSubs.filter(x => x.id !== id);
      // }

      // update state
      state.selected = Object.assign({}, newSelected);
      updateArray(state);
    } catch (error) {
      console.log(error);
    }
  },

  async getSubmissions(
    { state, dispatch },
    { seminarId, elementId }: GetSubsRequest,
  ) {
    try {
      const end = `/api/Seminar/Submissions/${seminarId}/${elementId}`;
      const subs = (await api.get(end)) as Submission[];

      const updated = Object.assign({}, state.selected);
      if (!updated) return;
      const oldSubs = updated.submissions || [];
      updated.submissions = [
        ...oldSubs.filter(x => x.elementId !== elementId),
        ...subs,
      ];

      if (updated.elements?.length) {
        const idx = updated.elements.findIndex(x => x.id === elementId);
        if (idx !== undefined && idx !== -1)
          updated.elements[idx].submissions = subs;
      }

      // todo check if element is complete

      // update
      state.selected = Object.assign({}, updated);
      updateArray(state);
    } catch (error) {
      console.log(error);
      dispatch("displaySnackbar", error, { root: true });
    }
  },
};
