import { SharingUser } from "@/core/store/managed";
import { Session } from ".";

//eslint-disable no-usused-vars
export enum LookupWordType {
  default = "default",
  stop = "stop_word",
  filler = "filler_word",
}

export interface WordSegment {
  word: string;
  offset: number;
  duration: number;
}

export interface LookupWord {
  text: string;
  occurrences: number;
  type: LookupWordType;
}

export interface SessionSlide {
  imageURI: string;
  offset: number;
  words: WordSegment[];
  lookupWords: LookupWord[];
  averagePitch: number;
  averageVolume: number;
  wordsPerMinute: number;
  duration: number;
}

type AverageData = { averagePitch: number; averageVolume: number };
export interface TimedAverage {
  endSeconds: number;
  startSeconds: number;
  audioAnalysisAverages: AverageData;
}

export interface SessionStatus {
  SessionId: number;
  AnalysisStatus?: string;
  ProgressInPercent: number;
}

export interface SharedSessions {
  user: SharingUser;
  sessions: Session[];
}

export interface BaselineSession {
  id: number;
  name: string;
  uploadedDate: Date;
  averageVolume: number;
  averagePitch: number;
}

export interface SessionScores {
  speechSpeedScore: number;
  pausesScore: number;
  repetitionScore: number;
  fillerWordsScore: number;
  speakingClearlyScore: number;
  overallScore: number;
}
