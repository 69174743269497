



























































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, PropSync, Watch } from "vue-property-decorator";

@Component
export default class UploadSession extends Vue {
  @PropSync("dialogProp", { default: () => false }) dialog!: boolean;

  @Getter("sessions/uploadingSession") loading!: boolean;
  @Action("sessions/uploadSession")
  uploadSessionAction!: (d: FormData) => Promise<void>;
  @Action("displaySnackbar") displaySnackbar!: (msg: string) => void;

  get canSubmit() {
    return this.name && this.audioFile;
  }

  handleFileChange(file: File | null) {
    if (!file || !file.type.startsWith("audio/")) {
      this.audioFile = null;
      this.displaySnackbar(this.$t("upsess.rejected_file").toString());
      // @ts-ignore
      this.$refs.form.reset();
      this.locale = this.$i18n.locale;
      return;
    }
    this.audioFile = file;
    if (!this.name)
      this.name = file.name.substring(0, file.name.lastIndexOf("."));
  }

  name = "";
  locale = this.$i18n.locale;
  formValid = false;
  audioFile: File | null = null;
  localeItems = [
    { text: "English", value: "en" },
    { text: "Deutsch", value: "de" },
  ];
  async uploadSession() {
    if (!this.formValid) {
      const message = this.$t("forms.invalidform").toString();
      this.displaySnackbar(message);
      return;
    }
    if (!this.audioFile) {
      const message = this.$t("forms.audiofile").toString();
      this.displaySnackbar(message);
      return;
    }

    this.dialog = false;
    const data = new FormData();
    data.append("sessionName", this.name);
    data.append("file", this.audioFile);
    data.append("locale", this.locale);
    await this.uploadSessionAction(data);

    // reset form
    this.name = "";
    this.audioFile = null;
    (this.$refs.form as HTMLFormElement).resetValidation();
  }

  @Watch("$i18n.locale", { immediate: true })
  langChanged() {
    this.locale = this.$i18n.locale;
  }
}
