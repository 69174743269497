class Condition {
  public Type: string;
  public Value: any;

  constructor(type: string, value: any) {
    this.Type = type;
    this.Value = value;
  }

  public static empty(): Condition {
    return new Condition("", "");
  }
}

export default Condition;
