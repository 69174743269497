import FileObject from "./FileObject";
import { Slide } from "./sessions";

class Presentation {
  public ID: number;
  public Name: string;
  public UploadDate: Date;
  public File: FileObject;
  public Slides: Slide[];

  constructor(
    id: number,
    name: string,
    date: string,
    file: FileObject,
    slides: Slide[],
  ) {
    this.ID = id;
    this.Name = name;
    this.File = file;
    this.Slides = slides;
    this.UploadDate = new Date(Date.parse(date));
  }

  public static parse(pres: any): Presentation {
    const file = FileObject.parseFile(pres.file);
    const presSlides = (pres.slides as any[]) || [];
    const slides = presSlides.map(x => Slide.parse(x));
    return new Presentation(
      pres.id,
      pres.name || "",
      pres.createdAt,
      file!,
      slides,
    );
  }
}

export default Presentation;
