




























import Vue from "vue";
import { Getter } from "vuex-class";
import UploadSession from "./UploadSession.vue";
import { Component, Prop } from "vue-property-decorator";

@Component({ components: { UploadSession } })
export default class UploadAudio extends Vue {
  @Prop({ default: () => false }) mini!: boolean;
  @Getter("sessions/uploadingSession") loading!: boolean;
  dialog = false;
}
