



























































import Vue from "vue";
import api from "@/core/utils/api";
import { Recording } from "@/core/models";
import { Component, Prop } from "vue-property-decorator";
import RecordingDialog from "./RecordingDialog.vue";

@Component({ components: { RecordingDialog } })
export default class RecordingItem extends Vue {
  @Prop({ default: () => ({} as Recording) }) recording!: Recording;

  get title() {
    return this.recording.title;
  }
  get lang() {
    const { locale } = this.recording;
    return locale === "en" ? "English" : "Deutsch";
  }

  edit(d: any) {
    this.$emit("edit", d);
  }

  dialog = false;
  removing = false;
  removeDialog = false;
  async removeRecord() {
    this.removeDialog = false;
    this.removing = true;
    try {
      const end = `/api/Recordings/${this.recording.id}`;
      await api.delete(end);
      this.$emit("remove");
    } catch (error) {
      console.log(error);
    }
    this.removing = false;
  }
}
