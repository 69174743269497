import { Module } from "vuex";

import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { RootState } from "../root";
import { User } from "@/core/models";
import BaselineAnalysis from "@/core/models/sessions/BaselineAnalysis";

export interface ProfileState {
  isAuthenticated: boolean;
  user?: User;
  loading: boolean;
  allUsers: User[];
  allUsersLoading: boolean;
  allUsersRetrieved: boolean;
  changeUserRoleLoading: boolean;
  changedUserRoleEmail: string;

  forgotPasswordLoading: boolean;
  forgotEmailSent: boolean;
  resetPasswordLoading: boolean;
  confirmEmailSent: boolean;
  confirmEmailLoading: boolean;

  createCompanyLoading: boolean;
  createCompanySuccess: boolean;
  editCompanyLoading: boolean;
  upgradeLoading: boolean;

  baseline: BaselineAnalysis;
}

export const state: ProfileState = {
  user: undefined,
  loading: false,
  isAuthenticated: false,

  allUsers: [],
  allUsersLoading: false,
  allUsersRetrieved: false,
  changeUserRoleLoading: false,
  changedUserRoleEmail: "",

  forgotPasswordLoading: false,
  forgotEmailSent: false,
  resetPasswordLoading: false,
  confirmEmailSent: false,
  confirmEmailLoading: false,

  createCompanyLoading: false,
  createCompanySuccess: false,
  editCompanyLoading: false,
  upgradeLoading: false,

  baseline: new BaselineAnalysis(null),
};

const namespaced: boolean = true;

export const profile: Module<ProfileState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
