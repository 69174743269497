














import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component } from "vue-property-decorator";
import { SelectItem, Seminar } from "@/core/models";

@Component
export default class SeminarSelect extends Vue {
  @Getter("seminars/selectedId") selectedId!: number;
  @Getter("seminars/seminars") seminars!: Seminar[];
  @Action("seminars/get") getSeminar!: (id: number) => Promise<void>;

  get items(): SelectItem<number>[] {
    return this.seminars.map(x => ({
      text: `${x.name} (Owner: ${x.owner?.username})`,
      value: x.id,
    }));
  }

  select(id: number) {
    this.getSeminar(id);
  }

  get shown() {
    return this.$route.path.includes("/seminars");
  }
}
