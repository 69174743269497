import { Roles } from "@/core/models";
import { Permission } from "./interfaces";

class User {
  public ID: string;
  public Email: string;
  public Username: string;
  public Role: string;
  public EmailVerified: boolean;
  public CustomerId: string;
  public Permissions: Permission[];

  constructor(
    email: string,
    username: string,
    role = Roles.User,
    emailVerified = false,
    customerId = "",
    permissions = [],
    id = "",
  ) {
    this.Email = email;
    this.Username = username;
    this.Role = role;
    this.EmailVerified = emailVerified;
    this.CustomerId = customerId;
    this.Permissions = permissions;
    this.ID = id;
  }

  public static parse(data: any): User {
    return new User(
      data.email,
      data.username,
      data.role,
      data.emailVerified,
      "",
      data.permissions,
      data.id,
    );
  }
}

export default User;
