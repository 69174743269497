import RecordRTC, { MediaStreamRecorder } from "recordrtc";

class VideoRecorder {
  stream: MediaStream | undefined;
  recorder: RecordRTC | undefined;
  muted = false;

  async start() {
    try {
      this.stream = await navigator.mediaDevices.getUserMedia({
        audio: true,
        video: true,
      });
      this.recorder = new RecordRTC(this.stream, {
        recorderType: MediaStreamRecorder,
        mimeType: "video/mp4",
        type: "video",
      });
      this.recorder.startRecording();
      return this.stream;
    } catch (error) {
      console.log(error);
      return undefined;
    }
  }

  pause() {
    this.recorder?.pauseRecording();
  }

  resume() {
    this.recorder?.resumeRecording();
  }

  async stop(cb: (blob: Blob | undefined) => void) {
    this.recorder?.stopRecording(() => {
      this.stream?.getTracks().forEach(t => t.stop());
      const initBlob = this.recorder?.getBlob();
      if (!initBlob) {
        cb(undefined);
        return;
      }
      cb(initBlob);
    });
  }

  async reset() {
    this.recorder?.reset();
  }

  toggleMuted() {
    if (this.muted) {
      this.muted = false;
      this.stream?.getAudioTracks().forEach(x => (x.enabled = true));
    } else {
      this.stream?.getAudioTracks().forEach(x => (x.enabled = false));
      this.muted = true;
    }
  }

  async save(fileName: string) {
    this.recorder?.save(fileName);
  }
}

export default VideoRecorder;
