import { TimedAverage, ChartEntry } from ".";

class AudioAnalysis {
  public Pitch: ChartEntry[];
  public Volume: ChartEntry[];
  public Pauses: ChartEntry[];

  public AveragePitch: number;
  public AverageVolume: number;
  public AveragePauses: number;
  public PausesPerMinute: number;
  public TotalPauseTime: number;
  public CalculationLength: number;

  public TimedAverages: TimedAverage[];
  public IsCompleted: boolean;

  constructor(
    pitch: ChartEntry[],
    vol: ChartEntry[],
    pauses: ChartEntry[],
    avgPitch: number,
    avgVolume: number,
    avgPause: number,
    totalPause: number,
    isCompleted: boolean,
    times: TimedAverage[],
    calcLen: number,
    ppm: number,
  ) {
    this.Pitch = pitch;
    this.Volume = vol;
    this.Pauses = pauses;
    this.AveragePitch = avgPitch;
    this.AverageVolume = avgVolume;
    this.AveragePauses = avgPause;
    this.TotalPauseTime = totalPause;
    this.IsCompleted = isCompleted;
    this.TimedAverages = times;
    this.CalculationLength = calcLen;
    this.PausesPerMinute = ppm;
  }

  public static parseAudioAnalysis(analysis: any): AudioAnalysis {
    if (!analysis) return this.blank();

    const pitch = this.parseEntries(analysis.pitch);
    const volume = this.parseEntries(analysis.volume).map(x => {
      x.Value = x.Value * 100;
      return x;
    });
    const pauses = this.parseEntries(analysis.pauses);
    return new AudioAnalysis(
      pitch,
      volume,
      pauses,
      analysis.averagePitch,
      analysis.averageVolume,
      analysis.averagePauseTime,
      analysis.totalPauseTime,
      analysis.isCompleted,
      analysis.timedAverages,
      analysis.calculationLength,
      analysis.pausesPerMinute,
    );
  }

  public static blank(): AudioAnalysis {
    return new AudioAnalysis([], [], [], 0, 0, 0, 0, false, [], 0, 0);
  }

  private static parseEntries(entries: any[]): ChartEntry[] {
    return entries.map(entry => {
      const time = entry.timeStamp;
      const value = Math.round(entry.value * 100) / 100;
      return new ChartEntry(time, value);
    });
  }
}

export default AudioAnalysis;
