












































import Vue from "vue";
import api from "@/core/utils/api";
import { HowToVideo } from "@/core/models";
import { Component, Prop, Watch } from "vue-property-decorator";
import VideoPlayer from "@/components/common/VideoPlayer.vue";

@Component({ components: { VideoPlayer } })
export default class VideoTiem extends Vue {
  @Prop({ default: () => 0 }) id!: number;
  open = false;
  loading = false;
  hasLoaded = false;
  shouldStop = false;
  shouldPlay = false;
  video: HowToVideo | null = null;

  async getVideo() {
    if (this.hasLoaded) return;
    this.loading = true;
    try {
      const end = `/api/Showcase/${this.id}`;
      const video = (await api.get(end)) as HowToVideo;
      this.video = video;
      this.hasLoaded = true;
    } catch (error) {
      console.log(error);
    }
    this.loading = false;
  }

  @Watch("loading")
  loadingChanged() {
    if (!this.loading && this.hasLoaded) this.shouldPlay = true;
  }

  @Watch("open")
  openChanged() {
    if (this.open) this.shouldPlay = true;
    else this.shouldStop = true;
  }
}
