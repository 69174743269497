const key = process.env.VUE_APP_LOCAL_STORAGE_KEY as string;

class StorageModel {
  accessToken: string;
  refreshToken: string;
  userRole: string;
  miniMenu: boolean;
  darkMode: boolean;
  locale: string;
  shouldShowRecordingTip: boolean;
  inputDevice: string;

  constructor() {
    this.locale = "de";
    this.userRole = "User";
    this.miniMenu = false;
    this.darkMode = false;
    this.accessToken = "";
    this.shouldShowRecordingTip = true;
    this.inputDevice = "";
    this.refreshToken = "";
  }

  static parse(json: string) {
    const obj = JSON.parse(json);
    const storage = new StorageModel();
    storage.locale = obj.locale;
    storage.userRole = obj.userRole;
    storage.miniMenu = obj.miniMenu;
    storage.darkMode = obj.darkMode;
    storage.accessToken = obj.accessToken;
    storage.shouldShowRecordingTip = obj.shouldShowRecordingTip;
    storage.inputDevice = obj.inputDevice;
    storage.refreshToken = obj.refreshToken;
    return storage;
  }
}

class LocalStorage {
  private static getStorage() {
    const item = localStorage.getItem(key);
    if (!item) return new StorageModel();
    return StorageModel.parse(item);
  }

  static getAccessToken() {
    return this.getStorage().accessToken;
  }
  static setAccessToken(token: string) {
    const storage = this.getStorage();
    storage.accessToken = token;
    localStorage.setItem(key, JSON.stringify(storage));
  }

  static getRefreshToken() {
    return this.getStorage().refreshToken;
  }
  static setRefreshToken(token: string) {
    const storage = this.getStorage();
    storage.refreshToken = token;
    localStorage.setItem(key, JSON.stringify(storage));
  }

  static getUserRole() {
    return this.getStorage().userRole;
  }
  static setUserRole(role: string) {
    const storage = this.getStorage();
    storage.userRole = role;
    localStorage.setItem(key, JSON.stringify(storage));
  }

  static getMenuMini() {
    return this.getStorage().miniMenu;
  }
  static setMenuMini(isMini: boolean) {
    const storage = this.getStorage();
    storage.miniMenu = isMini;
    localStorage.setItem(key, JSON.stringify(storage));
  }

  static getDarkMode() {
    return this.getStorage().darkMode;
  }
  static setDarkMode(mode: boolean) {
    const storage = this.getStorage();
    storage.darkMode = mode;
    localStorage.setItem(key, JSON.stringify(storage));
  }

  static getLocale() {
    return this.getStorage().locale;
  }
  static setLocale(locale: string) {
    const storage = this.getStorage();
    storage.locale = locale;
    localStorage.setItem(key, JSON.stringify(storage));
  }

  static getShouldShowRecordingTip() {
    return this.getStorage().shouldShowRecordingTip;
  }
  static setShouldShowRecordingTip(tip: boolean) {
    const storage = this.getStorage();
    storage.shouldShowRecordingTip = tip;
    localStorage.setItem(key, JSON.stringify(storage));
  }

  static getInputDevice() {
    return this.getStorage().inputDevice;
  }
  static setInputDevice(id: string) {
    const storage = this.getStorage();
    storage.inputDevice = id;
    localStorage.setItem(key, JSON.stringify(storage));
  }

  static clearStorage() {
    LocalStorage.setAccessToken("");
    LocalStorage.setRefreshToken("");
    LocalStorage.setInputDevice("");
    LocalStorage.setMenuMini(false);
    LocalStorage.setUserRole("");
    LocalStorage.setDarkMode(false);
  }
}

export default LocalStorage;
