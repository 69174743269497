






























































import Vue from "vue";
import { Getter, Action } from "vuex-class";
import { Component } from "vue-property-decorator";

@Component
export default class ProfileDropdown extends Vue {
  @Getter("profile/getUserName") name!: string;
  @Getter("profile/getUserEmail") email!: string;
  @Getter("profile/loading") profileLoading!: string;
  @Action("profile/logoutUser") logout!: () => void;
  @Action("clearEverything") clearEverything!: () => void;

  get firstLetter() {
    return this.name.charAt(0);
  }

  logoutUser() {
    this.logout();
    this.$sessionHub.stop();
    this.$notificationHub.stop();
    this.$router.replace("/auth/login");
  }

  refresh() {
    location.reload();
  }
}
