import { Condition, User } from "..";

class AssignedGoal {
  public GoalId: number;
  public Target: number;
  public Creator: User;
  public CreatedAt: Date;
  public Conditions: Condition[];
  public Progress: number;
  public IsCompleted: boolean;

  // Helpers
  public ProgressValue: number;
  public ProgressText: string;
  public ProgressColor: string;

  constructor(
    id: number,
    t: number,
    c: User,
    cAt: Date,
    cond: Condition[],
    pr: number = 0,
    isComp: boolean = false,
  ) {
    this.GoalId = id;
    this.Target = t;
    this.Creator = c;
    this.CreatedAt = cAt;
    this.Conditions = cond;
    this.Progress = pr;
    this.IsCompleted = isComp;

    const progress = Math.round((this.Progress / this.Target) * 10000) / 100;
    this.ProgressValue = progress;

    this.ProgressText = `You have completed ${
      this.IsCompleted ? "all" : this.Progress
    } ${this.Progress === 1 ? "session" : "sessions"}`;

    this.ProgressColor = "error";
    if (this.ProgressValue > 10) this.ProgressColor = "grey";
    if (this.ProgressValue > 40) this.ProgressColor = "primary";
    if (this.ProgressValue > 90) this.ProgressColor = "success";
  }

  public static parse(data: any): AssignedGoal {
    const date = new Date(data.createdAt);
    const conds = (data.conditions as any[]).map(
      c => new Condition(c.type, c.value),
    );
    const creator = User.parse(data.creator);
    return new AssignedGoal(
      data.goalId,
      data.target,
      creator,
      date,
      conds,
      data.progress,
      data.isCompleted,
    );
  }
}

export default AssignedGoal;
