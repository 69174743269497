import { ActionTree } from "vuex";

import { GroupsState } from "../";
import router from "@/core/router";
import api from "@/core/utils/api";
import { RootState } from "../../root";
import i18n from "@/core/plugins/i18n";
import { SentInvitation } from "@/core/models";

export type InviteUsersData = { groupId: number; emails: string[] };

const actions: ActionTree<GroupsState, RootState> = {
  async inviteUsers({ state, commit }, data: InviteUsersData) {
    state.addUsersLoading = true;
    let inv: SentInvitation[] = [];
    try {
      const end = "/api/Groups/InviteUsers";
      inv = (await api.post(end, data)) as SentInvitation[];
      const emails = data.emails as string[];
      if (inv.length != emails.length) {
        const str = emails
          .filter(x => !inv.some(y => y.user.includes(x)))
          .join(", ");
        const msg = i18n.t("snack.groups.unadded").toString();
        commit("displaySnackbar", msg + str, { root: true });
      }
    } catch (error) {
      console.log(error);
    }
    state.addUsersLoading = false;
    return inv;
  },

  async changeUserRole({ state }, data: any) {
    const { groupId, isManager, userEmail, isCurrentUser } = data;

    state.changeUserRoleLoading = true;
    state.editedUserEmail = userEmail;
    try {
      await api.put("/api/Groups/ChangeUserRole", data);
      const group = state.groups.find(g => g.ID == groupId);
      if (!group) return;
      if (isCurrentUser) group.IsManager = isManager;
      const user = group.Users.find(u => u.Email == userEmail);
      if (user) user.IsManager = isManager;
    } catch (error) {
      console.log(error);
    }
    state.changeUserRoleLoading = false;
    state.editedUserEmail = data.userEmail;
  },

  async removeUser({ state }, data: any) {
    const { userEmail, groupId, isCurrentUser } = data;
    state.removeUserLoading = true;
    state.removedUserEmail = userEmail;
    try {
      const endpoint = `/api/Groups/RemoveUser?groupId=${data.groupId}&userEmail=${data.userEmail}`;
      await api.delete(endpoint);
      const gidx = state.groups.findIndex(g => g.ID == groupId);
      if (gidx === -1) return;
      const group = state.groups[gidx];
      const uidx = group.Users.findIndex(u => u.Email == userEmail);
      if (uidx === -1) return;
      group.Users.splice(uidx, 1);

      // if same as user, remove group
      if (isCurrentUser) {
        state.groups.splice(gidx, 1);
        router.push("/groups");
      }
    } catch (error) {
      console.log(error);
    }
    state.removeUserLoading = false;
    state.removedUserEmail = "";
  },

  async leaveGroup({ state }, id: number) {
    const end = `/api/Groups/Leave/${id}`;
    await api.delete(end);
    const idx = state.groups.findIndex(x => x.ID == id);
    state.groups.splice(idx, 1);
  },
};

export default actions;
