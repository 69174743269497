











































import Vue from "vue";
import { Action, Getter } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import LocalStorage from "@/core/utils/LocalStorage";
import { checkIsMobile } from "@/components/layout/util";
import { AppBar, Navigation, Footer } from "@/components/layout";

@Component({ components: { AppBar, Navigation, Footer } })
export default class App extends Vue {
  @Getter("theme") theme!: any;
  @Getter("appLoading") appLoading!: boolean;
  @Getter("dataLoading") dataLoading!: boolean;
  @Getter("showSnackbar") showSnackbar!: boolean;
  @Getter("snackbarText") snackbarText!: boolean;
  @Getter("dataLoadSuccess") dataLoadSuccess!: boolean;

  @Getter("profile/loading") profileLoading!: string;
  @Getter("profile/getUserEmail") userEmail!: string;
  @Getter("profile/permissions") permissions!: string[] | undefined;
  @Getter("profile/isAuthenticated") isAuthenticated!: boolean;
  @Getter("profile/userEmailVerified") userEmailVerified!: boolean;
  @Getter("profile/id") id!: string;

  @Action("loadEverything") loadEverything!: () => Promise<void>;
  @Action("clearEverything") clearEverything!: () => void;

  showLayout = true;
  isMobile = false;
  navOpened = false;
  showBanner = false;

  toggleNav() {
    this.navOpened = !this.navOpened;
  }

  get hideNav() {
    return !this.permissions?.length;
  }

  get backgroundColor() {
    return this.theme.background + "";
  }
  get loaded() {
    return !this.dataLoading && this.dataLoadSuccess;
  }

  get hideFooter() {
    return this.$route.path.startsWith("/sessions");
  }

  refresh() {
    this.loadEverything();
  }
  reload() {
    location.reload();
  }
  clear() {
    this.clearEverything();
    this.$router.replace("/auth/login");
  }

  @Watch("$route", { immediate: true })
  urlChanged() {
    this.showLayout = !this.$route.path.startsWith("/auth");
    this.showBanner = !this.userEmailVerified;
    if (this.$route.path.startsWith("/auth")) this.showBanner = false;

    const { name } = this.$route;
    document.title = `PREZP${name ? " | " + name : ""}`;
  }

  @Watch("profileLoading", { immediate: true })
  userLoaded() {
    this.showBanner = !this.userEmailVerified;
    if (this.$route.path.startsWith("/auth")) this.showBanner = false;
  }

  @Watch("isAuthenticated")
  authStateChange() {
    if (this.isAuthenticated) {
      if (!this.$sessionHub.isStarted()) this.$sessionHub.start();
      if (!this.$notificationHub.isStarted()) this.$notificationHub.start();
    } else {
      this.$sessionHub.stop();
      this.$notificationHub.stop();
    }
  }

  get snackbarStyle() {
    if (this.$route.path === "/sessions/list") return "padding-bottom: 120px;";
    return "padding-bottom: 0px";
  }
  async load() {
    await this.loadEverything();
  }
  created() {
    this.load();
    this.isMobile = checkIsMobile();
    const darkMode = LocalStorage.getDarkMode();
    this.$vuetify.theme.dark = darkMode;
  }

  mounted() {
    // freshchat integration
    console.log("CREATED!");
    console.log(this.$freshchat);
    this.$freshchat.open();
    console.log(this.$freshchat.ready);
  }

  @Watch("id", { immediate: true })
  updateId() {
    this.$freshchat.setExternalId(this.id);
  }

  @Watch("userEmail", { immediate: true })
  updateEmail() {
    this.$freshchat.setUserProperties({ email: this.userEmail });
  }
}
