


























import Vue from "vue";
import { Component, Prop, PropSync, Watch } from "vue-property-decorator";

@Component
export default class ImageSlider extends Vue {
  @Prop({ default: () => [] }) images!: string[];
  @Prop({ default: () => "30vw" }) maxWidth!: string;
  @Prop({ default: () => "50vh" }) maxHeight!: string;
  @Prop({ default: () => "20vw" }) maxWidthVertical!: string;
  @PropSync("reset", { default: () => false }) shouldReset!: boolean;

  idx = 0;
  url = "";
  loading = false;
  width = this.maxWidth;
  get len() {
    return this.images.length - 1;
  }

  async loadImage(img: HTMLImageElement) {
    return new Promise(res => {
      img.onload = () => res(true);
    });
  }
  async setImage() {
    this.loading = true;
    const url = this.images[this.idx];
    const img = new Image();
    img.src = url;
    await this.loadImage(img);
    this.url = url;
    const ratio = img.naturalHeight / img.naturalWidth;
    if (ratio > 1) this.width = this.maxWidthVertical;
    else this.width = this.maxWidth;
    this.loading = false;
  }
  next() {
    if (this.idx === this.len) this.idx = 0;
    else this.idx = this.idx + 1;
    this.setImage();
    this.$emit("changed", this.idx);
  }
  prev() {
    if (this.idx === 0) this.idx = this.len;
    else this.idx = this.idx - 1;
    this.setImage();
    this.$emit("changed", this.idx);
  }

  @Watch("images", { immediate: true })
  imagesChanged() {
    this.idx = 0;
    this.setImage();
  }
  @Watch("maxWidth", { immediate: true })
  maxWidthChanged() {
    this.width = this.maxWidth;
  }
  @Watch("shouldReset", { immediate: true })
  resetSlides() {
    if (this.shouldReset) {
      this.idx = 0;
      this.setImage();
      this.shouldReset = false;
    }
  }
}
