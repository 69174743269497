import _Vue from "vue";
import store from "@/core/store";
import { Session } from "@/core/models";
import LocalStorage from "@/core/utils/LocalStorage";
import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";

declare module "vue/types/vue" {
  interface Vue {
    $freshchat: any;
    $sessionHub: CombinedVueInstance<
      _Vue,
      object,
      { start: () => void; stop: () => void; isStarted: () => boolean },
      object,
      Record<never, any>
    >;
  }
}

export default {
  install: (Vue: typeof _Vue) => {
    let isStarted = false;
    const sessionHub = new Vue();
    Vue.prototype.$sessionHub = sessionHub;

    // Create connection
    const getToken = () => LocalStorage.getAccessToken() || "";
    const url = "/session-hub";
    const connection = new HubConnectionBuilder()
      .withUrl(url, { accessTokenFactory: getToken })
      .configureLogging(LogLevel.Error)
      .build();

    // Handle update
    const handleUpdate = async (id: number) => {
      console.log("Fetching session ", id);
      await store.dispatch("sessions/getSingleSession", id);
      const list = store.getters["sessions/getSessions"] as Session[];
      const session = list.find(x => x.ID === id);
      if (!session) return;
      if (session.Completed) await store.dispatch("goals/getGoals");
    };

    // Open connection
    const start = async () => {
      try {
        isStarted = true;
        console.log("HUB: trying to connect to Hub " + url);
        await connection.start();
        console.log("HUB: Connection started for hub: " + url);
        connection.on("SessionStatusUpdate", async id => {
          console.log("HUB: Update received for session ", id);
          await handleUpdate(id);
          sessionHub.$emit("session-status-update", id);
        });
      } catch (error) {
        if (getToken()) setTimeout(() => start(), 5000);
      }
    };

    if (getToken()) start();
    Vue.prototype.$sessionHub.start = () => start();
    Vue.prototype.$sessionHub.stop = async () => {
      await connection.stop();
      isStarted = false;
      console.log("HUB: Connection stopped");
    };
    Vue.prototype.$sessionHub.isStarted = () => isStarted;
  },
};
