import { ActionTree } from "vuex";

import { ProfileState } from ".";
import router from "@/core/router";
import api from "@/core/utils/api";
import { RootState } from "../root";
import { User } from "@/core/models";
import UserData from "@/core/models/UserData";
import LocalStorage from "@/core/utils/LocalStorage";
import BaselineAnalysis from "@/core/models/sessions/BaselineAnalysis";

const actions: ActionTree<ProfileState, RootState> = {
  async logoutUser({ state, dispatch }) {
    state.user = undefined;
    state.isAuthenticated = false;
    LocalStorage.clearStorage();
    dispatch("clearEverything", null, { root: true });
  },
  clear: ({ state }) => (state.baseline = new BaselineAnalysis(null)),

  // login/register/load
  async loginUser({ state, rootState, commit, dispatch }, { email, password }) {
    state.loading = true;
    try {
      const res = (await api.post("/api/Account/Login", {
        email,
        password,
        from: "web",
      })) as any;
      const { userData: data, accessToken, refreshToken } = res as any;

      const userData = new UserData(data);
      LocalStorage.setAccessToken(accessToken);
      LocalStorage.setRefreshToken(refreshToken);

      state.isAuthenticated = true;
      router.push("/dashboard");
      dispatch("setEverything", userData, { root: true });
      rootState.dataLoadSuccess = true;
      rootState.active = userData.active;
    } catch (error) {
      commit("userLoadError");
    }

    state.loading = false;
    commit("loaded", null, { root: true });
  },
  async registerUser({ state, rootState, commit, dispatch }, data) {
    state.loading = true;
    try {
      const res = (await api.post("/api/Account/Register", data)) as any;
      const { user, emailToken } = res;
      const userData = new UserData({ user });
      dispatch("sendConfirmEmail", { email: user.email, token: emailToken });
      dispatch("setEverything", userData, { root: true });
      rootState.dataLoadSuccess = true;
      rootState.active = userData.active;
      router.replace("/auth/new-user");
    } catch (error) {
      commit("userLoadError");
    }
    state.loading = false;
    commit("loaded", null, { root: true });
  },

  async loadUser({ state, commit, dispatch }) {
    state.loading = true;
    try {
      const res = (await api.get("/api/Users/GetByToken")) as any;
      const { email, username, role, emailVerified, permissions } = res;
      console.log(res);
      LocalStorage.setUserRole(role);
      state.user = new User(email, username, role, emailVerified, permissions);
    } catch (error) {
      dispatch(
        "displaySnackbar",
        error.description || "Could not load user data",
        { root: true },
      );
      commit("userLoadError");
    }
    state.loading = false;
  },

  // sending emails
  async resetPassword({ state, dispatch }, data) {
    state.resetPasswordLoading = true;
    try {
      await api.post("/api/Account/MakePasswordResetResetPassword", data);
      router.push("/auth/login");
    } catch (error) {
      dispatch("displaySnackbar", error.description || "Reset password fail", {
        root: true,
      });
      console.log(error);
    }
    state.resetPasswordLoading = false;
  },
  async sendConfirmEmail({ state, dispatch }, payload) {
    const emailTimeout = 10 * 1000;
    state.confirmEmailLoading = true;
    try {
      await api.post("/api/Account/SendEmailVerification", payload);
      state.confirmEmailSent = true;
      setTimeout(() => (state.confirmEmailSent = false), emailTimeout);
    } catch (error) {
      dispatch("displaySnackbar", error.description || "Reset password fail", {
        root: true,
      });
      state.confirmEmailSent = false;
    }
    state.confirmEmailLoading = false;
  },
  async confirmEmail({ state, dispatch }, data) {
    state.confirmEmailLoading = true;
    try {
      await api.post("/api/Account/ConfirmEmail", data);
    } catch (error) {
      dispatch(
        "displaySnackbar",
        error.description || "Error when confirming email",
        { root: true },
      );
    }
    state.confirmEmailLoading = false;
  },
};

export default actions;
