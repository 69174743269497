import {
  User,
  Article,
  Goal,
  Group,
  Presentation,
  Meeting,
  AssignedGoal,
} from "./";
import { Seminar } from "./interfaces";
import { Session, MetaAnalysis, MetaSettings } from "./sessions";
import BaselineAnalysis from "./sessions/BaselineAnalysis";

class UserData {
  user: User;
  goals?: { assignedGoals: AssignedGoal[]; createdGoals: Goal[] } | null;
  presentations?: Presentation[];
  meetings?: Meeting[];
  articles?: Article[];
  groups?: Group[];
  sessions?: Session[];
  metaAnalysis?: MetaAnalysis[];
  metaSettings?: MetaSettings;
  seminars?: Seminar[];
  baseline?: BaselineAnalysis;
  active: boolean;
  lastCheckedNotifications?: Date;

  constructor(data: any) {
    const {
      user,
      goals,
      presentations,
      meetings,
      articles,
      groups,
      sessions,
      metaAnalysis,
      initialSession,
      active,
      seminars,
      lastCheckedNotifications,
    } = data;
    this.user = User.parse(user);
    this.active = active;
    this.seminars = seminars;

    if (!active) return;

    if (goals) {
      const { assignedGoals, createdGoals } = goals;
      this.goals = {
        assignedGoals: (assignedGoals as any[]).map(ag =>
          AssignedGoal.parse(ag),
        ),
        createdGoals: (createdGoals as any[]).map(cg => Goal.parse(cg)),
      };
    } else this.goals = { assignedGoals: [], createdGoals: [] }; // set goals

    if (presentations)
      this.presentations = (presentations as any[]).map(p =>
        Presentation.parse(p),
      );
    else this.presentations = []; // set presentations

    if (meetings)
      this.meetings = (meetings as any[]).map(m => Meeting.parse(m));
    else this.meetings = []; // set meetings

    if (articles)
      this.articles = (articles as any[]).map(a => Article.parseArticle(a));
    else this.articles = []; // set articles

    if (groups) this.groups = (groups as any[]).map(g => Group.parseGroup(g));
    else this.groups = []; // set groups

    if (sessions)
      this.sessions = (sessions as any[]).map(s =>
        Session.parseSingleSession(s),
      );
    this.sessions = []; // set sessions

    if (metaAnalysis && metaAnalysis.data)
      this.metaAnalysis = metaAnalysis.data.map(
        (d: any) => new MetaAnalysis(d),
      );
    if (metaAnalysis && metaAnalysis.settings)
      this.metaSettings = new MetaSettings(metaAnalysis.settings);

    this.baseline = new BaselineAnalysis(initialSession); // set initial session

    this.lastCheckedNotifications = lastCheckedNotifications
      ? lastCheckedNotifications
      : new Date("2021-05-01T10:00:00");
  }
}
export default UserData;
