class Notification {
  date: Date;
  type: NotificationType;
  user?: NotificationUser;
  submissionId: number;
  // Submission || Submission id to create a direct link

  constructor(data: any) {
    const { date, type, user, submissionId } = data;
    this.date = new Date(date);
    this.submissionId = submissionId;
    this.type = this.getNotificatoinType(type);
    this.user = user ? new NotificationUser(user) : undefined;
  }

  getNotificatoinType(type: string): NotificationType {
    switch (type) {
      case "feedback_received":
        return NotificationType.FeedbackReceived;
      case "rate_video":
        return NotificationType.RateVideo;
      case "rating_received":
        return NotificationType.RatingReceived;
    }
    throw new Error("Notification type not supported!");
  }
}

enum NotificationType {
  FeedbackReceived = "feedback_received",
  RateVideo = "rate_viedo",
  RatingReceived = "rating_received",
}

class NotificationUser {
  username: string;
  id: string;
  firstname: string;
  lastname: string;

  constructor(data: any) {
    const { id, username, firstname, lastname } = data;
    this.id = id;
    this.username = username;
    this.firstname = firstname;
    this.lastname = lastname;
  }
}

export { Notification, NotificationType, NotificationUser };
