// Scaffold app
import "@babel/polyfill";
import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";
import "./styles.scss";
import "./variables.scss";

// Middleware
import router from "@/core/router";
import store from "@/core/store";
import i18n from "@/core/plugins/i18n";
import vuetify from "@/core/plugins/vuetify";
import { SessionHub, NotificationHub } from "@/core/plugins/signalrconnection";

// Quill
// @ts-ignore
import VueQuillEditor from "vue-quill-editor";
import "quill/dist/quill.core.css"; // import styles
import "quill/dist/quill.snow.css"; // for snow theme
import "quill/dist/quill.bubble.css"; // for bubble theme
Vue.use(VueQuillEditor);

// videojs
import "video.js/dist/video-js.min.css";
import "videojs-record/dist/css/videojs.record.css";

// QuestionHub
Vue.use(SessionHub);
Vue.use(NotificationHub);

import Vuelidate from "vuelidate";
Vue.use(Vuelidate);

// @ts-ignore
import VueMasonry from "vue-masonry-css";
Vue.use(VueMasonry);

// @ts-ignore
import VueProgressiveImage from "vue-progressive-image";
Vue.use(VueProgressiveImage);

import VueFreshchat from "vue-freshchat";
Vue.use(VueFreshchat as any, {
  host: "https://wchat.eu.freshchat.com",
  appToken: "42065161-57a6-412a-8551-0327fb052824",
  config: {},
});

import { DateTime } from "luxon";
Vue.filter("format", (v: any) => {
  let date;
  date = DateTime.fromJSDate(v);
  if (!date.isValid) date = DateTime.fromISO(v);
  return date.toLocaleString((DateTime.DATE_MED as any) as any);
});

Vue.filter("formatwithtime", (v: any) => {
  let date: DateTime | null = null;
  if (typeof v === "string") {
    const parts = v.split(".");
    const format = parts[0] + "+0000";
    date = DateTime.fromISO(format);
  } else date = DateTime.fromJSDate(v);

  return (
    date.toLocaleString(DateTime.DATE_MED as any) +
    ", " +
    date.toLocaleString(DateTime.TIME_SIMPLE as any)
  );
});

console.log(
  "%cPREZP DEV CONSOLE",
  "font-weight: bold; font-size: 20px; color: rgba(255,255,255,0.8)",
);
console.log("%cv1.2.5", "font-size: 40px");

import { Component } from "vue-property-decorator";
Component.registerHooks(["beforeRouteLeave"]);

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: h => h(App),
}).$mount("#app");
