import axios, { AxiosError, AxiosRequestConfig } from "axios";

import store from "@/core/store";
import router from "@/core/router";
import i18n from "@/core/plugins/i18n";
import Storage from "@/core/utils/LocalStorage";

const api = axios.create({ headers: { accept: "application/json" } });

api.interceptors.request.use(config => {
  config.headers["preferred-lang"] = i18n.locale;
  const token = Storage.getAccessToken() || "";
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

let refreshingRequest: any = null;
let requestsToRetry: AxiosRequestConfig[] = [];
const handleUnuth = async (err: AxiosError) => {
  // token expired
  if (err.response?.headers["token-expired"] === "true")
    try {
      // already outgoing request to refresh
      if (refreshingRequest) {
        requestsToRetry.push(err.config);
        return handleError(err);
      }

      // make refresh request
      refreshingRequest = api.post(
        "/api/Account/RefreshJwt",
        {
          token: Storage.getAccessToken() || "",
          refreshToken: Storage.getRefreshToken() || "",
        },
        { skipRefresh: true } as AxiosRequestConfig,
      );

      // await refresh, get tokens, set tokens
      const { accessToken, refreshToken } = await refreshingRequest;
      refreshingRequest = null;
      Storage.setAccessToken(accessToken);
      Storage.setRefreshToken(refreshToken);
      err.config.headers["Authorization"] = `Bearer ${accessToken}`;

      // retry other requests that might have attempted to refresh
      try {
        Promise.all([requestsToRetry.map(x => api(x))]);
      } catch {
        // have to be manually attempted again
        console.log("Could not refresh all previous requests");
      } finally {
        requestsToRetry = [];
      }

      // retry first request
      try {
        return await api(err.config);
      } catch (error) {
        return handleError(error);
      }
    } catch (error) {
      if (router.currentRoute.path != "/auth/login")
        router.replace("/auth/login");
      store.dispatch("profile/logoutUser");
      return Promise.reject<any>();
    }

  // other error
  // logout, then to login page
  if (router.currentRoute.path != "/auth/login") router.replace("/auth/login");
  store.dispatch("profile/logoutUser");
  return handleError(err);
};

// default handling of error
const handleError = (err: AxiosError) => {
  const data = err.response?.data;
  // temp. solution for translated backend errors
  console.log(data);

  let msg = i18n.t("global.err").toString();

  if (data.description == "Incorrect password")
    msg = i18n.t("loginSection.wrongPasswordError").toString();
  else if (data.code === "EMAIL_NOT_VERIFIED")
    msg = i18n.t("loginSection.unconfirmedEmail").toString();
  else if (err.response?.status == 401)
    msg = i18n.t("loginSection.unauthorizedError").toString();

  store.commit("displaySnackbar", msg, { root: true });

  if (typeof data === typeof Array) return Promise.reject<any>(data[0]);
  return Promise.reject<any>(data);
};

// default interceptors
api.interceptors.response.use(
  res => {
    console.log(`${res.config.url} (${res.status})`, res.data);
    return Promise.resolve(res.data);
  },
  async (err: AxiosError) => {
    if (!err.response) return Promise.reject();

    const unauth = err.response.status === 401;
    const tokenExpired = err.response?.headers["token-expired"] === "true";
    const shouldRefresh = !(err.config as any).skipRefresh;
    if ((unauth || tokenExpired) && shouldRefresh)
      return await handleUnuth(err);
    return handleError(err);
  },
);

export default api;
