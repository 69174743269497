import { GetterTree } from "vuex";

import { RootState } from "../../root";
import { GroupsState } from "../../groups";

const getters: GetterTree<GroupsState, RootState> = {
  getVideos: state =>
    state.groups
      .flatMap(g => g.Videos)
      .sort((a, b) => +b.UploadedAt - +a.UploadedAt),

  // Group Videos
  addVideoLoading: state => state.addVideoLoading,
  editVideoLoading: state => state.editVideoLoading,
  editedVideoID: state => state.editedVideoID,
  deleteVideoLoading: state => state.deleteVideoLoading,
  deletedVideoID: state => state.deletedVideoID,
};

export default getters;
