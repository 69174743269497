import { GetterTree } from "vuex";
import { SeminarsState } from ".";
import { RootState } from "../root";

export const getters: GetterTree<SeminarsState, RootState> = {
  gettingAll: state => state.gettingAll,
  loading: state => state.loading,
  skipping: state => state.skipping,
  adding: state => state.adding,
  updating: state => state.updating,
  retrieved: state => state.retrieved,
  seminars: state => state.seminars,
  selected: state => state.selected,
  selectedId: state => state.selected?.id || 0,
  ownsSeminar: state => state.seminars.some(x => x.isOwner),
  isOwner: state => state.selected?.isOwner,
  users: state => state.selected?.users || [],

  currentLogo: state => state.selected?.logo,
  blockIndex: state => state.blockIndex,
  elementIndex: state => state.elementIndex,
  block: state => state.selected?.blocks[state.blockIndex],
  element: state =>
    state.selected?.blocks[state.blockIndex].elements[state.elementIndex],
  selectableUpTo: state => {
    if (!state.selected?.blocks.length) return [0, 0];

    const lastBlockIdx = state.selected.blocks.length - 1;
    for (let i = 0; i <= lastBlockIdx; i++)
      for (let j = 0; j < state.selected.blocks[i].elements.length; j++) {
        const curr = state.selected.blocks[i].elements[j];
        if (!curr.completed && !curr.skipped) return [i, j];
      }
    return [
      lastBlockIdx,
      state.selected.blocks[lastBlockIdx].elements.length - 1,
    ];
  },

  gettingFeedback: state => state.gettingFeedback,
  feedbackVideos: state => state.feedbackVideos.get(state.selected?.id || 0),
};
