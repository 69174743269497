import VueI18n from "vue-i18n";
import { AddElementRequest, ElementType, SeminarElement } from "../models";

export const requestFromElement = (
  el: SeminarElement,
): AddElementRequest | undefined => {
  const data = new FormData();
  switch (el.type) {
    case "read_text":
      return {
        type: "read_text",
        data: {
          title: el.title,
          body: el.body,
          seminarId: el.seminarId,
          blockId: el.blockId,
        },
      };
    case "watch_video":
      data.append("title", el.title);
      data.append("body", el.body);
      data.append("seminarId", el.seminarId.toString());
      data.append("blockId", el.blockId.toString());
      data.append("videoURI", el.videoURI);
      return { type: "watch_video", data };
    case "video_presentation":
      return {
        type: "video_presentation",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
          rateableSubmissions: el.rateableSubmissions || false,
          requiredSubmissions: Number(el.requiredSubmissions || 1),
          maxNumShared: Number(el.maxNumShared || 1),
          maxSubmissions: Number(el.maxSubmissions || 1),
          requiredNumShared: Number(el.requiredNumShared || 1),
          isTimed: el.isTimed || false,
          expectedLen: el.isTimed && el.expectedLen ? el.expectedLen : 0,
          maxLen: el.isTimed && el.maxLen ? el.maxLen : 0,
        },
      };
    case "watch_feedback":
      return {
        type: "watch_feedback",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
        },
      };
    case "give_feedback":
      return {
        type: "give_feedback",
        data: {
          body: el.body,
          title: el.title,
          blockId: el.blockId,
          seminarId: el.seminarId,
          forElement: el.forElement,
          rateableSubmissions: el.rateableSubmissions || false,
        },
      };
    case "rephrase":
      return {
        type: "rephrase",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
          answers: el.answers,
          sentences: el.sentences,
        },
      };
    case "categorize":
      return {
        type: "categorize",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
          answers: el.answers,
          words: el.words,
          categories: el.categories,
        },
      };
    case "gap_text":
      return {
        type: "gap_text",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
          answers: el.answers,
          text: el.text,
        },
      };
    case "vr_training":
      return {
        type: "vr_training",
        data: {
          blockId: el.blockId,
          body: el.body,
          seminarId: el.seminarId,
          title: el.title,
          requiredScore: el.requiredScore,
        },
      };
    default:
      return undefined;
  }
};

export const elementTypes = [
  "read_text",
  "watch_video",
  "video_presentation",
  "give_feedback",
  "watch_feedback",
  "gap_text",
  "rephrase",
  "categorize",
  "vr_training",
];

export const videoTypes = ["video_presentation", "give_feedback"];

export const allTypes = (
  t?: (
    key: string,
    values?: VueI18n.Values | undefined,
  ) => VueI18n.TranslateResult,
) => {
  return elementTypes.map(x => (t && t(`seminars.${x}.title`).toString()) || x);
};

export const typeName = (
  type: ElementType,
  t?: (
    key: string,
    values?: VueI18n.Values | undefined,
  ) => VueI18n.TranslateResult,
) => {
  switch (type) {
    case "read_text":
      return (t && t(`seminars.${type}.title`)) || "Read text";
    case "categorize":
      return (t && t(`seminars.${type}.title`)) || "Put words into categories";
    case "gap_text":
      return (t && t(`seminars.${type}.title`)) || "Gap text";
    case "give_feedback":
      return (t && t(`seminars.${type}.title`)) || "Record feedback";
    case "rephrase":
      return (t && t(`seminars.${type}.title`)) || "Rephrase sentences";
    case "video_presentation":
      return (t && t(`seminars.${type}.title`)) || "Record presentation";
    case "vr_training":
      return (t && t(`seminars.${type}.title`)) || "VR Training";
    case "watch_feedback":
      return (t && t(`seminars.${type}.title`)) || "Watch feedback";
    case "watch_video":
      return (t && t(`seminars.${type}.title`)) || "Watch video";
    default:
      return "";
  }
};

export const typeToEndpoint = (t: ElementType) => {
  switch (t) {
    case "read_text":
      return "/api/Elements/ReadText";
    case "categorize":
      return "/api/Elements/Categorize";
    case "gap_text":
      return "/api/Elements/GapText";
    case "give_feedback":
      return "/api/Elements/GiveFeedback";
    case "rephrase":
      return "/api/Elements/Rephrase";
    case "video_presentation":
      return "/api/Elements/VideoPresentation";
    case "vr_training":
      return "/api/Elements/VRTraining";
    case "watch_feedback":
      return "/api/Elements/WatchFeedback";
    case "watch_video":
      return "/api/Elements/WatchVideo";
    default:
      return "";
  }
};

const base = {
  id: 0,
  blockId: 0,
  seminarId: 0,
  title: "",
  body: "",
  completed: false,
};
export const emptyElement = (t: ElementType): SeminarElement => {
  switch (t) {
    case "read_text":
      return {
        ...base,
        type: "read_text",
      };
    case "categorize":
      return {
        ...base,
        type: "categorize",
        words: [],
        answers: [["Answer 1"]],
        categories: ["Category #1"],
      };
    case "gap_text":
      return {
        ...base,
        type: "gap_text",
        text: "__ text",
        answers: ["Gap"],
      };
    case "give_feedback":
      return {
        ...base,
        type: "give_feedback",
        forElement: 0,
      };
    case "rephrase":
      return {
        ...base,
        type: "rephrase",
        sentences: ["Hey"],
        answers: ["Hello"],
      };
    case "video_presentation":
      return {
        ...base,
        type: "video_presentation",
        maxNumShared: 1,
        maxSubmissions: 1,
        requiredNumShared: 1,
        requiredSubmissions: 1,
        rateableSubmissions: true,
        isTimed: false,
        expectedLen: 10,
        maxLen: 20,
      };
    case "vr_training":
      return {
        ...base,
        type: "vr_training",
        requiredScore: 50,
      };
    case "watch_feedback":
      return {
        ...base,
        type: "watch_feedback",
      };
    case "watch_video":
      return {
        ...base,
        type: "watch_video",
        videoURI: "",
      };
    default:
      return {} as SeminarElement;
  }
};
