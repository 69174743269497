import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

import store from "../store";
import {
  authGuard,
  adminGuard,
  trainingGuard,
  seminarGuard,
  // ownerGuard,
} from "./utils";

Vue.use(VueRouter);
const routes: RouteConfig[] = [
  {
    name: "Home",
    path: "/",
    component: () => import("@/views/home"),
  },
  // Auth
  {
    name: "",
    path: "/auth",
    component: () => import("@/views/auth"),
    children: [
      {
        name: "Login",
        path: "login",
        component: () => import("@/views/auth/pages/Login.vue"),
      },
      {
        name: "Register",
        path: "register",
        component: () => import("@/views/auth/pages/Register.vue"),
      },
      {
        name: "Forgot Password",
        path: "forgotPassword",
        component: () => import("@/views/auth/pages/ForgotPassword.vue"),
      },
      {
        name: "Reset Password",
        path: "password/reset",
        component: () => import("@/views/auth/pages/ResetPassword.vue"),
      },
      {
        name: "Confirm Email",
        path: "email/confirm",
        component: () => import("@/views/auth/pages/ConfirmEmail.vue"),
      },
      {
        name: "Inactive Profile",
        path: "inactive",
        component: () => import("@/views/auth/pages/InactiveProfile.vue"),
      },
      {
        name: "New User",
        path: "new-user",
        component: () => import("@/views/auth/pages/NewUser.vue"),
      },
    ],
  },

  // Dash
  {
    name: "Dashboard",
    path: "/dashboard",
    component: () => import("@/views/dashboard"),
    beforeEnter: trainingGuard,
  },

  // Admin
  {
    name: "Admin",
    path: "/admin",
    component: () => import("@/views/admin"),
    beforeEnter: adminGuard,
    children: [
      {
        name: "Website Users",
        path: "users",
        component: () => import("@/views/admin/pages/Users.vue"),
      },
      {
        name: "How-To Videos",
        path: "videos",
        component: () => import("@/views/admin/pages/HowToVideos.vue"),
      },
      {
        name: "Slides",
        path: "slides",
        component: () => import("@/views/admin/pages/Slides.vue"),
      },
    ],
  },

  // Sessions
  {
    name: "Sessions (List)",
    path: "/sessions/list",
    component: () => import("@/views/sessions/Sessions.vue"),
    beforeEnter: trainingGuard,
  },
  {
    name: "Sessions (Progress)",
    path: "/sessions/progress",
    component: () => import("@/views/sessions/Sessions.vue"),
    beforeEnter: trainingGuard,
  },
  {
    name: "Shared Sessions",
    path: "/sessions/shared/:email?",
    component: () => import("@/views/sessions/ManagedSessions.vue"),
    beforeEnter: trainingGuard,
  },

  // Groups
  {
    name: "Groups",
    path: "/groups",
    component: () => import("@/views/groups/Groups.vue"),
    beforeEnter: trainingGuard,
  },
  {
    name: "Group Details",
    path: "/groups/:id",
    component: () => import("@/views/groups/GroupDetails.vue"),
    beforeEnter: trainingGuard,
  },

  // News
  {
    name: "News",
    path: "/news",
    component: () => import("@/views/news/News.vue"),
    beforeEnter: trainingGuard,
  },
  {
    name: "Article",
    path: "/news/article/:id",
    component: () => import("@/views/news/ArticleDetails.vue"),
    beforeEnter: trainingGuard,
  },

  // Goals
  {
    name: "Goals",
    path: "/goals",
    component: () => import("@/views/goals"),
    beforeEnter: trainingGuard,
  },

  // Meetings
  {
    name: "Meetings",
    path: "/meetings",
    component: () => import("@/views/meetings"),
    beforeEnter: authGuard,
  },

  // Seminars
  {
    path: "/seminars",
    component: () => import("@/views/seminars"),
    children: [
      {
        path: "",
        name: "Seminars - Home",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Home.vue"),
      },
      {
        path: "dashboard/:id?",
        name: "Seminars - Dashboard",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Dashboard.vue"),
      },
      {
        path: "details/:id?",
        name: "Seminars - Details",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Details.vue"),
      },
      {
        path: "videos/:id?",
        name: "Seminars - Video",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Videos.vue"),
      },
      {
        path: "edit/:id",
        name: "Seminars - Edit",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Edit.vue"),
      },
      {
        path: "admin",
        name: "Seminars - Admin Dashboard",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/Admin.vue"),
      },
      {
        path: "submission/:id",
        name: "Seminars - Submission Details",
        beforeEnter: seminarGuard,
        component: () => import("@/views/seminars/pages/SubmissionDetails.vue"),
      },
    ],
  },

  // Settings
  {
    name: "Settings",
    path: "/settings",
    component: () => import("@/views/settings"),
    beforeEnter: authGuard,
  },

  // Fallthrough
  {
    path: "**",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((_, _1, next) => {
  global.window.scrollTo(0, 0);
  next();
});

router.beforeEach((_, _1, next) => {
  store.commit("appLoading");
  next();
});

router.afterEach(() => store.commit("appLoaded"));

export default router;
