import LocalStorage from "@/core/utils/LocalStorage";

export interface Device {
  id: string;
  name: string;
}

export const getInputDevice = () => {
  return LocalStorage.getInputDevice();
};

export const getDevices = async () => {
  if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
    console.log("enumerateDevices() not supported.");
    return [];
  }

  const inputDevice = getInputDevice();
  const constraints: MediaStreamConstraints = {
    video: false,
    audio: {
      channelCount: 1,
      echoCancellation: false,
      deviceId: inputDevice ? inputDevice : undefined,
    },
  };
  const stream = await navigator.mediaDevices.getUserMedia(constraints);
  stream.getTracks().forEach(t => t.stop());
  const devices = await navigator.mediaDevices.enumerateDevices();

  const inputDevices = devices
    .filter(x => x.kind === "audioinput")
    .map(x => ({ id: x.deviceId, name: x.label } as Device));
  if (!inputDevice) selectDevice(inputDevices[0].id);
  return inputDevices;
};

export const selectDevice = (id: string) => {
  LocalStorage.setInputDevice(id);
};
