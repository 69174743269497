




































































































































import Vue from "vue";
import { Getter, Action } from "vuex-class";
import { Component, Watch } from "vue-property-decorator";

import { InformationTooltip } from "./";
import { SessionSlide } from "@/core/models/sessions";
import { formatFromMilliSeconds } from "@/core/utils/formatTime";

@Component({ components: { InformationTooltip } })
export default class SessionSlides extends Vue {
  @Getter("currentSession/currentSlide") currentSlide!: SessionSlide;
  @Getter("currentSession/slides") slidesBase!: SessionSlide[];
  @Getter("audio/time") audioTime!: number;
  @Getter("audio/total") totalTime!: number;
  @Getter("audio/progress") progress!: number;
  @Action("audio/setProgress") setProgress!: Function;
  @Action("audio/updateTime") updateTime!: (t: number) => void;

  get slides() {
    return this.slidesBase.map(x => ({
      ...x,
      offsetFormatted: formatFromMilliSeconds(x.offset),
    }));
  }

  sliderValue = 0;
  fullscreen = false;
  @Watch("audioTime", { immediate: true })
  timeupdate() {
    this.sliderValue = this.audioTime;
  }
  sliderUpdate(value: number) {
    this.sliderValue = value;
    const progress = value / this.totalTime;
    this.setProgress(progress);
  }
  imageOffset(slide: SessionSlide) {
    const offset = slide.offset / (this.totalTime * 1000);
    const res = offset * 100 + 0.7;
    return res > 100 ? 100 : res;
  }

  setAudio(slide: SessionSlide) {
    this.updateTime(slide.offset / 1000);
  }

  get currentStart() {
    const start = this.currentSlide.offset || 0;
    return formatFromMilliSeconds(start);
  }
  get currentEnd() {
    const end =
      (this.currentSlide.offset || 0) + (this.currentSlide.duration || 0);
    return formatFromMilliSeconds(end);
  }
  get currentWpm() {
    const wpm = this.currentSlide.wordsPerMinute || 0;
    return Math.round(wpm);
  }
  get currentPitch() {
    const pitch = this.currentSlide.averagePitch || 0;
    return Math.round(pitch * 100) / 100;
  }
  get currentVolume() {
    const volume = this.currentSlide?.averageVolume || 0;
    return Math.round(volume * 100 * 100) / 100;
  }
}
