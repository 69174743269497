import { ActionTree } from "vuex";
import { RootState } from "../root";
import { Interval } from "@/core/models";
import { CurrentSessionState } from "./index";
import { Session } from "@/core/models/sessions";

const actions: ActionTree<CurrentSessionState, RootState> = {
  setSession({ state }, session: Session | null) {
    if (session === null) state.session = null;
    else state.session = Object.assign({}, session);
  },
  setLimit({ state }, limit: number) {
    state.limit = limit;
  },
  setZoom({ state }, val: boolean) {
    state.zoom = val;
  },
  setZoomInterval({ state }, interval: Interval) {
    state.interval = interval;
  },
};

export default actions;
