import { Notification } from "@/core/models/Notification";
import LocalStorage from "@/core/utils/LocalStorage";
import { HubConnectionBuilder, LogLevel } from "@aspnet/signalr";
import _Vue from "vue";
import store from "@/core/store";

declare module "vue/types/vue" {
  interface Vue {
    $notificationHub: CombinedVueInstance<
      _Vue,
      object,
      { start: () => void; stop: () => void; isStarted: () => boolean },
      object,
      Record<never, any>
    >;
  }
}

export default {
  install: (Vue: typeof _Vue) => {
    let isStarted = false;
    const notificationHub = new Vue();
    Vue.prototype.$notificationHub = notificationHub;

    // Create connection
    const getToken = () => LocalStorage.getAccessToken() || "";
    const url = "/notification-hub";
    const connection = new HubConnectionBuilder()
      .withUrl(url, { accessTokenFactory: getToken })
      .configureLogging(LogLevel.Error)
      .build();

    // Handle update
    const handleUpdate = async (notification: Notification) => {
      console.log("Handle Update for ", notification);
      store.commit(
        "notifications/addNotification",
        new Notification(notification),
      );
    };

    // Open connection
    const start = async () => {
      try {
        isStarted = true;
        console.log("HUB: trying to connect to Hub " + url);
        await connection.start();
        console.log("HUB: Connection started for hub: " + url);
        connection.on("NotificationUpdate", async notification => {
          console.log("Notification Hub:", notification);
          console.log("HUB: Update received for notification ", notification);
          await handleUpdate(notification);
          // sessionHub.$emit("session-status-update", notification);
        });
      } catch (error) {
        if (getToken()) setTimeout(() => start(), 5000);
      }
    };

    if (getToken()) start();
    Vue.prototype.$notificationHub.start = () => start();
    Vue.prototype.$notificationHub.stop = async () => {
      await connection.stop();
      isStarted = false;
      console.log("HUB: Connection stopped");
    };
    Vue.prototype.$notificationHub.isStarted = () => isStarted;
  },
};
