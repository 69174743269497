import { MutationTree } from "vuex";

import { GoalsState } from ".";

const mutations: MutationTree<GoalsState> = {
  set(state, payload) {
    if (!payload) {
      state.createdGoals = [];
      state.assignedGoals = [];
      return;
    }
    state.retrieved = true;
    const { assignedGoals, createdGoals } = payload;
    state.assignedGoals = assignedGoals;
    state.createdGoals = createdGoals;
  },
};

export default mutations;
