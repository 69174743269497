const en = {
  dashboardPage: {
    yourGoalsCard: {
      yourGoals: "Your Goals",
      haveCompleted: "You have completed {number} session{plural}",
      error: "An error occurred.",
      loadingGoals: "Loading Goals...",
    },
    groupsCard: {
      groups: "Groups",
      viewAll: "View All",
      information: "Information",
      created: "Created:",
      users: "Users",
      videos: "Videos",
      posts: "Posts",
      notGroupMember: "You are not part of any groups yet.",
      groupsLoading: "Groups are loading...",
    },
    videosCard: {
      all: "All",
      videos: "Videos",
      group: "Group",
      loadingVids: "Loading videos...",
      noGroups: "You are not a part of any groups",
    },
    newsCard: {
      news: "News",
      readAll: "Read All",
      posted: "Posted",
      readMore: "Read Article",
      noNews: "No news has been posted yet.",
      newsLoading: "News is loading.",
    },
    sessionsCard: {
      sessions: "Progress Breakdown",
      pitchHdg: "Pitch",
      pitchSubHdgDeeper:
        "You spoke in a deeper voice this week when compared to last week.",
      ptichSubHdgHigher:
        "You spoke in a higher voice this week when compared to last week.",
      speechSpdHdg: "Speech Speed",
      speechSpdSubHdgFaster:
        "You spoke faster today in comparison to yesterday.",
      speechSpdSubHdgSlower:
        "You spoke slower today in comparison to yesterday.",
      hertz400: "400Hz",
      hertz600: "600Hz",
      thisWeek: "This Week",
      wpm: "words/minute",
      today: "Today",
      yesterday: "Yesterday",
    },
    presentationsCard: {
      presentations: "Presentations",
      mb: "MB",
      empty: "Empty",
    },
  },
  //isnt used
  topPanel: {
    settings: "Settings",
    mail: "Mail",
    refresh: "Refresh",
    logout: "Logout",
  },
  //isnt used
  leftPanel: {
    dashboard: "Dashboard",
    sessions: "Sessions",
    groups: "Groups",
    news: "News",
    goals: "Goals",
    meetings: "Meetings",
    adminPanel: "Admin Panel",
  },
  sessionsPage: {
    noSessions: "No sessions could be found",
    sessionsFor: "Sessions for ",
    yourSessionsSection: {
      yourSessions: "Sessions",
      sessionFilters: "Session Filters",
      filterByDate: "Filter by date",
      beforeDate: "Before date",
      afterDate: "After date",
      betweenTwoDates: "Between two dates",
      filterByDuration: "Filter by duration",
      timeSpan: "Time span (minutes)",
      longerThan: "Longer than (minutes)",
      shorterThan: "Shorter than (minutes)",
      minTime: "Minimum time (minutes)",
      maxTime: "Maximum time (minutes)",
      filterByName: "Filter by name",
      startsWith: "Starts with",
      endsWith: "Ends with",
      includes: "Includes",
      exactMatch: "Exact match",
      searchTerm: "Search term",
      caseInsensitive: "Case insensitive",
      filterByScore: "Filter by score",
      greaterThanScore: "Greater than",
      lessThanScore: "Less than",
      scoreRange: "Score range",
      applyButton: "Apply",
      clearButton: "Clear",
      cancelButton: "Cancel",
      fetching: "Fetching session...",
    },
    sessionsListSection: {
      sessionsList: "Sessions List",
      sessions: "Sessions",
      audioAnalHdg: "Audio Analysis",
      audioAnalNotComplete:
        "Audio Analysis hasn't been completed yet. You can wait for it to load or check if it is done by refreshing the page.",
      textAnalHdg: "Text Analysis",
      textAnalNotComplete:
        "Text Analysis not completed. You can refresh the page to check if it is ready or wait for the session to complete.",
      movementAnalHdg: "Movement Analysis",
      movementAnalNoData: "There is no movement data available.",
      show: "Show",
      points: "points",
      volumeWarning:
        "A too quiet or loud recording may influence the quality of the Analysis Results. To get precise Analysis Results, aim for a Voice Volume between 20% and 80%.",
      movementAnalCard: {
        movementAnal: "Movement Analysis",
        headMovementOverTime: "Head Movement over Time",
        headMoTInfo:
          "Head Movement over Time shows how much your movement has changed over the last few sessions.",
        headMovement: "Head Movement",
        avgHeadMovement: "Average head movement",
        infoHeadM:
          "Head Movement shows how much you move with your Head / Body. Try to move from one space to another with reason and purpose.",
        handMovementOverTime: "Hand Movement over Time",
        handMoTInfo:
          "Hand Movement over Time shows how much your movement has changed over the last few sessions.",
        handMovement: "Hand Movement",
        avgHandMovement: "Average hand movement",
        infoHandM:
          "Hand Movement shows how much you move your Hands / Gesture. Alot of movement may cause you to appear nervous and less movement may portray you as bored. Try to use your Gestures as a tool to build tension.",
        distance: "Distance",
        changeOverTime: "Change over time",
        timePercent: "Time in %",
      },
      speechSpeedCard: {
        cardTitle: "Speech Speed",
        wpmInfo:
          "This shows how fast you speak. / How many words you use in one minute on average.",
        wpmTimeUnit: "Words/Minute",
        wpmNotice:
          "For most presentations a value between 80 and 120 words per minute is suitable",
        infoTitle: "Speech Speed and Listening Speed",
        infoText:
          "An average speech speed between 80 and 120 words per minute (WPM) is considered pleasant and understandable. Listening we can understand 150 WPM just fine but we can read about at about 260 WPM! That means that listeners will prefer to read the text that's on the slide, rather than wait for the speaker to say the it out loud. Bear this in mind when creating your presentation slides. Check out the 'How-To' section for more info on how to create the perfect presentation.",
      },
      scoresCard: {
        infoTitle: "Session Scores",
        infoText: "These are the scores for this session",
        cardTitle: "Session Scores",
        overall: "Overall",
        other: "Other scores",
        wpm: "Speech Speed",
        pauses: "Pauses",
        repetition: "Repetition",
        filler: "Filler words",
        speaking: "Speech Clarity",
        none: "No scores for this session",
      },
    },
    uploadSessionSection: {
      uploadSession: "Upload Session",
      createSessionFromAudio: "Create session from audio file",
      sessionName: "Session Name *",
      audioFile: "Audio File *",
      chooseLang: "Choose language",
    },
    deleteSession: {
      deleteSessionButton: "Delete this session",
      youSureDelete: "Are you sure you would like to delete this session?",
      deletionInfo:
        "Deleting a session is irreversible. Are you sure you would like to continue with deletion of this session?",
      confirmSessDel: "Confirm",
      cancelSessDel: "Cancel",
    },
    sessionsProgressSection: {
      analysisStatus: {
        AnalyzingAudio: "Analyzing audio...",
        AnalyzingText: "Analyzing text...",
        AudioDone: "Audio analysis complete",
        Running: "Running...",
        NotStarted: "Not started",
        Succeeded: "Succeeded",
        null: "Running...",
        TextDone: "Text transcription completed",
      },
      sessionsProgress: "Sessions Progress",
      analyzingSession: "Analyzing...",
      gettingSession: "Getting session...",
      recentData: "Recent Data",
      display: "Display",
      sessions: "sessions",
      audioAnal: "Audio Analysis",
      textAnal: "Text Analysis",
      movementAnal: "Movement Analysis",
      pitchOverTimeCard: {
        pitchOverTime: "Pitch over Time",
        changeOverTime: "Change over time",
        voicePitch: "Voice Pitch",
        infoPoT:
          "With Pitch over Time you can see the progress of your voice. This is useful if you want to train to use a deeper or higher voice.",
      },
      volumeOverTimeCard: {
        volumeOverTime: "Volume over Time",
        voiceVolume: "Voice Volume",
        changeOverTime: "Change over Time",
        infoVoT:
          "Volume over Time shows the progress of the volume of your voice.",
      },
      pausesOverTimeCard: {
        pausesOverTime: "Pauses-per-minute over Time",
        changeOverTime: "Change over time",
        totalPauseTime: "Total Pause Time",
        infoPoT:
          "Pauses over Time shows how many pauses you made within your last few sessions.",
      },
      textAnalCard: {
        sentimentOverTime: "Sentiment over Time",
        changeOverTime: "Change over time",
        sentiment: "Sentiment",
        infoSoT:
          "Sentiment over Time shows your progress in terms of a positive choice of words.",
      },
      speechSpeedOverTimeCard: {
        speechSpeedOverTime: "Speech Speed over Time",
        changeOverTime: "Change over time",
        wordsPerMinute: "Words/Minute",
        infoSSoT:
          "This shows the speed of your latest sessions relative to previous ones.",
      },
      movementAnalCard: {
        movementAnal: "Movement Analysis",
        headMovementOverTime: "Head Movement over Time",
        headMoTInfo:
          "Head Movement over Time shows how much your movement has changed over the last few sessions.",
        headMovement: "Head Movement",
        infoHeadM:
          "Head Movement shows how much you move with your Head / Body. Try to move from one space to another with reason and purpose.",
        handMovementOverTime: "Hand Movement over Time",
        handMoTInfo:
          "Hand Movement over Time shows how much your movement has changed over the last few sessions.",
        handMovement: "Hand Movement",
        infoHandM:
          "Hand Movement shows how much you move your Hands / Gesture. Alot of movement may cause you to appear nervous and less movement may portray you as bored. Try to use your Gestures as a tool to build tension.",
        distance: "Distance",
        changeOverTime: "Change over time",
        timePercent: "Time in %",
      },
    },
    sessionFiltersPopUp: {
      sessionFilters: "Session filters",
      filterByDate: "Filter by date",
      filterByDuration: "Filter by duration",
      minTimeMin: "Minimum time (minutes)",
      maxTimeMin: "Maximum time (minutes)",
      filterByName: "Filter by name",
      searchTerm: "Search term",
      caseInsensitive: "Case insensitive",
      filterByScore: "Filter by score",
      apply: "Apply",
      clear: "Clear",
      cancel: "Cancel",
      ok: "OK",
      beforeDate: "Before date",
      after: "After date",
      between: "Between two dates",
      startDate: "Start date",
      endDate: "End date",
    },
  },
  groupsSections: {
    yourGroupsPage: {
      yourGroups: "Your groups",
      noGroups: "You are not part of any groups.",
      addNewGroup: "Add A New Group",
      addGroupButton: "Add Group",
      groupName: "Group Name",
      groupUsers: "Group Users",
      emailRules:
        "Separate e-mails with comma(,) or by pressing space, tab or enter",
      submitButton: "Submit",
    },
    //isnt used
    groupPage: {
      latestVideosCard: {
        latestVideos: "Latest videos",
        editVideo: "Edit video",
        deleteVideo: "Delete video",
        addVidButton: "Add Video",
        addNewVideo: "Add new video",
        vidTitle: "Video Title (optional)",
        info: "If the title is empty, it will be set automatically",
        vidDescription: "Video Description (optional)",
        pinVideo: "Pin this video?",
        pinfo: "The pinned video will appear first",
        linkButton: "Link",
        vidLink: "Video Link",
        submit: "Submit",
        fileButton: "File",
      },
      latestPostsCard: {
        latestPosts: "Latest posts",
        editPost: "Edit post",
        deletePost: "Delete post",
        addPostButton: "Add Post",
        postTitle: "Post Title (required)",
        postBody: "Post Body (required)",
        pinPost: "Pin this post?",
        uploadBannerImage: "Upload banner image?",
        bannerImage: "Banner Image",
        otherFiles: "Other files",
        postButton: "Post",
      },
      usersCard: {
        users: "Users",
        remove: "Remove User",
        makeManager: "Make Manager",
        removeManager: "Remove Manager",
      },
      addUserCard: {
        addANewUser: "Add A New User",
        addUserButton: "Add User",
        userEmail: "User E-mail",
        makeManager: "Make Manager",
        submitButton: "Submit",
      },
    },
  },
  latestNewsSections: {
    latestNewsPage: {
      latestNews: "Latest News",
      noNews: "No news posted yet.",
      newsLoading: "News is loading",
      read: "Read",
      back: "Back",
      edit: "Edit",
      delete: "Delete",
      err404: "404",
      artWithId: "Article with ID",
      notFound: "could not be found.",
      goBack: "Go Back",
      addArticleButton: "Add Article",
      addArticlePopUp: {
        addNewArticle: "Adds a News Article",
        articleTitle: "Article Title",
        cannotBeEmpty: "Field cannot be empty",
        articleBody: "Article Body",
        addBannerImg: "Add Banner Image",
        bannerImg: "Banner Image",
        otherFiles: "Other Files",
        addArticle: "Add Article",
      },
    },
    articleActions: {
      edit: "Edit",
      editArticle: "Edit Article",
      newArticleTitle: "New Article Title",
      newArticleBody: "New Article Body",
      done: "Done",
      deleteArticle: "Delete article",
      areYouSure: "Are you sure you would like to delete the current article?",
      confirm: "Confirm",
      cancel: "Cancel",
    },
    addArticleButton: {
      addArticle: "Add Article",
      addNewsArticle: "Add a News Article",
      articleTitle: "Article Title",
      articleBody: "Article Body",
      addBannerImage: "Add Banner Image",
      bannerImage: "Banner Image",
      otherFiles: "Other files",
    },
  },
  goalsSections: {
    yourGoals: "Your Goals",
    assignedGoalsCard: {
      assignedGoals: "Assigned Goals",
      completeNumSessions: "Complete #### sessions",
      dueDate: "Due in #### years",
      completedSessions: "You have completed #### sessions",
      noGoalAssignments: "You have not been assigned any goals.",
    },
    createdGoalsCard: {
      createdGoals: "Created Goals",
      completeNumSessions: "Complete #### sessions",
      goalCreatedAtMonth: "a month ago",
      goalCreatedAtMinutes: "x minutes ago",
      sessionsThatCount: "Only sessions with a score greater than ## count",
      sentimentAim: "Aim for a sentiment score between xx% and yy%",
      pausesAim: "Aim to make anywhere between x and y pauses",
      pitchAim: "Aim for an average voice pitch between ##Hz and ##Hz",
      volumeAim: "Aim for an average volume between ##dB and ##dB",
      noCreatedGoals: "You have not created any goals.",
    },
    addGoalButton: "Add Goal",
    addGoalPopUp: {
      addNewGoal: "Add new goal",
      addCondition: "Add Condition",
      conditionNum: "Condition #x",
      conditionType: "Condition Type (required)",
      dueDate: "Due Date",
      sessionScore: "Session Score",
      targetScore: "Target Score",
      sentiment: "Sentiment",
      targetSentiment: "Target Sentiment",
      avgPitch: "Average Pitch",
      targetPitch: "Target Pitch",
      avgVolume: "Average Volume",
      targetVolume: "Target Volume",
      pausesPerMin: "Pauses per Minute",
      targetPauses: "Target Pauses",
      assignToUsers: "Assign to users",
      selectAll: "Select All",
      deselectAll: "Deselect All",
      assignSelf: "Assign yourself?",
      submit: "Submit Goal",
    },
  },
  meetingsSection: {
    yourMeetings: "Your Meetings",
    allMeetingsCard: {
      allMeetings: "All Meetings",
      subject: "Subject",
      noSubject: "No Subject Set",
      noMeetings: "You have not created any meetings.",
      joinMeeting: "Join Meeting",
      editMeeting: "Edit Meeting",
      cancelMeeting: "Cancel Meeting",
      dateFuture: "in xx days",
      datePast: "xx days ago",
      done: "Done",
    },
    addMeetingButton: "Add Meeting",
    addNewMeetingPopUp: {
      addNewMeeting: "Add a New Meeting",
      meetingSub: "Meeting Subject",
      chooseStart: "Choose start date/time",
      chooseEnd: "Choose end date/time",
      assignUsers: "Assign to users",
      selectAll: "Select All",
      deselectAll: "Deselect All",
      additionalUsers: "Additional Users",
      emailRule:
        "Separate e-mails with a comma(,) or by pressing space, tab or enter",
      setPass: "Set password?",
      pass: "Password",
      meetingTypes: "Meeting Types",
      blizzMeeting: "Blizz Meeting",
      customMeeting: "Custom Meeting",
      customMeetingLink: "Custom meeting link",
      addMeeting: "Add Meeting",
      dateError: "Meeting end date has to be after its start date",
    },
  },
  adminPanelSection: {
    allUsers: "All Users",
    changeRole: "Change Role",
    changeRolePopUp: {
      changeRoleUser: "Change role for user",
      submit: "Submit",
      cancel: "Cancel",
      admin: "Admin",
      user: "User",
      owner: "Owner",
    },
  },
  passwordResetSection: {
    resetPass: "Reset Password",
    error: "An error occurred",
    newPassRequired: "New Password (required)",
    confirmPass: "Confirm New Password (required)",
    doneRedirect: "Done! Redirecting you to Login in 5-seconds.",
    couldNotResetPass: "Could not reset your password",
    resetMyPass: "Reset my password",
    backToForgotPass: 'Back to "Forgot Password"',
    passRequired: "Password is required",
    passMustBe: "Password must be at least 6-characters in length.",
    passCannotBe: "Password cannot be more than 16-characters in length.",
    confirmPassRequired: "Password confirmation is required.",
    passNoMatch: "Passwords do not match.",
  },
  registerSection: {
    error: "An error occurred",
    fNameRequired: "First Name (required)",
    lNameRequired: "Last Name (required)",
    firstName: "First Name",
    lastName: "Last Name",
    companyName: "Company Name",
    emailRequired: "E-mail (required)",
    passwordRequired: "Password (required)",
    confirmPassRequired: "Confirm Password (required)",
    emailPlaceHolder: "demo@prezp.com",
    agree: "I agree with the",
    termsNCond: "Terms and Conditions",
    back: "Back",
    register: "Register",
    fName: "First name is required",
    fNameMin: "First name has to be at least 2-letters long",
    lName: "Last name is required",
    lNameMin: "Last name has to be at least 2-letters long",
    email: "E-mail is required",
    invalidEmail: "Invalid e-mail",
    password: "Password is required",
    passMin: "Password must be at least 6-characters long",
    passMax: "Password cannot be more than 16-characters long",
    passRules:
      "Password must include at least one lowercase letter, at least one uppercase letter and at least one digit",
    confirmPass: "Password confirmation is required",
    passMismatch: "Passwords do not match",
    tOS: "Please read and agree to the Terms of Service",
    termsAndServices:
      "https://prezpstore.blob.core.windows.net/site-files/Nutzungsbedingungen_Prezp_Deutsch_03.03.2021.pdf?sp=rl&st=2021-03-15T08:01:58Z&se=2098-01-16T08:01:00Z&sv=2020-02-10&sr=b&sig=jlq6cAdFw%2FuPNsqdmHorPnwhcVd8qzso7yMHwhVo9gM%3D",
  },
  onboardingSection: {
    titleFirstPart: "YOUR COMMUNICATION TRAINING",
    titleSecondPart: "IN COMMUNICATION",
    bannerContent:
      "Would you like to communicate better,talk more grippingly and sell more successfully? Start now!",
    learnMoreBtn: "Learn more",
    footerLinkPrivacy: "Privacy",
    footerLinkLegal: "Legal",
    footerLinkContact: "Contact",
  },
  loginSection: {
    dontHaveAccount: "Don't have an account yet?",
    register: "Register",
    signIn: "Sign In",
    title: "Sign in to PREZP",
    error: "An error occurred",
    unconfirmedEmail: "You have not confirmed your e-mail address.",
    wrongPasswordError: "The entered password is wrong!",
    unauthorizedError: "Unauthorized. This account might be deactivated.",
    email: "E-mail",
    password: "Password",
    forgotPass: "Forgot password",
    emailPlaceHolder: "demo@prezp.com",
    invalidEmail: "Invalid e-mail",
    emailRequired: "E-mail is required",
    passRequired: "Password is required",
    passMin: "Password must be at least 6-characters long",
    passMax: "Password cannot be more than 16-characters long",
  },
  newUser: {
    thanks:
      "Thank you for registering an account on PREZP. Your account will be activated soon.",
    welcome: "Welcome to PREZP",
  },
  inactiveProfile: {
    notActivated:
      "This profile has not been activated. For more information please contact the administrators of this website.",
  },
  forgotPassSection: {
    signUp: "Don’t have an account yet?",
    register: "Register",
    wantToSignIn: "Want to sign in?",
    login: "Login",
    resetPass: "Reset Password",
    haveAnAccount: "Have an account?",
    signIn: "Sign in",
    forgotPass: "Forgot your password?",
    error: "An error occurred",
    email: "E-mail",
    sentEmail: "An e-mail was sent to",
    followLinkResetPass:
      "Follow the link in the e-mail to reset your password.",
    emailSent: "E-mail sent!",
    sendReset: "Send Reset E-mail",
    invalidEmail: "Invalid e-mail",
    emailRequired: "An e-mail address is required",
    passRequired: "Password is required",
    passMinRule: "Password must be at least 6-characters long",
    passMaxRule: "Password cannot be more than 16-characters long",
    emailPlaceHolder: "demo@prezp.com",
  },
  confirmEmailSection: {
    emailConfirmed: "E-mail Successfully Confirmed!",
    contWeb: "Continue to website",
    wait: "Give us a sec...",
    error: "An error occurred",
    couldNotConfirm: "E-mail could not be confirmed...",
    backToLogin: "Back to Login",
  },
  audioSection: {
    startAnal: "Start Analysis",
  },
  scoreDetailsSection: {
    sessionScore: "Session Score",
  },
  sentimentSection: {
    breakdown: "Breakdown by sentence",
    positive: "Positive",
    negative: "Negative",
    neutral: "Neutral",
    noAnalyzedSentence: "No sentences were analyzed.",
    sentimentRatio: "Sentiment",
  },
  pausesCard: {
    pauses: "Pauses",
    spentSubVerb: "You spent",
    spentPrep: "of time in pause.",
    number: "Pauses Made",
    average: "Average Pause Time",
    total: "Total Pause Time",
    timeUnit: "seconds",
    warning:
      "The duration of a pause is at least 2 seconds. Low volume can be interpreted as a pause.",
    ppm: "Pauses / Minute",
    ppmCaps: "PAUSES / MINUTE",
  },
  movementRatioCard: {
    movementRatio: "Movement Ratio",
    headMovement: "Head Movement",
    handMovement: "Hand Movement",
    noMoveData: "No movement data found.",
  },
  managedUsersCard: {
    usersManaged: "Users you manage",
    noManagedUsers: "You do not manage any users",
  },
  keywordsCard: {
    keywords: "Keywords",
    basedOn: "Based on AI",
  },
  audioCard: {
    yourAudio: "Your audio",
    audioFileInfo:
      "This is the audio file you recorded for this session. Listen back to it and as you scrub through you will see the charts update",
  },
  volumeCard: {
    voiceVolume: "Voice Volume",
    avgVoiceVol: "Average Volume",
  },
  pitchCard: {
    voicePitch: "Voice Pitch",
    avgVoicePitch: "Average Pitch",
  },
  sentimentRatioCard: {
    sentimentRatio: "Sentiment",
  },

  appBar: {
    notConfirmedEmail: "You have not confirmed your e-mail. Please do so.",
    sending: "Sending...",
    sendEmail: "Send E-mail",
    emailSent: "E-mail Sent!",
  },
  toolbarMenu: {
    settings: "Settings",
    mail: "Mail",
    refresh: "Refresh",
    logout: "Logout",
  },
  profileDropdown: {
    loading: "Loading...",
    profile: "Profile",
    checkoutSettings: "Check out the settings",
    logout: "Logout",
  },
  groupName: {
    newName: "New Group Name",
    rename: "Rename",
    nameRule: "Name must be at least 3-letters long",
  },
  groupItem: {
    deleteGroup: "Delete group",
    areYouSure: "Are you sure you wish to delete ",
    deleteInfo: "This will delete all videos associated with it as well.",
    confirm: "Confirm",
    cancel: "Cancel",

    leave: "Leave group",
    groupLeft: "You left group {name}",
    confirmLeave: "Are you sure you wish to leave {name}?",
  },
  addGroupCard: {
    addNewGroup: "Add A New Group",
    groupName: "Group Name",
    users: "Group Users",
    submit: "Submit",
    addGroup: "Add Group",
  },

  // New stuff
  nav: {
    dash: "Dashboard",
    sessions: "Sessions",
    list: "Session list",
    progress: "Session progress",
    groups: "Groups",
    news: "News",
    goals: "Goals",
    meetings: "Meetings",
    company: "Company",
    howto: "Tutorials",
    presentation: "Presentation",
    admin: "Admin panel",
    sessionList: "Session List",
    sessionProgress: "Session Progress",
    seminars: "Seminars",
    seminarsDash: "Dashboard",
    seminarsProg: "Progress",
    seminarsAdmin: "Admin Panel",
  },

  global: {
    loading: "Loading",
    submit: "Submit",
    empty: "Empty",
    close: "Close",
    requiredf: "Field required",
    cancel: "Cancel",
    files: "Attached files",
    confirm: "Confirm",
    none: "None",
    back: "Back",
    done: "Done",
    save: "Save changes",
    refresh: "Refresh",
    reset: "Reset",
    clear: "Clear",
    nomatch: "No items that match your search",
    upload: "Upload",
    create: "Create",
    invalidURL: "Invalid URL",
    err: "An error occurred",
    retry: "Try again",
    backToLogin: "Back to login",
    imprint: "Imprint",
    dataprotection: "Data Protection",
    dataprotection_link:
      "https://prezpstore.blob.core.windows.net/site-files/Datenschutzerkl%C3%A4rung_Prezp_English_19.11.2020.pdf?sp=r&st=2020-11-19T16:06:32Z&se=2120-11-20T00:06:32Z&spr=https&sv=2019-12-12&sr=b&sig=%2Fes5f4M01ji2xY%2FpxtiXhYDgY9oTUOf2bhML3Vmd2S4%3D",
    feedback: "For Feedback and Questions: info@prezp.com",
    nodata: "No data available",
    remove: "Delete",
  },

  forms: {
    invalidform: "This form is invalid",
    invalidfield: "This field is invalid",
    reqfield: "This field is required",
    audiofile: "Please provide an audio file",
    namelen3: "Name must be at least 3 letters long.",
    file: "Please provide a file",
    invaliddata: "Invalid input data",
    errors: "Cannot continue with errors present",
    missing: "Some files were missing",
  },

  upsess: {
    button: "Upload audio",
    title: "Upload a new session from a file",
    subtitle: "Create new session",
    text:
      'Upload a session from an audio file. Uploaded sessions can be viewed in the "Sessions" page. Analysis starts right after you upload. Click refresh once in a while to check if your session\'s analysis is complete.',
    go: "Create now",
    hint:
      "We support the most common audio-file formats such as mp3, wav, ogg, m4a(*) and mpeg(*)",
    smallprint:
      "*in some rare cases the transcription for .m4a or .mpeg files cannot be created",

    select: "Select presentation",
    rec: "PREZP Recorder",
    rectip: "Record a new session directly",
    rectext: "Record a new session, with or without a presentation",
    rejected_file:
      "Nicht unterstütztes Dateiformat. Nur Audioformate erlaubt (mp3, wav, ogg, m4a, mpeg)",
  },

  groups: {
    groups: "Groups",
    all: "All groups",
    none: "You are not part of any group yet",
    hint: "Seperate e-mails with comma (,) or by pressing tab or enter",
    created: "Created at",
    delete: "Delete group",
    you: "(you)",

    creator: "Group creator",
    joined: "Joined",
    removeu: "Remove user",
    confrem: "Are you sure you wish to remove {u} from the group?",
    remself: "yourself",

    videos: "Latest videos",
    addv: "Add video",
    addnv: "Add new video",
    vidtitlehint: "If the title is empty, it will be set automatically",
    vidtitlelabel: "Video title",
    viddesc: "Video description",
    vidpinlabel: "Pin this video?",
    vidpinhint: "The pinned video will appear first",
    link: "Link",
    vidlinklabel: "Video link",
    file: "File",
    novideos: "No videos found",
    delv: "Delete video",
    editv: "Edit video",
    original_title: "Original title",
    author: "Author",
    uploaded_by: "Uploaded by",

    posts: "Latest posts",
    addp: "Add post",
    addnp: "Create new post",
    posttitle: "Post title*",
    postbody: "Post body*",
    postpin: "Pin this post?",
    bannerlabel: "Upload banner image?",
    bannerfile: "Banner image",
    files: "Other files",
    noposts: "No posts found",
    delpost: "Delete post",
    editpost: "Edit post",

    users: "Group members",
    addu: "Add member",
    addnu: "Add member to group",
    uemail: "User e-mail",
    makeman: "Make manager",
    remman: "Remove as manager",
    nousers: "No user e-mails that include {term}",

    inv: {
      title: "Your invitations",
      message: "{inviter} invited you to group {group}",
      accept: "Accept",
      decline: "Decline",
      accepted: "You were added to the group {group}",
      declined: "Invitation from {inviter} declined",
      sent: "Sent invitations",
      nonesent: "No sent invitations",
    },
  },

  inv: {
    title: "Your invitations",
    message: "{inviter} invited you to group {group}",
    accept: "Accept",
    decline: "Decline",
    accepted: "You were added to the group {group}",
    declined: "Invitation from {inviter} declined",
    sent: "Sent invitations",
    nonesent: "No sent invitations",
    asmanager: "Invited as manager",
    asemployee: "Invited as an employee",
  },

  videos: {
    videos: "Videos",
    none: "No videos in that group",
    edit: "Edit video",
    delete: "Delete video",
    desc: "Video description",
    title: "Video title",
    ispinned: "Is pinned?",
  },

  goals: {
    goals: "Your goals",
    none: "You have no goals",

    assigned: "Assigned goals",
    tassigned: "All goals that were assigned to you",
    noassigned: "No assigned goals",
    assigner: "From",

    created: "Created goals",
    tcreated: "All goals that were created by you",
    nocreated: "No created goals",

    add: "Add goal",
    complete: "Complete {n} sessions",
    aim: "Aim for",
    addc: "Add condition",
    assign: "Assign to user",
    assignedu: "Assigned users",
    assigny: "Assign yourself?",
    submit: "Submit goal",
    remove: "Remove goal",
    select: "Select all",
    deselect: "Deselect all",
    cond: "Condition",
    conds: "Goal conditions",
    ctype: "Condition type",

    score: "Target score",
    due: "Due date",
    sentiment: "Target sentiment",
    pitch: "Target pitch",
    volume: "Target volume",
    pauses: "Target pauses",
    wpm: "Target speech speed",

    condtype: {
      date: "Due Date",
      sent: "Sentiment",
      pitch: "Average Pitch",
      vol: "Average Volume",
      ppm: "Pauses per Minute",
      wpm: "Speech Speed",
    },

    condmsg: {
      overdue: "Overdue",
      duein: "Due",
      session_score: "Only sessions with score greater than {n} count",
      sentiment: "Aim for a sentiment score between {n1}% and {n2}%",
      pitch: "Aim for an average voice pitch between {n1}Hz and {n2}Hz",
      volume: "Aim for an average volume between {n1}% and {n2}%",
      pauses: "Aim to make anywhere between {n1} and {n2} pauses per minute",
      speech_speed: "Aim for {n1} to {n2} words per minute",
    },

    confirm: "Are you sure?",
    confirmmsg:
      "Are you sure you wish to delete this goal? The action is irreversible.",
  },

  news: {
    news: "News",
    read: "Read Article",
    all: "Read all",
    posted: "Posted on",
  },

  meet: {
    created: "Created by:",
    expired: "This meeting has expired",
    add: "Add meeting",
  },

  pres: {
    pres: "Presentations",
    none: "You have no presentations",
    dl: "Download",
    ed: "Edit",
    rm: "Remove",
    edit: "Edit Presentation",
    no_slides:
      "If you're not seeting images, then the presentation could not be converted",
    tip: "Upload your presentations (.pdf) here to use on your VR Headset",
  },

  rec: {
    tiptitle: "How to use the built-in recorder",
    tiptext:
      "You can test your microphone volume to determine if it is within the best range for audio analysis.",
    hide: "Don't show this tip again.",
    voll: "Too quiet",
    volgood: "Good",
    volh: "Too loud",
    latest: "Latest recordings",
    save: "Save recording as",
    sess: "New session",
    base: "Add as baseline",
    succ: "Session Created Successfully",
    fromVideo: "Session from Video created successfully",
    fail: "Could not create session",
    remove: "Remove recording",
    removetext: "Are you sure you wish to remove the following recording?",
    mic: "Adjust mute gain",
    mute: "Mute/unmute",
    devices: "Devices",
    prefix: "Recorded on",
  },

  meta: {
    dev: "Deviation",
    earlier: "Earlier sessions average",
    recent: "Recent sessions average",
    names: {
      pitch: "Pitch",
      volume: "Volume",
      pauses: "Pauses",
      sentiment: "Sentiment",
      session_score: "Session score",
      speech_speed: "Speech speed",
    },
    units: {
      pitch: "Hz",
      volume: "%",
      pauses: "pauses/minute",
    },
  },

  slides: {
    slides: "Slides",
    tip: "Slides from presentaion",
    pitch: "Average pitch",
    volume: "Average volume",
    wpm: "Words/Minute",
    start: "Start time",
    end: "End time",

    notfound: "No slide that matches the current time",
    none: "There are no slides for this session",
  },

  lookup: {
    title: "Look Up Words",
    enter: "Enter text to filter by",
    exact: "Exact match",
    show: "Show all",
    hide: "Show less",
    start: "start typing...",
    filter: "Filter by",
    missing: "Not enough data to show lookup words",
  },

  cards: {
    avg: "Average",
    pvolume: "Personal volume",
    ppitch: "Personal pitch",
    wpm: "Words/Minute",
    keywords: "Keywords (Based on AI)",
  },

  bench: {
    choose: "Choose benchmark",
    change: "Change benchmark",
    title: "Benchmark session",
    text:
      "Choose a benchmark session to be displayed on the same graphs. You can use to benchmark session to compare your performance to it.",
  },

  sentences: {
    filter: "Sentence filter",
    none: "No sentences found",
    pos: "Positive",
    neu: "Neutral",
    neg: "Negative",
    nochart: "No sentiment data found",
    filters: {
      none: "None",
      vpos: "Very positive",
      vneg: "Very negative",
    },
  },

  company: {
    title: "Company",
    partof: "You are part of a company",
    notpartof: "You are not part of a company",
    create: "Feel free to create a company",
    createbtn: "Create company",
    invitations:
      "Or, you may accept any of the following invitations you have received to join an existing company",
    noinv: "No invitations",

    companies: "Companies",
    getting: "Getting companies...",
    notfound: "No companies found",

    none: "You do not have a company",
    inactive: "This company has not been activated",
    pdesc: "Click here to select a plan for purchasing a subscription",

    plans: "Plans",
    changep: "Change plan",
    selectp: "Select new plan",
    same: "Select a different plan",

    emp: "Employees",
    esingle: "Employee",
    invite: "Invite user(s)",
    adde: "Add employee",
    eemail: "User e-mail",
    makeman: "Make manager?",
    rem: "Remove",
    sure: "Are you sure you wish to remove this employee?",
    crole: "Change role",
    crolefor: "Change role for",
    noemp: "No company employees yet",

    edit: "Edit company name",
    name: "Company name",
  },

  charts: {
    avghand: "Average hand movement",
    avghead: "Average head movement",
    ppm: "Pauses/minute",
    sent: "Sentiment",
    pitchot: "Pitch over time",
    scoreot: "Score over time",
    avgvp: "Average voice pitch",
    avgvol: "Average voice volume",
    avgwpm: "Average words/minute",
    sscore: "Session score",
    points: "Show {n} points",
    all: "all",
  },

  sess: {
    noneselected: "No session selected",
    shared: "Shared with {n} users",
    sharedWithOne: "Shared with {n} user",
    sharedWith: "Shared with ",
    sharedWithNone: "Shared with no one",
    noOneToShareWith:
      "You can share your sessions only with users in one of your groups.",
    private: "Private",
    duration: "Duration",
    none: "No sessions found",
    noneseelcted: "No session selected",
    edit: "Edit session",
    name: "Session name",
    vis: "Visibility",
    updated: "Session updated",
    filter: "Filter sessions",
    keywords: "Keywords",

    sharing: "See shared Sessions",
    usersSharing: "Users that are sharing sessions with you",
    usersManaged: "Users you're managing",
    otherUsers: "Other users from groups",
    changeuser: "Change user",
    nouser: "No user selected",
    sharedback: "Back to shared sessions",

    list: "List",
    progress: "Progress",
    progresslimit: "Choose number of sessions to show",

    filters: {
      date: {
        before: "Before date",
        after: "After date",
        between: "Between two dates",
      },
      duration: {
        longer: "Longer than (minutes)",
        shorter: "Shorter than (minutes)",
        between: "Time span (minutes)",
      },
      name: {
        startsWith: "Starts with",
        endsWith: "Ends with",
        includes: "Includes",
        exact: "Exact match",
      },
      score: {
        greater: "Greater than",
        less: "Less than",
        range: "Score range",
      },
      pres: {
        title: "Filter by presentation",
        label: "Select presentation",
      },
    },

    hanging: {
      title: "Other users",
      text:
        "You're still sharing this session with some users, with whom you are no longer in any group.",
      removeOneDialogTitle:
        "Are you sure you with to stop sharing this session with {u}",
      removeOneDialogText:
        "This action is irreversible. You'll need to be in at least one group with {u} to be able to share a session with them again later.",
      removeAllButton: "Remove all such users",
      removeAllDialogTitle: "Are you sure you want to remove all such users?",
      removeAllDialogText:
        "This action is irreversible. You'll need to be in at least one group with each of these users to be able to share a session with them again later",
      snack: {
        removeAll: "Success. Users removed",
        removeSome:
          "Not all users were removed. Users {e-mails} could not be removed",
        removeFail: "Could not stop sharing session with one or more users",
      },
    },

    clear: {
      title: "Remove all users",
      text: `You'll still have to confirm your action by clicking the "Confirm" button`,
    },
  },

  words: {
    word: "word",
    filler: "Filler word",
    stop: "Stop word",
    normal: "Default word",
  },

  admin: {
    page: "Admin page",
    users: "Site users",
    products: "Stripe products",
    coupons: "Stripe coupons",
    tuts: "Tutorials",
    companies: "Companies",
    slides: "Presentation slides",
    createNewProduct: "Create new product",
    createProduct: "Create product",
    activeProduct: "Active product",
    productName: "Product name",
    productDescription: "Product description",
    productPrice: "Product price (in Euro)",
    maximumAllowedEmployees: "Maximum allowed employees",
    verifyEmail: "Verify this user's e-mail",
    permissions: "Manage this user's permissions",
  },

  permissions: {
    session_training: "Session Training",
    seminar: "Seminar Permission",
    editTitle: "Manage permissions",
    editText: "Add, remove or extend permissions for",
    removeTitle: "Are you sure?",
    removeText:
      "You can choose to add the same permission type at a later date.",
    modifyTitle: "Change date",
    add: "Add permission",
    confirm: "Confirm changes",
  },

  roles: {
    employee: "Employee",
    manager: "Manager",
    owner: "Owner",
    admin: "Admin",
    user: "User",
    change: "Change role",
    changetitle: "Change role for user",
  },

  activeUser: {
    search: "Filter users",
    none: "No users found",
    indef: "Indefinite",
    mins: " minutes",
    hours: " hour(s)",
    days: " day(s)",
    months: " month(s)",
    both: "Active/Deactivated",
    active: "Active",
    inactive: "Deactivated",
    activate: "Activate",
    deactivate: "Deactivate",
    change: "Change active status for user ",
    until: "until",
    confirm: "Are you sure you want to deactivate this user?",
    activateMsg:
      "You are about to activate this user. Select how long this user will remain active",
  },

  def_pres: {
    title: "Default presentation slides",
    current: "Currently uploaded slides",
    currentmsg:
      "These are the slides every new user gets when they register for PREZP",
    none: "No default presentation has been set",
    upload: "Upload new presentation",
    uploadBtn: "Upload",
    remove: "Delete default presentation",
    removeBtn: "Remove presentation",
    confirm: "Are you sure you wish to remove the default slides?",
    no_slides: "This presentation could not be converted or is empty",
  },

  snack: {
    users: {
      rupdated: "User role updated",
      rem: "User removed",
      emails: "One or more e-mails were invalid",
      alreadyin: "One or more users are already in the group",
    },
    comp: {
      created: "Company successfully created",
      createfail: "Could not create company",
      invited: "User successfully invited",
      rupdated: "User role updated",
      upsuccess: "Upgrading successful",
      upfail: "Upgrading did not succeed",
      empmax: "Employees exceeds max number",
      rejected: "Following users couldn't be added: ",
      success: "Invitation successfully sent",
      conflict: "One or more users are already in a company",
    },
    groups: {
      unadded: "These users could not be added: ",
      del: "Group deleted successfully",
      added: "Group added successfully",

      ingroup: "User already in group",
      allin: "All users already in group",
      somein: "Some users are already in this group: ",
    },
    news: {
      added: "Article successfully deleted",
    },
    pres: {
      del: "Successfully deleted presentation",
    },
    sess: {
      delsuccess: "Successfully deleted session",
      delfail: "Could not delete session",
      metafail: "Could not update meta analysis settings",
      benchfail: "Could not fetch benchmark sessions",
      basefail: "Could not upload baseline sessions",
      fetchfail: "Could not fetch sessions for {e}",
    },
    vid: {
      editsuccess: "Successfully edited video",
      editfail: "Error editing video",
      delsuccess: "Successfully deleted video",
      delfail: "Error deleting video",
    },
    auth: {
      fail: "An error occured, redirecting to login",
      confirmfail: "Could not verify this e-mail",
      sent: "E-mail sent",
      sendfail: "Could not send e-mail",
      reset: "Successfully reset password",
      resetfail: "Could not reset password",
    },
    stripe: {
      elempty: "Element cannot be empty",
      elinvalid: "Invalid payment information",
      elincomplete: "Incomplete data",
      discount: "* This coupon applies for the first {d} months",
      purchase: "Success",
      pfail: "Could not purchase subscription",
      entercode: "Please enter a coupon code",
      codeinvalid: "Invalid coupon code",
    },
    meta: {
      attrs: "Please select at least one attribute",
    },
  },

  settings: {
    title: "User settings",
    dark: "Enable dark mode",
    alreadyup: "You've already uploaded one or more baseline sessions.",
    analyzed: "Sessions have been analyzed.",
    notanalyzed: "Sessions have not been analyzed yet.",
    upAt: "Uploaded at",
    avgVol: "Average volume",
    avgPitch: "Average pitch",
    actions: "Actions",
    items_per_page_text: "Rows per page",
  },

  baseline: {
    up: "Upload baseline session",
    base: "Baseline Sessions",
    base1: "Baseline Session (1)",
    base2: "Baseline Session (2)",
    base3: "Baseline Session (3)",
    text:
      "This is going to be the baseline session, used to compare your future sessions to. You will be asked to read out a certain paragraph a few times in different ways",
    lang: "Choose language",
  },

  move: {
    title: "Movement Analysis",
    none:
      "You have not yet completed any VR training, so PREZP has not analyzed any of your motion data",
    head: {
      title: "Head movement",
      avg: "Average head movement",
      tip: "Shows head movement in the VR during presentation",
    },
    hand: {
      title: "Hand movement",
      avg: "Average hand movement",
      tip: "Shows hand movement in the VR during presentation",
    },
    ratio: {
      title: "Movement ratio",
      tip: "This shows the ratio between head and hand movement",
    },
  },

  number: {
    toosmall: "Number is too small (min.: {n})",
    toobig: "Number is too big (max.: {n})",
  },

  seminars: {
    home: {
      title: "Seminars Home Page",
      noSeminars: "No Seminars found",
      adminLink: "Admin Page",
      progressLink: "Progress",
      yourSubmissions: "Your submissions",
      feedback: "Feedback for me",
      submissions: "Submissions",
      noSubmissions: "No submissions yet",
      seeAllButton: "See all",
    },
    admin: {
      title: "Seminar Admin Panel",
      addBtn: "Add Seminar",
      addTitle: "Add new seminar",
      addText:
        "A seminar can be constructed here. Course elements such as fill-in-the-blanks, video feedback exercises or other course components can be created and added easily and flexibly. Afterwards, publish the seminar and invite your participants with one click.",

      feedback: {
        title: "Give feedback to user",
        requested: "Feedback requested",
        desc:
          "The following video submissions have requested feedback from the seminar owner",
        yourFeedback: "Your feedback",
        alreadyGiven: "You have already given feedback to this video.",
      },
      participants: {
        title: "Seminar Teilnehmer",
        detail: "Aufzeichnungen von",
        numSubmissions: "Anzahl Aufzeichnungen",
        noParticipants: "Keine Teilnehmer",
        pos: "Position",
        progress: "Progress",
        name: "Name",
        fetchFail: "Could not fetch all users",
        userNotFound: "User not found",
        emptySub: "Nothing to see. Empty submission.",
        fitler: {
          title: "Filter users in this seminar",
          userCount: "{n} Users in total",
          progressLabel: "Target Current Progress",
          progressHint:
            "All users that have a progress percentage higher than or equal to this value will be included",
          blockLabel: "Target Current Block",
          blockHint: "All users that are past this block will be included",
          elLabel: "Target Current Element",
          elHint: "All users that are past this element will be included",
        },
      },
      invitations: {
        fetchFail: "Could not retrieve sent invitations",
        revokeFail: "Could not revoke invitation",
        invalidEmail: "Invalid email detected",
      },
    },

    edit: {
      actions: "Submissions",
      invite: "Invite users",
      inviteTitle: "Invite users to this seminar",
      inviteEmails: "E-mails that will be invited",
      editBtn: "Edit seminar",
      editTitle: "Edit seminar",
      blocks: {
        title: "Seminar blocks",
        addBtn: "Add block",
        editBtn: "Edit block",
        blockTitle: "Block title",
        blockDesc: "Block description",
      },
      elements: {
        title: "Elements in this block",
        addBtn: "Add element",
        editBtn: "Edit element",
        elTitle: "Element title",
        elDesc: "Element description",
        uploadVid: "Upload new video",
        embedVid: "Embed video",
        file: "Video file",
        link: "Link to video",
        desc_text: "Put stuff here...",

        video_presentation: "Users will record a video, nothing to specify",
        give_feedback:
          "Users will give feedback to an uploaded video, nothing to specify here",
        watch_feedback:
          "Users will watch a feedback video made for them, nothing to specify here",
        gap_text: {
          title: 'Specify blanks by putting down "__" (double underscore)',
          answers: "Fill in the blanks (sample solution)",
        },
        addRow: "Add row",
        addCategory: "Add category",
        newCategory: "New category",
        categoryTitle: "Category title",
        deleteElement: {
          title: "Delete element",
          detail: "Are you sure you wish to delete this element?",
        },
        rateable: "This element's submissions are shared and can be rated",
        embed_youtube:
          "If you are linking youtube videos make sure they are able to be embedded",
        required_numer_of_submissions:
          "Required number of submissions for this element",
        maximum_number_of_submissions:
          "Maximum number of submissions for this element",
        number_range_1_100: "Number range is 1-100",
        number_range_0_10: "Number range is 0-10",
        max_condition:
          "Max must be greater than or equal to the required number submissions",
        required_number_of_shared_submissions:
          "Required number of SHARED submissions for this element",
        shared_number_condition:
          "Required number of shared submissions must be less than or equal to the number of required submissions",
        max_number_of_shared_submissions:
          "Maximum number of SHARED submissions for this element",
        timed_video_recording: "Timed video recording",
        max_shared_rule_1:
          "Max. number of shared submissions must be greater than or equal to required number of shared submissions",
        max_shared_rule_2:
          "Max. number of shared submissions must be less than or equal to required number of shared submissions",
        max_shared_rule_3:
          "Max. num. of submissions must be greater than the number of required submissions and in the range 1-100",
        max_shared_rule_4:
          "Max. num. of shared submissions must be greater than or equal to required number of shared submissions and in the range 1-10",
        req_subs_rule_1:
          "Required number of submissions must be greater than the number of shared submissions and in the range 0-10",
        req_shared_rule_1:
          "Required num. of shared submissions must be less than or equal to the number of required submissions and in the range 0-10",
      },
      participants: {
        title: "Seminar Participants",
        detail: "Viewing submissions for",
        numSubmissions: "Total submissions",
        noParticipants: "No Participants",
      },
    },

    name: "Seminar name",
    description: "Seminar description",
    loading: "Loading seminar...",
    notFound: "Seminar not found",
    noBlocks: "This seminar has no blocks yet.",
    progress: "Seminar progress",
    tasks: "Tasks",
    skipTask: "Skip task",
    prev: "Previous",
    next: "Next",
    percentComplete: "{percent}% complete",
    submittedOn: "Submitted on",
    yourSubmission: "Your submission",
    yourAnswer: "Your answer",
    sampleSolution: "Sample solution",

    submissions: {
      videoRecorder: {
        askForFeedback: "Ask for feedback",
        askForFeedbackInfo:
          "If you ask for feedback, the seminar owner will see your video and can give feedback to it.",
        expectedTime: "Expected time:",
        maxAllowedTime: "max. recording time:",
        title: "Video recorder",
        actions: "Actions",
        start: "Start",
        stop: "Stop",
        upload: "Upload",
        reset: "Reset",
        pause: "Pause",
        resume: "Resume",
        flipVideo: "Flip Video",
        nameYourFile: "Name your file",
        save: "Save",
        uploadYourPres: "Upload your presentation",
        privateVid: "Private video",
        sharedVid: "Shared video",
        wouldLikeFeedback: "Yes, I would like to request feedback from owner",
        shared:
          "The recording will be shared to all other seminar participants. Are you sure you want to do this?",
        notShared:
          "The following video will be saved as a submission to the current element of this seminar",
      },
    },

    read_text: { title: "Read text" },
    watch_video: { title: "Watch video" },
    video_presentation: {
      title: "Record presentation",
      submissions: "max. submission(s)",
      reqSubmissions: "required submission(s)",
      sharedSubmissions: "max. shared submission(s)",
      reqSharedSubmissions: "required shared submission(s)",
      share: "Share submission",
      unshare: "Unshare submission",
      delete: "Delete Submission",
      delConfirm: "Are you sure you wish to delete this submission?",
      error:
        "You need {submissions} more submission(s) and {sharedSubmissions} more shared submission(s)!",
    },
    give_feedback: {
      title: "Give feedback",
      text: "Record a video as feedback to this video",
      noRating: "This video cannot be rated",
      nextTitle: "Record your feedbaack",
      yourSubmissions: "Your submission",
      noVideo: "No video found",
      noVideoDetail:
        "We could not find a video that hasn't been reviewed by another user yet.",
    },
    watch_feedback: {
      title: "Watch feedback",
      no_feedback: "No one has given feedback to your video, yet.",
      text:
        "Watch this video that another participant has given as feedback to your presentation",
    },
    gap_text: {
      title: "Fill in the blanks",
      error: "Please fill out all blanks before submitting",
    },
    rephrase: {
      title: "Reword",
      sentences: "Sentences",
      error: "Please fill out all fields",
    },
    categorize: {
      title: "Link Questions",
      phrases: "Phrases",
      noMore: "No more phrases",
      categories: "Categories",
      dnd: "Drag and drop here",
      error: "Please put all phrases in categories",
    },
    vr_training: {
      title: "VR Training",
      requiredScore: "Required score",
      yourSubmissions: "Your submissions",
      score: "Score",
      refresh: "Refresh submissions",
    },

    nameRequired: "Name required",
    descRequired: "Description required",

    ratings: {
      amusing: "Amusing",
      creative: "Creative",
      professional: "Professional",
    },

    videos: {
      title: "All videos",
      noSubmissions: "No submissions yet",

      sortBy: {
        title: "Sort by",
        date: "Date",
      },
      filter: {
        users: {
          title: "Users",
          detail: "Filter by user",
          placeholder: "Filter by name...",
        },
        elements: {
          title: "Elements",
          detail: "Filter by element",
        },
      },
    },
    vrScores: {
      title: "VR Scores",
      noVr: "No VR elements in this seminar",
    },
  },

  homePage: {
    welcomeMessage: "Welcome to PREZP",
    noPermissions:
      "If you are on this page that means you are in a company but have no permissions. When seminar/training permissions are granted, you can reload the window and start browsing",
  },

  invitations: {
    title: "Invitations",
    sent: "Sent invitations",
    revoke: "Revoke invitation",
    noInvitations: "You have no new invitation.",
  },

  zoomControl: "Zoom to slide",

  notifications: {
    feedback_received: "Received feedback",
    rate_video: "Upload new video for feedback",
    rating_received: "Received new feedback",
    months: "months",
    weeks: "weeks",
    days: "days",
    hours: "hours",
    minutes: "minutes",
    fewSeconds: "few seconds ago",
  },
};

export default en;
