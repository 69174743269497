






























import Vue from "vue";
import { Component } from "vue-property-decorator";
import {
  Device,
  getDevices,
  selectDevice,
  getInputDevice,
} from "../lib/devices";

@Component
export default class DeviceSelect extends Vue {
  devices: Device[] = [];
  selectedDevice = "";
  async get() {
    this.devices = await getDevices();
    this.selectedDevice = getInputDevice();
    if (!this.selectedDevice && this.devices.length)
      this.selectedDevice = this.devices[0].id;
  }
  async selectDevice(id: string) {
    this.selectedDevice = id;
    console.log(id, this.devices);
    selectDevice(id);
  }
  mounted() {
    this.get();
  }
}
