import { Module } from "vuex";

import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";
import { RootState } from "../root";
import { SessionsProgress, User } from "@/core/models";
import { MetaSettings, Session, MetaAnalysis } from "@/core/models/sessions";

export interface SessionsState {
  // sessions
  loading: boolean;
  retrieved: boolean;
  sessions: Session[];

  // progress
  progress: SessionsProgress;
  progressLoading: boolean;
  progressRetrieved: boolean;

  // Helpers
  removingSession: boolean;
  removedSessionId: number;
  gettingSingleSession: boolean;
  retrievedSessionId: number;
  uploadingSession: boolean;

  // Meta
  metaAnalysis: MetaAnalysis[];
  metaSettings: MetaSettings;
  metaLoading: boolean;

  // Benchmark Sessions
  benchmarkUser: User;
  benchmarks: Session[];
  benchmarksLoading: boolean;
  benchmark: Session | null;

  // Baseline session
  baselineLoading: boolean;
}

export const state: SessionsState = {
  sessions: [],
  loading: false,
  retrieved: false,

  progressLoading: false,
  progressRetrieved: false,
  progress: {} as SessionsProgress,

  // loaders
  uploadingSession: false,
  gettingSingleSession: false,
  retrievedSessionId: 0,
  removingSession: false,
  removedSessionId: 0,

  // meta
  metaAnalysis: [],
  metaSettings: {} as MetaSettings,
  metaLoading: false,

  // benchmarks
  benchmarkUser: {} as User,
  benchmarks: [],
  benchmarksLoading: false,
  benchmark: null,

  // baseline
  baselineLoading: false,
};

const namespaced = true;
export const sessions: Module<SessionsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
