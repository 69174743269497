import { MutationTree } from "vuex";

import { ProfileState } from ".";
import router from "@/core/router";
import { User } from "@/core/models";
import Storage from "@/core/utils/LocalStorage";
import BaselineAnalysis from "@/core/models/sessions/BaselineAnalysis";

const mutations: MutationTree<ProfileState> = {
  // Set
  set(state, user: User) {
    const { Role } = user;
    state.user = user;
    Storage.setUserRole(Role);
    state.loading = false;
    state.isAuthenticated = true;
  },
  setBaseline(state, baseline: BaselineAnalysis) {
    state.baseline = Object.assign({}, baseline);
  },

  userLoadError(state) {
    state.isAuthenticated = false;
    state.user = undefined;
    Storage.clearStorage();
  },

  // Forgot password
  forgotPasswordLoading(state, value: boolean) {
    state.forgotPasswordLoading = value;
  },
  forgotEmailSent(state) {
    state.forgotEmailSent = true;
    setTimeout(() => (state.forgotEmailSent = false), 10000);
  },
  forgotPasswordSuccess() {},
  forgotPasswordError() {},

  // Reset password
  resetPasswordLoading(state, value: any) {
    state.resetPasswordLoading = value;
  },
  resetPasswordSuccess() {
    router.replace("/auth/login");
  },
  resetPasswordError() {
    router.replace("/auth/forgotPassword");
  },

  // Confirm email
  confirmEmailLoading(state, value) {
    state.confirmEmailLoading = value;
  },

  // Company
  createCompanyLoading(state) {
    state.createCompanyLoading = true;
  },
  createCompany(state) {
    state.createCompanySuccess = true;
    console.log("success");
  },
  createCompanyLoaded(state) {
    state.createCompanyLoading = false;
  },

  upgradeLoading(state) {
    state.upgradeLoading = true;
  },
  upgradeSuccess(_, company) {
    console.log(company);
  },
  upgradeLoaded(state) {
    state.upgradeLoading = true;
  },
};

export default mutations;
